define('handandstone-ember/services/navmenu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    fastboot: Ember.inject.service(),
    isOpenMobileMenu: false,

    getIsDeviceHasTouch: function getIsDeviceHasTouch() {
      var result = null;

      if (!this.get('fastboot.isFastBoot')) {
        result = !(typeof document.body.ontouchstart == 'undefined');
      }

      return result;
    },
    toggleIsOpenFlag: function toggleIsOpenFlag() {
      this.toggleProperty('isOpenMobileMenu');
    },
    resetIsOpenFlag: function resetIsOpenFlag() {
      this.set('isOpenMobileMenu', false);
    }
  });
});