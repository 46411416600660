define('handandstone-ember/services/check-browser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    fastboot: Ember.inject.service(),

    version: function version() {
      var result = {};

      if (!this.get('fastboot.isFastBoot')) {
        var ua = window.navigator.userAgent,
            msie = ua.indexOf('MSIE '),
            trident = ua.indexOf('Trident/'),
            edge = ua.indexOf('Edge/');

        result = {
          isIE: false,
          isEdge: false,
          version: null,
          isOtherBrowser: true,
          details: ua
        };
        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        if (msie > 0) {
          // IE 10 or older => return version number
          result.isIE = true;
          result.version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)));
          result.isOtherBrowser = false;
        }

        if (trident > 0) {
          // IE 11 => return version number
          var rv = ua.indexOf('rv:');

          result.isIE = true;
          result.version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)));
          result.isOtherBrowser = false;
        }

        if (edge > 0) {
          // Edge (IE 12+) => return version number
          result.isEdge = true;
          result.version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)));
          result.isOtherBrowser = false;
        }
      }

      return result;
    }
  });
});