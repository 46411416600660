define('handandstone-ember/pods/components/locations/popup-locations/component', ['exports', 'handandstone-ember/mixins/modal-native-mixin'], function (exports, _modalNativeMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_modalNativeMixin.default, {
    store: Ember.inject.service(),
    geoLocation: Ember.inject.service('geo-location'),
    location: Ember.inject.service(),

    classNames: ['popups', 'popup', 'location-choose-popup', 'show'],
    attributeBindings: ['aria-label'],
    'aria-label': "Locations",
    'aria-labelledby': false,
    address: "",
    local: {
      latitude: null,
      longitude: null
    },
    localLocation: {
      address: null,
      zip: null
    },

    isCurrentLocations: function () {
      if (!this.get('location.localLatitude') && !this.get('location.localLongitude')) {
        this.searchLatLng(this.get('address'));
        return false;
      }
      return true;
    }.property(),

    coordinates: function () {
      var currentLocationLatitude = this.get('location.localLatitude') || false,
          currentLocationLongitude = this.get('location.localLongitude') || false;

      this.setProperties({
        'local.latitude': currentLocationLatitude,
        'local.longitude': currentLocationLongitude
      });

      return {
        latitude: currentLocationLatitude,
        longitude: currentLocationLongitude
      };
    }.property(),

    //search zip using Google api
    zipSearch: function () {
      var _this = this;

      if (this.get('local.latitude') && this.get('local.longitude')) {
        this.get('geoLocation').searchByCoordinates(this.get('local.latitude'), this.get('local.longitude')).then(function (data) {
          _this.set('localLocation.address', data.long_name);
        }, function () {
          _this.set('localLocation.address', undefined);
          return false;
        });
      }
    }.observes('local.longitude'),

    //Latitude and Longitude by selected address
    searchLatLng: function searchLatLng(address) {
      var _this2 = this;

      if (!address) {
        this.setProperties({
          "coordinates.latitude": undefined,
          'coordinates.longitude': undefined
        });

        return true;
      }

      this.get('geoLocation').searchByAddress(address).then(function (data) {
        if (data.lat && data.lng) {
          _this2.setProperties({
            'coordinates.latitude': data.lat,
            'coordinates.longitude': data.lng
          });
        } else {
          _this2.setProperties({
            'coordinates.latitude': false,
            'coordinates.longitude': false
          });
        }
      }, function () {
        _this2.setProperties({
          'coordinates.latitude': false,
          'coordinates.longitude': false
        });
      });
    },


    //Locations
    locations: Ember.computed('coordinates.longitude', function () {
      return this.get('store').query('location', {
        latitude: this.get('coordinates.latitude'),
        longitude: this.get('coordinates.longitude'),
        distance: 200,
        'per-page': 50,
        sort: 'distance',
        coming_soon: 0
      });
    }),

    updateFocusCycle: function () {
      this.get('focus').rebuildModalFocus();
    }.observes('locations.@each.id'),

    addComma: function addComma(string) {
      return string.length > 0 ? ',' : '';
    },


    actions: {
      closeModal: function closeModal() {
        this.sendAction('closeModal');
      },
      selectLocation: function selectLocation(locationId) {
        this.sendAction('selectLocation', locationId);
        this.sendAction('closeModal');

        if (this.get('model') && this.get('model').hasOwnProperty('resolve')) {
          this.get('model').resolve();
        }
      },
      selectCustomLocation: function selectCustomLocation() {
        this.searchLatLng(this.get('address'));
        this.set('isCurrentLocations', false);
      },
      useCurrentLocation: function useCurrentLocation() {
        this.setProperties({
          'coordinates.latitude': this.get('local.latitude'),
          'coordinates.longitude': this.get('local.longitude'),
          isCurrentLocations: true
        });
      },
      findLocation: function findLocation() {
        this.searchLatLng(this.get('address'));
      }
    }
  });
});