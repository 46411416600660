define('handandstone-ember/pods/components/booking/service-duration/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollTo: Ember.inject.service('scroll-to'),
    classNameBindings: ['isChecked:checked'],

    isChecked: false,
    tagName: 'li',
    formatPrice: function formatPrice(price) {
      var cents = Math.round(price * 100 % 100);
      if (cents.toString().length < 2) {
        cents = '0' + parseInt(cents).toString();
      } else {
        cents = parseInt(cents);
      }

      return {
        dollars: parseInt(price),
        cents: cents
      };
    },


    cents: function () {
      return this.formatPrice(this.get('item.price')).cents;
    }.property(),

    dollars: function () {
      return this.formatPrice(this.get('item.price')).dollars;
    }.property(),

    checkedDuration: function () {

      var result = this.get('item') === this.get('selectedDuration');

      if (result) {
        this.scrollToNextSection();
      }

      this.set('isChecked', result);
    }.on('didInsertElement').observes('selectedDuration'),

    scrollToNextSection: function scrollToNextSection() {
      var position = Ember.$('.footer').offset().top - 100;
      this.get('scrollTo').jQueryJumpTo(position, 500);
    },


    isMobile: function () {
      return Ember.$(window).width() <= 1200;
    }.property(),

    didInsertElement: function didInsertElement() {
      if (this.get('index') == 0) {
        this.$('a').focus();
      }
    },


    actions: {
      select: function select(duration) {
        this.sendAction('durationAction', duration);
      }
    }
  });
});