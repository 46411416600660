define('handandstone-ember/pods/components/geo-autocomplete/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['wrap-autocomplete'],
    inputId: null,
    isExpanded: false,
    resultsAmount: 0,

    autocompleteItemRenderer: function autocompleteItemRenderer(ul, item) {
      var itemHtml = Ember.$('<li>').attr('role', 'option');

      Ember.$('<div>').attr('role', 'button').append(item.label).appendTo(itemHtml);

      return itemHtml.appendTo(ul);
    },
    didInsertElement: function didInsertElement() {
      var self = this;
      self.set('input', this.$(':text'));
      self.get('input').autocomplete({
        appendTo: self.get('input').parent(),
        autoFocus: true,
        minLength: 2,
        classes: {
          "ui-autocomplete": "custom-autocomplete"
        },

        create: function create() {
          self.get('input').autocomplete('widget')[0].setAttribute('role', 'listbox');
        },

        source: function source(request, response) {
          var displaySuggestions = function displaySuggestions(predictions, status) {
            if (status !== google.maps.places.PlacesServiceStatus.OK) {
              return;
            }

            var arr = [];
            predictions.forEach(function (prediction) {
              arr.push({
                label: prediction.description,
                value: prediction.description
              });
            });
            response(arr);
          };

          var service = new google.maps.places.AutocompleteService();

          var options = {
            input: request.term,
            componentRestrictions: { country: 'us' }
          };

          service.getPlacePredictions(options, displaySuggestions);
        },

        open: function open() {
          self.setProperties({
            isExpanded: true,
            resultsAmount: self.get('input').autocomplete("widget")[0].childElementCount
          });
          self.set('isExpanded', true);
        },

        close: function close() {
          self.set('isExpanded', false);
          $('.ui-helper-hidden-accessible').remove();
        }
      }).data("ui-autocomplete")._renderItem = this.autocompleteItemRenderer;
    }
  });
});