define('handandstone-ember/services/dynamic-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    regularType: 'regular',

    init: function init() {
      this._super.apply(this, arguments);
      this.getAllModels();
    },
    getAllModels: function getAllModels() {
      var _this = this;

      var router = Ember.getOwner(this).lookup('router:main');
      var allRoutesList = router.get('router.recognizer.names');

      return this.get('store').findAll('menu').then(function (result) {
        result.forEach(function (model) {
          if (model.get('type') === _this.get('regularType')) {
            var items = [];
            model.get('menu.items').forEach(function (item) {
              if (parseInt(item.is_internal_url)) {
                if (!(item.url in allRoutesList)) {
                  return true;
                }
              }
              items.pushObject(item);
            });
            model.set('menu.items', items);
          }
        });
        _this.set('allModels', result);
        return result;
      });
    },


    allModels: null,

    footerFirstMenu: function () {
      return this.get('store').peekRecord('menu', 'footer_first_menu');
    }.property('allModels.@each.name'),

    footerSecondMenu: function () {
      return this.get('store').peekRecord('menu', 'footer_terms_and_conditions');
    }.property('allModels.@each.name'),

    footerThirdMenu: function () {
      return this.get('store').peekRecord('menu', 'footer_third_menu');
    }.property('allModels.@each.name'),

    socialMenu: function () {
      return this.get('store').peekRecord('menu', 'follow_us');
    }.property('allModels.@each.name'),

    headerTopMenu: function () {
      return this.get('store').peekRecord('menu', 'header_top_menu');
    }.property('allModels.@each.name')
  });
});