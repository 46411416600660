define('handandstone-ember/pods/account/appointments/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'handandstone-ember/mixins/authenticate-popup-mixin'], function (exports, _authenticatedRouteMixin, _authenticatePopupMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authenticatePopupMixin.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      start_date: {
        refreshModel: true
      },
      show_cancellations: {
        refreshModel: true
      }
    },

    model: function model(params) {
      return this.get('store').query('account/appointment', params);
    }
  });
});