define('handandstone-ember/pods/components/header-main/component', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    session: service(),
    location: service(),
    fastboot: service(),
    navmenu: service(),
    'dynamic-menu': Ember.inject.service('dynamic-menu'),
    routerService: service('-routing'),
    scrollTo: service('scroll-to'),

    tagName: 'header',
    classNames: ['header-global'],
    classNameBindings: ['headerClass', 'navmenu.isOpenMobileMenu:menu-open'],
    params: {
      logo: {
        title: "Hand & Stone Massage and Facial Spa",
        image: "/slice/dist/images/svg/logo.svg"
      },
      isBooking: {
        info: [{
          type: "online",
          icon: "/slice/dist/images/svg/booking-online.svg",
          text: "Book online 24/7"
        }, {
          type: "deals",
          icon: "/slice/dist/images/svg/booking-deals.svg",
          text: "Last Minute Deals"
        }, {
          type: "free",
          icon: "/slice/dist/images/svg/booking-free.svg",
          text: "Booking is Free. Pay on Location"
        }]
      },
      info: [{
        icon: "/slice/dist/images/svg/open-7-days.svg",
        text: "Open <br>7 Days"
      },
      /*   {
           icon: "/slice/dist/images/svg/walk-ins-welcome.svg",
           text: "Walk Ins <br>Welcome"
         },*/
      {
        icon: "/slice/dist/images/svg/extended-hours.svg",
        text: "Extended <br>Hours"
      }, {
        icon: "/slice/dist/images/svg/membership-programs-available.svg",
        text: "Membership Programs <br>Available"
      }]
    },
    headerClass: Ember.computed('location.currentRoute', 'fastboot.isFastBoot', function () {

      var booking = this.get('location').isBookingRoute();
      if (booking) {
        this.set('isBooking', true);
        return "header-booking";
      } else {
        this.set('isBooking', false);
        return "header-main";
      }
    }),

    canToBook: Ember.computed('location.selectedType', 'location.isSingleLocationRoute', function () {
      return this.get('location.selectedType') === 'local' || this.get('location.isSingleLocationRoute');
    }),

    /**
     * For show phone on mobile booking header and regular header
     */
    mobilePhoneLocation: Ember.computed('location.{booking,singlePageLocation,selectedLocation}.phoneNumber,location.{isSingleLocationRoute,selectedType}', function () {
      var result = null;

      if (this.get('location').isBookingRoute()) {
        result = this.get('location.booking.phoneNumber');
      } else {
        if (this.get('location.isSingleLocationRoute')) {
          result = this.get('location.singlePageLocation.phoneNumber');
        } else {
          if (this.get('location.selectedType') === 'local') {
            result = this.get('location.selected.phoneNumber');
          }
        }
      }
      return result;
    }),

    isYourLocalSpa: Ember.computed('location.selectedType', function () {
      return this.get('location.selectedType') === 'local';
    }),

    locationTypeTitle: Ember.computed('location.selectedType', function () {
      var result = '';
      switch (this.get('location.selectedType')) {
        case 'central':
          result = "Call for your closest spa";
          break;
        case 'nearest':
          result = "For Nearest SPA";
          break;
        default:
          result = "Your Local SPA";
      }
      return result;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('location').init();
    },


    isStage: function () {
      return _environment.default.environment === 'stage';
    }.property(),

    currentRouteChanged: function currentRouteChanged() {
      this.get('navmenu').resetIsOpenFlag();
    },

    didInsertElement: function didInsertElement() {
      this.get('routerService').addObserver("currentState", this, "currentRouteChanged");
    },


    topMenu: function () {
      return this.get('dynamic-menu.headerTopMenu.menu.items');
    }.property('dynamic-menu.headerTopMenu.menu.items'),

    conversionPixelAction: function () {
      var config = { //key = routeName, value = action
        homepage: 'content',
        locations: 'lead'
      };

      return config[this.get('location.currentRoute')];
    }.property('location.currentRoute'),

    actions: {
      locationsPopUp: function locationsPopUp(defer) {
        this.sendAction('modalAction', 'locations/locations-popup', defer);
      },
      signInPopUp: function signInPopUp() {
        this.sendAction('signInAction');
      },
      openMobileMenu: function openMobileMenu() {
        this.get('navmenu').toggleIsOpenFlag();
      },
      skipToContent: function skipToContent() {
        this.get('scrollTo').jQueryJumpTo(Ember.$('.content-page').offset().top, 500, function () {
          Ember.$('#skip-content-anchor').focus();
        });
      },

      callUsToSchedule: function callUsToSchedule() {
        //Amazon Advertising Tag
        if (this.get('location.currentRoute') === 'homepage') {
          setTimeout(function () {
            amzn("trackEvent", "Contact_IHM 317 Hand and Stone Homepage Call us to schedule");
          }, 0);
        }
      }
    }
  });
});