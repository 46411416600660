define('handandstone-ember/pods/components/header-main/logo-main/component', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),

    classNames: ['logo'],
    tagName: 'strong',

    url: function () {
      return _environment.default.APP.CURRENT_URl;
    }.property(),

    actions: {
      goToHome: function goToHome() {
        this.get('router').transitionTo('homepage');
      }
    }
  });
});