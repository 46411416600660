define('handandstone-ember/pods/components/booking/countdown-timer/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    'booking-store': Ember.inject.service(),

    classNames: ["add-info"],
    attributeBindings: ['style'],
    SRMessage: '',
    countdownTime: "",

    style: function () {
      return Ember.String.htmlSafe("display: block");
    }.property(),

    isActive: Ember.computed('model.expired_at', function () {
      var isActive = false;

      if (this.get('model.expired_at')) {
        this.setCountdownTimer();
        isActive = true;
      }

      return isActive;
    }),

    observeIsTimerActive: function () {
      this.set('parent.isActiveTimer', this.get('isActive'));
    }.observes('isActive').on('didInsertElement'),

    setScreenReaderLiveText: function () {
      var text = "Please, complete your reservation in ",
          result = '',
          selfContext = this;

      var removeSRMessageSchedule = function removeSRMessageSchedule() {
        if (!selfContext.isDestroyed) {
          selfContext.set('SRMessage', '');
        }
      };

      if (this.get('countdownTime') === "1:00") {
        result = '<p>' + text + 1 + ' minute</p>';
      } else if (this.get('countdownTime') === "3:50") {
        result = '<p>' + text + 5 + ' minutes</p>';
      }

      if (result) {
        this.set('SRMessage', result);
        setTimeout(removeSRMessageSchedule, 1000);
      }
    }.observes('countdownTime'),

    setCountdownTimer: function setCountdownTimer() {
      var _this = this;

      var interval = setInterval(function () {
        if (!_this.isDestroyed) {
          if (!_this.get('model.expired_at') || _this.get('model.isFourthStep') || _this.get('model.isThirdStep') && _this.get('model.isIntroductory')) {
            clearInterval(_this.get('handler'));
            return false;
          }

          //Expired At timestamp
          var countDownDate = moment.utc(_this.get('model.expired_at')).valueOf();
          //let Local timestamp
          var now = moment.utc().valueOf();
          // Find the distance between now an the count down date
          var distance = countDownDate - now;

          // If the count down is finished, write some text
          if (distance < 1000) {
            _this.setProperties({
              countdownTime: '00:00',
              'model.expired_at': null
            });
            _this.sendAction('clearStore');

            if (_this.get('model.isIntroductory')) {
              _this.set('booking-store.booking.model', {
                location_id: _this.get('model.location_id'),
                user_type: _this.get('model.user_type')
              });

              _this.set('booking-store.booking.step', 'first');
              _this.get('model').unloadRecord();
            }

            _this.sendAction('openModal', 'booking/errors-countdown');
            clearInterval(_this.get('handler'));
            return false;
          } else {
            var duration = moment.duration(distance, 'milliseconds');
            _this.set('countdownTime', duration.minutes() + ':' + (duration.seconds() < 10 ? "0" + duration.seconds() : duration.seconds()));
          }
        } else {
          clearInterval(interval);
        }
      }, 1000);
      this.set('handler', interval);
    },


    tearDown: function () {
      if (this.get('handler')) {
        clearInterval(this.get('handler'));
      }
    }.on('willDestroyElement')
  });
});