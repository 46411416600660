define('handandstone-ember/pods/components/location/work-time/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['work-time'],
    arrDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    worktimeChanged: Ember.computed(function () {
      var worktime = this.get('worktime'),
          arr = [];
      for (var i = 0; i < this.arrDays.length; i++) {
        var result = {
          title: this.arrDays[i],
          opened: null,
          closed: null
        };
        for (var j = 0; j < 7; j++) {
          if (worktime[j] === undefined) {
            break;
          }
          if (this.arrDays[i] === worktime[j].day) {
            if (worktime[j].opened && worktime[j].closed) {
              result = {
                title: this.arrDays[i],
                opened: worktime[j].opened,
                closed: worktime[j].closed
              };
            }
            break;
          }
        }
        arr.push(result);
      }
      var time = null,
          array = [];
      if (arr[0].opened) {
        time = moment(arr[0].opened, "H:mm").format("LT") + ' - ' + moment(arr[0].closed, "H:mm").format("LT");
      }
      array.push({
        time: time,
        title: arr[0].title,
        days: [arr[0].title]
      });
      for (var _i = 0; _i < arr.length - 1; _i++) {
        if (arr[_i].opened == arr[_i + 1].opened && arr[_i].closed == arr[_i + 1].closed) {
          array[array.length - 1].days.push(arr[_i + 1].title);
          array[array.length - 1].title = array[array.length - 1].days[0] + ' - ' + array[array.length - 1].days[array[array.length - 1].days.length - 1];
        } else {
          var _time = null;
          if (arr[_i + 1].opened) {
            _time = moment(arr[_i + 1].opened, "H:mm").format("LT") + ' - ' + moment(arr[_i + 1].closed, "H:mm").format("LT");
          }
          array.push({
            time: _time,
            title: arr[_i + 1].title,
            days: [arr[_i + 1].title]
          });
        }
      }
      return array;
    }),
    worktimeChangedADA: function () {
      var result = [];
      this.get('worktimeChanged').forEach(function (item) {
        var time = '';

        if (item.time) {
          time = '<i class="visually-hidden">Our hours are: from </i>' + item.time.replace(/:00/g, '').replace('-', '- <i class="visually-hidden">to</i>');
        } else {
          time = '<i class="visually-hidden">Location is </i> Closed';
        }

        result.push({
          title: item.title.replace('-', '- <i class="visually-hidden">to</i>'),
          time: time
        });
      });
      return result;
    }.property('worktimeChanged')
  });
});