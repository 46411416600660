define('handandstone-ember/pods/account/information/model', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 50
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 50
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    phone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'phone', allowBlank: true })],
    address1: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 128 })],
    city: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40 })],
    zip: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 25 })]

  });

  exports.default = _emberData.default.Model.extend(Validations, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    address1: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    send_email: _emberData.default.attr('boolean'),
    points: _emberData.default.attr('number'),
    member_id: _emberData.default.attr('number'),
    membership_status: _emberData.default.attr('boolean'),
    home_location: _emberData.default.attr('number'),
    monthly_credit: _emberData.default.attr('string'),
    used_intro_services: _emberData.default.attr('array'),

    phoneOnlyNumbers: function () {
      return this.get('phone').replace('-', '');
    }.property('phone'),

    phoneNumber: function () {
      if (this.get('phone')) {
        var phone = this.get('phone');
        return '(' + phone.substr(0, 3) + ') ' + phone.substr(4, 3) + '-' + phone.substr(8, 4);
      }
      return '';
    }.property('phone'),

    fullName: function () {
      var name = '';

      if (this.get('full_name')) {
        name += this.get('full_name').charAt(0).toUpperCase() + this.get('full_name').substr(1).toLowerCase();
      }

      return name;
    }.property('full_name')
  });
});