define('handandstone-ember/pods/components/custom-link-to/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TYPE_LEARN_MORE = 'learn-more';
  var TITLE_LEARN_MORE = 'learn more';
  var TITLE_BUY_NOW = 'buy now';

  exports.default = Ember.LinkComponent.extend({
    location: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
    },

    type: null,
    title: null,

    click: function click() {
      //Amazon Advertising Tag
      if (this.get('location.currentRoute') === 'class.neveskin') {
        if (this.get('type') === TYPE_LEARN_MORE || this.get('title') && this.get('title').toLowerCase() === TITLE_LEARN_MORE) {
          amzn("trackEvent", "Lead_IHM 317 Hand and Stone Cryo Learn More");
        } else if (this.get('title') && this.get('title').toLowerCase() === TITLE_BUY_NOW) {
          amzn("trackEvent", "Lead_IHM 317 Hand and Stone Cryo Buy Now");
        }
      } else if (this.get('location.currentRoute') === 'class.facial') {
        if (this.get('type') === TYPE_LEARN_MORE || this.get('title') && this.get('title').toLowerCase() === TITLE_LEARN_MORE) {
          amzn("trackEvent", "Lead_IHM 317 Hand and Stone Facial Learn More");
        } else if (this.get('title') && this.get('title').toLowerCase() === TITLE_BUY_NOW) {
          amzn("trackEvent", "Lead_IHM 317 Hand and Stone Facial Buy Now");
        }
      }
      this._super.apply(this, arguments);
    }
  });
});