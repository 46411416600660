define('handandstone-ember/pods/components/homepage/book-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['book-block'],
    tagName: 'section',

    actions: {
      bookingAction: function bookingAction(template, deferFunction) {
        this.sendAction('bookingAction', template, deferFunction);
      }
    }
  });
});