define('handandstone-ember/pods/components/header-main/location-more-information-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    location: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
    },
    click: function click() {
      //Amazon Advertising Tag
      if (this.get('location.currentRoute') === 'homepage') {
        amzn("trackEvent", "Lead_IHM 317 Hand and Stone Homepage More Information");
      }
      this._super.apply(this, arguments);
    }
  });
});