define('handandstone-ember/pods/addon/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    addon_id: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    isPopular: _emberData.default.attr(),
    price: _emberData.default.attr('string'),
    options: _emberData.default.attr(),
    icon: _emberData.default.attr('string'),
    image_right: _emberData.default.attr('string'),
    image_left: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    is_taxable: _emberData.default.attr('boolean'),
    is_override_tax: _emberData.default.attr('boolean'),
    override_tax: _emberData.default.attr('number')
  });
});