define('handandstone-ember/pods/service/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'slug',

    normalize: function normalize(model, hash) {
      if (hash.location_id) {
        hash.links = {
          employees: '/v1/locations/' + hash.location_id + '/employees',
          employeesSchedule: '/v1/locations/' + hash.location_id + '/services/:service_id/employees-schedule'
        };
      }
      return this._super.apply(this, arguments);
    }
  });
});