define('handandstone-ember/pods/components/service/back-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    isMassage: Ember.computed('model.type', function () {
      return this.get('model.type') == 'MA';
    }),

    isFacial: Ember.computed('model.type', function () {
      return this.get('model.type') == 'FI';
    }),

    isNeveskin: Ember.computed('model.type', function () {
      return this.get('model.type') == 'NS';
    }),

    name: function () {
      var link = void 0;
      if (this.get('isMassage')) {
        link = 'Massage';
      } else if (this.get('isFacial')) {
        link = 'Facial';
      } else if (this.get('isNeveskin')) {
        link = 'Neveskin';
      } else {
        link = 'Hair Removal';
      }
      return link;
    }.property(),

    link: function () {
      var link = void 0;
      if (this.get('isMassage')) {
        link = 'class.massage';
      } else if (this.get('isFacial')) {
        link = 'class.facial';
      } else if (this.get('isNeveskin')) {
        link = 'class.neveskin';
      }
      return link;
    }.property()
  });
});