define('handandstone-ember/pods/contact-request/model', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var Validations = (0, _emberCpValidations.buildValidations)({
        name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
            min: 2
        })],
        email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
        phone: [(0, _emberCpValidations.validator)('format', { type: 'phone', allowBlank: true })],
        message: [(0, _emberCpValidations.validator)('length', { max: 1500 })],
        category: [(0, _emberCpValidations.validator)('presence', true)]
    });

    exports.default = _emberData.default.Model.extend(Validations, {
        name: _emberData.default.attr('string'),
        email: _emberData.default.attr('string'),
        phone: _emberData.default.attr('string'),
        category: _emberData.default.attr('string', {
            defaultValue: function defaultValue() {
                return "";
            }
        }),
        message: _emberData.default.attr('string')
    });
});