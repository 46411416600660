define('handandstone-ember/pods/components/booking/auth-block/sign-in/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    identification: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 8, max: 16 })]
  });

  exports.default = Ember.Component.extend(Validations, {
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    validation: null,
    showError: false,
    serverErrors: {
      email: false,
      password: false
    },

    requestSending: false,

    isEmailInvalid: Ember.computed('showError', 'validation.identification.isInvalid', 'serverErrors.email', function () {
      return this.get('showError') && (this.get('validation.identification.isInvalid') || this.get('serverErrors.email'));
    }).readOnly(),

    isPasswordInvalid: Ember.computed('showError', 'validation.password.isInvalid', 'serverErrors.password', function () {
      return this.get('showError') && (this.get('validation.password.isInvalid') || this.get('serverErrors.password'));
    }).readOnly(),

    passwordChange: function () {
      this.set('serverErrors.password', false);
    }.observes('password'),

    identificationChange: function () {
      this.set('serverErrors.identification', false);
    }.observes('identification'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly('validations.attrs'));
    },

    actions: {
      formSwitch: function formSwitch() {
        this.sendAction('formSwitch');
      },
      signIn: function signIn() {
        var _this = this;

        this.set('showError', true);
        this.set('serverErrors', {});
        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var credentials = _this.getProperties('identification', 'password'),
                authenticator = 'authenticator:token';

            if (!_this.get('requestSending')) {
              _this.set('requestSending', true);
              _this.get('session').authenticate(authenticator, credentials).then(function () {
                return _this.get('account.model').then(function () {
                  _this.setProperties({ showError: false, requestSending: false });
                  _this.sendAction('signIn');
                }, function () {
                  _this.get('session').invalidate();
                });
              }).catch(function (reason) {
                if (Array.isArray(reason)) {
                  reason.forEach(function (item) {
                    if (item.field === 'password') {
                      _this.set('serverErrors.password', item.message);
                    } else if (item.field === 'email') {
                      _this.set('serverErrors.email', item.message);
                    } else {
                      _this.set('serverErrors', { password: item.message, email: item.message });
                    }
                  });
                } else {
                  _this.set('serverErrors.email', 'Authorization Error!');
                }
                _this.set('requestSending', false);
              });
            }
          }
        });
      }
    }
  });
});