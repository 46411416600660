define('handandstone-ember/pods/components/load-content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    loadingMessage: '<p>Alert. Content is loading</p>',
    finishedLoadingMessage: '<p>Alert. Content has finished loading</p>',
    classes: '',
    tagNaming: 'div',
    tagName: '',
    SRMessage: '',
    isLoading: false,
    removeSRMessageIndex: 0,

    checkLoading: function () {
      this.setCurrentMessage();
    }.observes('isLoading'),

    setCurrentMessage: function setCurrentMessage() {
      var messageType = this.get('isLoading') ? 'loadingMessage' : 'finishedLoadingMessage';
      this.set('SRMessage', this.get(messageType));
      this.setRemoveSRMessageSchedule();
    },
    setRemoveSRMessageSchedule: function setRemoveSRMessageSchedule() {
      this.set('removeSRMessageIndex', this.get('removeSRMessageIndex') + 1);
      var selfContext = this;

      var removeSRMessageSchedule = function removeSRMessageSchedule() {
        if (!selfContext.isDestroyed) {
          selfContext.set('removeSRMessageIndex', selfContext.get('removeSRMessageIndex') - 1);
          if (selfContext.get('removeSRMessageIndex') === 0) {
            selfContext.set('SRMessage', '');
          }
        }
      };

      setTimeout(removeSRMessageSchedule, 500);
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setCurrentMessage();
    }
  });
});