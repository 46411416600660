define('handandstone-ember/pods/components/location/location-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['location-slider'],
    classNameBindings: ['isComingSoon:coming-soon'],

    isComingSoon: function () {
      return this.get('model.is_coming_soon');
    }.property('model.is_coming_soon'),

    setImages: function setImages() {
      var result = null;

      if (!this.isDestroyed) {
        var images = this.get('model.images');

        if (images.length) {
          result = this.get('model.images');
        } else {
          result = [{
            url: this.get('model.image'),
            title: this.get('model.name')
          }];
        }
      }

      this.set('images', result); //Not use Ember.computed images, slider crash in open "Change Location popup"
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setImages();
    }
  });
});