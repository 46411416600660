define('handandstone-ember/router', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    didTransition: function didTransition(arr) {
      if (!this.get('fastboot.isFastBoot')) {
        if (arr[arr.length - 1].name === 'homepage') {
          Ember.$('body').addClass('home-page');
        } else {
          Ember.$('body').removeClass('home-page');
        }
      }
      this._super.apply(this, arguments);
      this._trackPage();
      if (!this.get('fastboot.isFastBoot')) {
        window.scrollTo(0, 0);
      }
    },

    _trackPage: function _trackPage() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', this, function () {
        var page = _this.get('url');
        var title = _this.getWithDefault('currentRouteName', 'unknown');
        Ember.get(_this, 'metrics').trackPage({ page: page, title: title });
      });
    }
  });

  Router.map(function () {
    this.route('homepage', { path: '/' });
    this.route('booking', { path: 'locations/:slug/booking' });
    this.route('locations', { path: 'locations' });
    this.route('location', { path: 'locations/:slug' });

    this.route('not-found', { path: '/*path' });
    this.route('result-classification', { path: 'before-and-after/:name' });

    this.route('class.facial', { path: 'facial' });
    this.route('class.facial.item', { path: 'facial/:name' });
    this.route('class.neveskin', { path: 'neveskin' });
    this.route('class.neveskin.item', { path: 'neveskin/:name' });
    this.route('class.massage', { path: 'massage' });
    this.route('class.massage.item', { path: 'massage/:name' });
    this.route('class.hair-removal', { path: 'hair-removal' });

    this.route('about-us', { path: '/about' }, function () {
      this.route('our-story', { path: '' });
      // this.route('contact-us');
      this.route('press');
    });

    this.route('featured', { path: 'featured' });
    this.route('featured/single', { path: 'featured/:slug' });

    this.route('static-page.pages', { path: 'page/:slug' });
    this.route('hot-deal', { path: 'spa-deals' });
    /*this.route('account', function() {
      this.route('dashboard', {path: ''});
      //this.route('payment'); //TODO add ADA if needed (templates forms)
      this.route('appointment', {path: 'appointments/:id'});
      this.route('information');
      this.route('appointments');
      this.route('intro-booking');
      this.route('gift-cards');
    });*/
    this.route('blog', function () {
      this.route('post-list', { path: '' });
      this.route('post', { path: '/:slug' });
    });
    this.route('first-visit');

    this.route('membership', function () {
      this.route('overview', { path: '' });
      this.route('rewards-program');
    });
    //this.route('auth/sign-in', {path: 'sign-in'});
    //this.route('auth/forgot-password', {path: 'forgot-password'});
    //this.route('auth/sign-up', {path: 'sign-up'});
    this.route('special-offer', { path: 'usat-offer' });
    this.route('phltri-offer', { path: 'phltri-offer' });
    this.route('usat');
    this.route('phltri');
    this.route('acg-submitted-email', { path: 'acg' });
  });
  exports.default = Router;
});