define('handandstone-ember/pods/components/locations/list-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['locations-nav'],
    minDistance: 0,
    maxDistance: 200,

    address: function () {
      return this.get('model.filterAddress');
    }.property('model.filterAddress'),

    filterAddress: function () {
      this.set('address', this.get('model.filterAddress'));
    }.observes('model.filterAddress'),

    setSliderADAattrs: function setSliderADAattrs() {
      var props = this.getProperties('sliderHandle', 'minDistance', 'maxDistance', 'slidedDistance', 'address'),
          address = props.address ? 'from ' + props.address + ' ' : '',
          distance = props.slidedDistance;

      props.sliderHandle.attr({
        role: 'slider',
        'aria-valuemin': props.minDistance,
        'aria-valuemax': props.maxDistance,
        'aria-label': 'Range',
        'aria-valuetext': 'The distance radius ' + address + 'is set up to ' + distance + ' miles',
        'aria-valuenow': distance
      });
    },
    didInsertElement: function didInsertElement() {
      var self = this,
          distance = self.get('model.search.distance'),
          slider = Ember.$(".slider-radius");

      self.set('slidedDistance', distance);

      slider.slider({
        range: "min",
        min: self.get('minDistance'),
        max: self.get('maxDistance'),
        value: distance,

        create: function create() {
          self.set('sliderHandle', slider.slider("widget").find('.ui-slider-handle'));
          self.setSliderADAattrs();
        },

        slide: function slide(e, ui) {
          self.set('slidedDistance', ui.value);
          self.setSliderADAattrs();
        },

        change: function change(e, ui) {
          self.sendAction('setDistance', ui.value);
        }
      });
    },


    actions: {
      findLocation: function findLocation() {
        this.sendAction('findLocation', this.get('address') ? this.get('address') : "");
        //Amazon Advertising Tag
        amzn("trackEvent", "Search_IHM 317 Hand and Stone Locations Find Stores");
      },
      setCurrentLocation: function setCurrentLocation() {
        this.sendAction('setCurrentLocation');
      }
    }
  });
});