define('handandstone-ember/pods/components/modal-confirm/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    attributeBindings: ['aria-describedby'],
    'aria-describedby': 'small-popup-describedby',

    customData: function () {
      return this.get('model.options.model');
    }.property('model.options.model'),

    actions: {
      actionConfirm: function actionConfirm() {
        var confirmFunction = this.get('customData.actionConfirm');

        if (confirmFunction) {
          confirmFunction();
        }

        this.get('modal').close();
      },
      actionReject: function actionReject() {
        var rejectFunction = this.get('customData.actionReject');

        if (rejectFunction) {
          rejectFunction();
        }

        this.get('modal').close();
      }
    }
  });
});