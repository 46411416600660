define('handandstone-ember/pods/components/add-to-calendar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['success-link'],
    title: 'Hand and Stone',
    organizer: '',
    email: '',
    isExpanded: false,
    isInit: false,

    startDateTime: function () {
      return moment(this.get('model.date') + ' ' + this.get('model.time')).format('YYYY-MM-DD HH:mm:ss');
    }.property(),

    endDateTime: function () {
      return moment(this.get('model.date') + ' ' + this.get('model.time')).add(this.get('model.duration_time'), 'minute').format('YYYY-MM-DD HH:mm:ss');
    }.property(),

    description: function () {
      return this.get('model.service.title');
    }.property(),

    address: function () {
      return this.get('model.location.address');
    }.property(),

    timezone: function () {
      return this.get('model.location.timezone_name');
    }.property(),

    didRender: function didRender() {
      if (!this.get('isInit')) {
        this.set('isInit', true);
        setTimeout(function () {
          addToCalendar(window, document);
        }, 1);
      }
    },
    show: function show() {
      var list = this.getList();

      if (list) {
        list.setAttribute('style', 'display:block;');
        list.setAttribute('aria-hidden', 'false');
      }

      this.set('isExpanded', true);
      this.goToFirstLink();
    },
    hide: function hide() {
      var list = this.getList();

      if (list) {
        list.setAttribute('style', 'display:nome;');
        list.setAttribute('aria-hidden', 'true');
      }

      this.set('isExpanded', false);
    },
    getCurElement: function getCurElement() {
      return document.getElementById(this.get('elementId'));
    },
    getList: function getList() {
      var element = this.getCurElement();
      return element ? element.getElementsByClassName('atcb-list')[0] : null;
    },
    getLinks: function getLinks() {
      var element = this.getCurElement();
      return element ? element.getElementsByClassName('atcb-item-link') : null;
    },
    goToFirstLink: function goToFirstLink() {
      var links = this.getLinks();

      if (links) {
        links[0].focus();
      }
    },
    goToButton: function goToButton() {
      this.hide();
      var element = this.getCurElement();

      if (element) {
        element.getElementsByClassName('atcb-link')[0].focus();
      }
    },
    getLinkIndex: function getLinkIndex(linksArray, link) {
      var index = 0;

      for (index; index < linksArray.length; index++) {
        if (Ember.$(linksArray[index]).is(link)) {
          break;
        }
      }
      return index;
    },
    bindLinksLogic: function bindLinksLogic() {
      var self = this,
          links = Ember.$(this.getLinks());

      if (links) {
        var linksAmount = links.length - 1;

        links.on('keydown.keydownMultiCalendarItem', function (e) {
          var linkIndex = self.getLinkIndex(links, Ember.$(this));

          if (e.keyCode == 40 && linkIndex != linksAmount) {
            //down
            links.eq(linkIndex + 1).focus();
          } else if (e.keyCode == 38) {
            //up
            linkIndex ? links.eq(linkIndex - 1).focus() : self.goToButton();
          }
        });
      }
    },
    unbindLinksLogic: function unbindLinksLogic() {
      var links = Ember.$(this.getLinks());

      if (links) {
        links.off('keydown.keydownMultiCalendarItem');
      }
    },
    willDestroyElement: function willDestroyElement() {
      this.unbindLinksLogic();
    },


    changeIsOpenBox: function () {
      var _this = this;

      var isOpen = this.get('isExpanded'),
          body = Ember.$('body');

      body.off('click.openMultiCalendar');

      if (isOpen) {
        if (/iP/i.test(navigator.userAgent)) {
          body.addClass('device-cursor');
        }

        body.on('click.openMultiCalendar', function (ev) {
          if (!Ember.$(ev.target).closest(Ember.$('#' + _this.get('elementId'))).length) {
            _this.hide();
          }
        });

        this.bindLinksLogic();
      } else {
        body.removeClass('device-cursor');
        this.unbindLinksLogic();
      }
    }.observes('isExpanded'),

    handleKeydown: Ember.on('keyDown', function (e) {
      if (e.keyCode == 27) {
        //esc
        this.goToButton();
      }
    }),

    actions: {
      openDropdown: function openDropdown() {
        this.get('isExpanded') ? this.hide() : this.show();
      },
      checkIsKey: function checkIsKey(e) {
        if (e.keyCode == 40 || e.keyCode == 13) {
          //arrow down ore enter
          this.show();
        }
      }
    }
  });
});