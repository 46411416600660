define('handandstone-ember/pods/components/modal-expired-token/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    attributeBindings: ['aria-describedby'],
    'aria-describedby': 'expired-token-describedby'
  });
});