define('handandstone-ember/pods/components/header-main/sub-menu-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.LinkComponent.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.addObserver('active', this, 'changeActiveState');
    },
    didInsertElement: function didInsertElement() {
      this.changeActiveState();
    },


    isActive: function () {
      return Boolean(this.get('active'));
    }.property('active'),

    changeActiveState: function changeActiveState() {
      this.sendAction('changeActive', this.get('isActive'));
    },
    willDestroyElement: function willDestroyElement() {
      this.removeObserver('active', this, 'changeActiveState');
    }
  });
});