define('handandstone-ember/pods/components/account/dashboard/membership-status/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    account: Ember.inject.service(),
    modal: Ember.inject.service(),
    classNames: ['cell'],

    actions: {
      availableLimitInfo: function availableLimitInfo() {
        this.get('modal').open('small-popup', { model: { success: true, title: 'Note', message: 'This information updates twice per day, AM and PM time.' } });
      }
    }
  });
});