define('handandstone-ember/pods/components/location/location-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service(),

    tagName: 'li',

    isCurrentLocation: function () {
      return this.get('model.id') == this.get('location.locationId');
    }.property('model.id', 'location.locationId'),

    click: function click(e) {
      e.preventDefault();
      this.get('location').setLocationId(this.get('model.id'));
    }
  });
});