define('handandstone-ember/pods/account/password/model', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _emberCpValidations.buildValidations)({
    password: [(0, _emberCpValidations.validator)('length', { min: 8 }), (0, _emberCpValidations.validator)('presence', true)],
    new_password: [(0, _emberCpValidations.validator)('length', { min: 8, max: 16 }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
      message: 'Password must include at least one upper case letter, one lower case letter, and a number'
    })],
    confirmation_new_password: [(0, _emberCpValidations.validator)('confirmation', {
      on: 'new_password'
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    password: _emberData.default.attr('string'),
    new_password: _emberData.default.attr('string'),
    confirmation_new_password: _emberData.default.attr('string')
  });
});