define('handandstone-ember/pods/components/validated-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      defineProperty = Ember.defineProperty;
  exports.default = Ember.Component.extend({
    classNames: ['element'],
    //classNameBindings: ['showErrorClass:has-error', 'showWarningClass:has-error', 'isValid:has-success'],
    classNameBindings: ['showWarningClass:has-error', 'isCheckbox:element-checkbox'],
    inputId: null,
    model: null,
    value: null,
    textarea: false,
    isRendering: true,
    type: 'text',
    valuePath: '',
    placeholder: '',
    'aria-required': false,
    validation: null,
    showValidations: false,
    didValidate: false,
    disablePaste: false,
    notValidating: computed.not('validation.isValidating').readOnly(),
    hasContent: computed.notEmpty('value').readOnly(),
    hasWarnings: computed.notEmpty('validation.warnings').readOnly(),
    isValid: computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),
    //showErrorClass: computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation', 'showError').readOnly(),
    //showErrorMessage: computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    //showWarningMessage: computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    randomValidationErrorId: function () {
      if (this.get('showError') && this.get('validation.isInvalid')) {
        return this.get('valuePath') + '-' + this.get('randomNumber');
      }

      return false;
    }.property('randomNumber', 'valuePath', 'showError', 'validation.isInvalid'),

    randomDescribedNoteId: function () {
      if (!this.get('showError') && this.get('note')) {
        return 'note-id-' + this.get('randomNumber');
      }

      return false;
    }.property('showError', 'note', 'randomNumber'),

    modifiedServerErrors: function () {
      var _this = this;

      var result = [];

      if (Array.isArray(this.get('serverErrors'))) {
        this.get('serverErrors').forEach(function (element, index) {
          result.push({
            message: element.message,
            id: _this.get('valuePath') + '-' + _this.get('randomNumber') + index
          });
        });
      }

      return result;
    }.property('serverErrors', 'randomNumber', 'valuePath'),

    allDescribeIds: function () {
      var result = '';

      if (this.get('randomValidationErrorId')) {
        result = this.get('randomValidationErrorId');
      }

      if (this.get('randomDescribedNoteId')) {
        result += ' ' + this.get('randomDescribedNoteId');
      }

      this.get('modifiedServerErrors').forEach(function (element) {
        result += ' ' + element.id;
      });

      return result.trim() || false;
    }.property('modifiedServerErrors.@each.id', 'randomValidationErrorId', 'randomDescribedNoteId'),

    randomNumber: function () {
      return String(Math.floor(Math.random() * 10000));
    }.property(),

    tabIndex: function () {
      var cnt = (this.get('serverErrors') ? this.get('serverErrors').length : 0) + (this.get('validation.message') ? 1 : 0);
      return cnt > 1 ? '-1' : false;
    }.property('validation.message', 'serverErrors'),

    showWarningClass: Ember.computed('showError', 'validation.isInvalid', 'serverErrors', function () {
      return this.get('showError') && (this.get('serverErrors') || this.get('validation.isInvalid'));
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      var valuePath = this.get('valuePath');
      defineProperty(this, 'validation', computed.readOnly('model.validations.attrs.' + valuePath));
      defineProperty(this, 'value', computed.alias('model.' + valuePath));
    },
    focusOut: function focusOut() {
      this._super.apply(this, arguments);
      this.set('showValidations', true);
    }
  });
});