define('handandstone-ember/pods/components/modal-points-info/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    modal: Ember.inject.service(),
    attributeBindings: ['aria-describedby'],
    'aria-describedby': 'popup-points-info',

    actions: {
      link: function link() {
        this.get('modal').close();
      }
    }
  });
});