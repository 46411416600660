define('handandstone-ember/pods/about-us/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        pathForType: function pathForType() {
            return 'contact-request';
        }
    });
});