define('handandstone-ember/pods/employee/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    full_name: _emberData.default.attr('string'),
    sex: _emberData.default.attr('string'),

    name: Ember.computed('full_name', function () {

      var name = this.get('full_name');
      return name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    })

  });
});