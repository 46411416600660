define('handandstone-ember/mixins/get-validation-props', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Object.create({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40
    })],
    first_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40
    })],
    last_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40
    })],
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    email_confirmation: [(0, _emberCpValidations.validator)('confirmation', {
      on: 'email'
    })],
    phone: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'phone', allowBlank: true })],
    card_name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40
    })],
    card_number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('cc-validation', {
      cards: ['visa', 'master-card', 'american-express', 'discover']
    })],
    card_cvv: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 4
    })],
    card_exp_month: [(0, _emberCpValidations.validator)('presence', true)],
    card_exp_year: [(0, _emberCpValidations.validator)('presence', true)],
    billing_zip: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 10
    })],
    password: [(0, _emberCpValidations.validator)('length', {
      allowBlank: true,
      min: 8,
      max: 16
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: true,
      regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,16}$/,
      message: 'Password must include at least one upper case letter, one lower case letter, and a number'
    })],
    password_confirmation: [(0, _emberCpValidations.validator)('confirmation', {
      on: 'password'
    })],
    token: [(0, _emberCpValidations.validator)('presence', true)],
    cancellation_policy: [(0, _emberCpValidations.validator)('inclusion', {
      in: [true],
      message: 'Required checkbox'
    })],

    returnValidationObj: function returnValidationObj(arrayValidationPropsNames) {
      var result = {},
          obj = this.getProperties.apply(this, _toConsumableArray(arrayValidationPropsNames));

      for (var key in obj) {
        result[key] = obj[key].slice();
      }

      return result;
    },
    buildValidations: function buildValidations(validationObj) {
      return (0, _emberCpValidations.buildValidations)(validationObj);
    }
  });
});