define('handandstone-ember/pods/components/account/info-panel/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'id-info',
    maxPoints: 9000,
    progressBarSectionsCount: 2,
    location: Ember.inject.service('location'),
    modal: Ember.inject.service(),

    limitedPoints: function () {
      if (!this.get('points')) {
        return 0;
      }
      return this.get('points');
    }.property('points'),

    progressBarPercentagesStyle: function () {
      var result = this.get('points') > this.get('maxPoints') ? 100 : this.get('points') / this.get('maxPoints') * 100;

      return Ember.String.htmlSafe('width: ' + result + '%');
    }.property('limitedPoints', 'points'),

    points: function () {
      return this.get('model.membership_status') ? this.get('model.points') > 0 ? this.get('model.points') : 0 : 0;
    }.property('model.points', 'model.membership_status'),

    progressBarSectionValues: function () {
      var progressBarSectionsCount = this.get('progressBarSectionsCount') + 1,
          result = [],
          i = 0,
          sectionValue = this.get('maxPoints') / progressBarSectionsCount;

      do {
        result.push(sectionValue * i);
        i++;
      } while (i <= progressBarSectionsCount);

      return result;
    }.property('maxPoints', 'progressBarSectionsCount'),

    actions: {
      openInfoPopup: function openInfoPopup() {
        this.get('modal').open('points-info');
      },

      bookingAction: function bookingAction(template, deferFunction) {
        this.sendAction('bookingAction', template, deferFunction);
      }
    }
  });
});