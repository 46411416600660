define('handandstone-ember/pods/application/controller', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    focus: Ember.inject.service('focus'),
    location: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    arttrkPixelID: function () {
      return _environment.default.ARTTRK_PIXEL_ID;
    }.property(),

    isHomePage: Ember.computed('location.currentRoute', 'fastboot.isFastBoot', function () {
      return this.get('location.currentRoute') === 'homepage';
    }),

    isLocationsPage: Ember.computed('location.currentRoute', 'fastboot.isFastBoot', function () {
      return this.get('location.currentRoute') === 'locations';
    }),

    isNeveskinPage: Ember.computed('location.currentRoute', 'fastboot.isFastBoot', function () {
      return this.get('location.currentRoute') === 'class.neveskin';
    }),

    isFacialPage: Ember.computed('location.currentRoute', 'fastboot.isFastBoot', function () {
      return this.get('location.currentRoute') === 'class.facial';
    })
  });
});