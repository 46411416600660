define('handandstone-ember/services/location', ['exports', 'lodash', 'handandstone-ember/config/environment'], function (exports, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    fastboot: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    centralOfficeId: 1,
    singleLocationId: null,

    init: function init() {
      this.setLocationId(this.get('cookies').read('locationId'));
      this.setLocationLat(this.get('cookies').read('currentLocationLatitude'));
      this.setLocationLong(this.get('cookies').read('currentLocationLongitude'));

      if (!this.get('locationId')) {
        this.setCurrentCoordinates();
      }
    },
    setCurrentCoordinates: function setCurrentCoordinates() {
      var _this2 = this;

      if (!this.get('currentLocationLatitude') || !this.get('currentLocationLongitude')) {
        if (!this.get('fastboot.isFastBoot')) {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
              if (!_this2.get('localLatitude') || !_this2.get('localLongitude')) {
                _this2.setLocationLat(position.coords.latitude);
                _this2.setLocationLong(position.coords.longitude);
              }
            });
          }
        }
      }
    },
    unsetLocationId: function unsetLocationId() {
      this.get('cookies').clear('locationId');
      this.set('locationId', null);
      this.setCurrentCoordinates();
      this.setDefaultLocation();
    },


    singlePageLocation: Ember.computed('singleLocationId', 'isSingleLocationRoute', function () {
      if (this.get('singleLocationId') && this.get('isSingleLocationRoute')) {
        if (!this.get('store').hasRecordForId('location', this.get('singleLocationId'))) {
          return this.get('store').findRecord('location', this.get('singleLocationId'));
        } else {
          return this.get('store').peekRecord('location', this.get('singleLocationId'));
        }
      } else {
        return false;
      }
    }),
    forBookingLocation: Ember.computed('singlePageLocation.{is_coming_soon,isLoaded}', 'selected', function () {
      var result = null;

      if (this.get('singlePageLocation') && (!this.get('singlePageLocation.isLoaded') || this.get('singlePageLocation.isLoaded') && !this.get('singlePageLocation.is_coming_soon'))) {
        result = this.get('singlePageLocation');
      } else {
        result = this.get('selected');
      }

      return result;
    }),

    setLocationId: function setLocationId(id) {
      if (parseInt(id)) {
        this.set('locationId', id);
        this.get('cookies').write('locationId', id, { 'maxAge': _environment.default.cookiesMaxAge, path: '/' });
      }
    },

    setCurrentLocationById: function setCurrentLocationById(id) {
      var _this = this;
      return new window.Promise(function (resolve, reject) {
        _this.getLocationById(id).then(function (result) {
          _this.setProperties({
            selected: result,
            selectedType: 'local'
          });
          _this.setLocationId(id);
          resolve();
        }, function (error) {
          _this.unsetLocationId();
          reject(error);
        });
      });
    },
    getLocationById: function getLocationById(id) {
      var _this = this;
      return new window.Promise(function (resolve, reject) {
        if (_this.get('store').hasRecordForId('location', id)) {
          resolve(_this.get('store').peekRecord('location', id));
        } else {
          _this.get('store').findRecord('location', id).then(function (result) {
            resolve(result);
          }, function (error) {
            reject(error);
          });
        }
      });
    },


    setLocationLat: function setLocationLat(lat) {
      if (parseFloat(lat)) {
        this.set('localLatitude', lat);
        this.get('cookies').write('currentLocationLatitude', lat, { 'maxAge': _environment.default.cookiesMaxAge, path: '/' });
      }
    },

    setLocationLong: function setLocationLong(long) {
      if (parseFloat(long)) {
        this.get('cookies').write('currentLocationLongitude', long, { 'maxAge': _environment.default.cookiesMaxAge, path: '/' });
      }
    },

    myLocations: function () {
      var _this3 = this;

      if (this.get('locationId') && !this.isBookingRoute()) {
        //Check if this location is in store
        if (!this.get('store').hasRecordForId('location', this.get('locationId'))) {
          this.get('store').findRecord('location', this.get('locationId')).then(function (result) {
            _this3.setProperties({
              selected: result,
              selectedType: 'local'
            });
          }, function () {
            _this3.unsetLocationId();
          });
        } else {
          this.setProperties({
            selected: this.get('store').peekRecord('location', this.get('locationId')),
            selectedType: 'local'
          });
        }
      }
    }.observes('locationId', 'currentRoute'),

    nearestLocation: function () {
      if (!this.get('locationId') && !this.isBookingRoute() && !this.get('selected.id')) {
        this.setDefaultLocation();
      }
    }.observes('currentRoute'),

    nearestLocationByCoordinate: function () {
      if (!this.get('locationId') && !this.isBookingRoute()) {
        this.setDefaultLocation();
      }
    }.observes('localLongitude,localLatitude'),

    setDefaultLocation: function setDefaultLocation() {
      var _this4 = this;

      if (Boolean(this.get('localLatitude')) && Boolean(this.get('localLongitude'))) {
        this.get('store').query('location', {
          latitude: this.get('localLatitude'),
          longitude: this.get('localLongitude'),
          distance: 200,
          'per-page': 1,
          sort: 'distance',
          coming_soon: 0
        }).then(function (results) {
          if (results.get('firstObject')) {
            _this4.set('selectedType', 'local');
            _this4.setLocationId(results.get('firstObject').get('id'));
            _this4.set('selected', results.get('firstObject'));
          } else {
            _this4.setCentralOffice();
          }
        });
      } else {
        this.setCentralOffice();
      }
    },


    //Get central office and set it as selected
    setCentralOffice: function setCentralOffice() {
      var _this5 = this;

      if (this.get('centralOfficeId')) {
        //Check if this location is in store
        if (!this.get('store').hasRecordForId('location', this.get('centralOfficeId'))) {
          this.get('store').findRecord('location', this.get('centralOfficeId')).then(function (result) {
            _this5.set('selected', result);
          });
        } else {
          var result = this.get('store').peekRecord('location', this.get('centralOfficeId'));
          this.set('selected', result);
        }
        this.set('selectedType', 'central');
      }
    },


    //Get booking location
    booking: Ember.computed('bookingLocationId', function () {
      if (this.get('bookingLocationId')) {
        if (!this.get('store').hasRecordForId('location', this.get('bookingLocationId'))) {
          return this.get('store').findRecord('location', this.get('bookingLocationId'));
        } else {
          return this.get('store').peekRecord('location', this.get('bookingLocationId'));
        }
      }
    }),

    setCurrentRoute: function setCurrentRoute(route) {
      var _this6 = this;

      this.set('currentRoute', route.name);

      if (this.isBookingRoute()) {
        if (route.params.slug) {
          this.getBySlug(route.params.slug).then(function (model) {
            _this6.set('bookingLocationId', model.get('id'));
          });
        } else {
          this.set('bookingLocationId', route.params.id);
        }
      }
    },

    isBookingRoute: function isBookingRoute() {
      return this.get('currentRoute') === 'booking';
    },

    currentLocation: function () {
      return this.get('singlePageLocation') || (this.get('selectedType') !== 'central' ? this.get('selected') : false);
    }.property('singlePageLocation', 'selectedType', 'selected'),

    isSingleLocationRoute: Ember.computed('currentRoute', function () {
      return this.get('currentRoute') === 'location';
    }),

    selectedLocation: Ember.computed('selected', 'selectedType', function () {
      if (this.get('selected') && this.get('selectedType') === 'local') {
        return this.get('selected');
      }
    }),

    getBySlug: function getBySlug(slug) {
      var existedLocation = this.get('store').peekAll('location').filter(function (location) {
        return location.get('slug') === slug;
      });

      if (existedLocation && !_lodash.default.isEmpty(existedLocation)) {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(existedLocation.pop());
        });
      }

      return this.get('store').queryRecord('location', { slug: slug });
    },

    centralOffice: function () {
      var centralOfficeId = this.get('centralOfficeId');

      if (this.get('store').hasRecordForId('location', centralOfficeId)) {
        var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
        return ObjectPromiseProxy.create({
          promise: Ember.RSVP.cast(this.get('store').peekRecord('location', centralOfficeId))
        });
      } else {
        return this.get('store').findRecord('location', centralOfficeId);
      }
    }.property(''),

    redirectToExternalSpaDealsLink: Ember.observer('selected.id', function () {
      if (this.get('routing.currentRouteName') === 'hot-deal' && this.get('selected.isAllowedExternalSpaDealsLink')) {
        window.location.href = this.get('selected.external_spa_deals_link');
      }
    })
  });
});