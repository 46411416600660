define('handandstone-ember/pods/components/about-us/contact-us/form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service(),
    modal: Ember.inject.service(),
    store: Ember.inject.service(),

    drop_down_value: null,
    classNames: ['contact-form'],
    model: null,
    showError: false,

    firsDropDownValue: function () {
      this.setDefaultDropDownValue();
    }.observes('model.page.model_data'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelForm', this.get('store').createRecord('contact-request'));
    },
    didRender: function didRender() {
      page.customSelect();
    },
    setDropDownValues: function setDropDownValues(value) {
      this.setProperties({
        drop_down_value: value,
        'modelForm.category': value
      });
    },
    setDefaultDropDownValue: function setDefaultDropDownValue() {
      if (this.get('model.page.model_data')) {
        this.setDropDownValues(this.get('model.page.model_data')[0]);
      }
    },


    actions: {
      save: function save() {
        var _this = this;

        this.set('showError', true);
        this.get('modelForm').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.get('modelForm').save().then(function () {
              _this.setProperties({
                showError: false,
                modelForm: _this.get('store').createRecord('contact-request')
              });

              _this.setDefaultDropDownValue();
              _this.get('modal').open('small-popup', {
                model: {
                  success: true,
                  title: 'Congratulations!',
                  message: 'Your message was sent.'
                }
              });
            }).catch(function () {
              _this.get('modal').open('small-popup', {
                model: {
                  success: false,
                  title: 'Error!',
                  message: 'Undefined error while sending contact form'
                }
              });
            });
          }
        });
      },
      selectTopic: function selectTopic(selection) {
        this.setDropDownValues(selection);
      }
    }
  });
});