define('handandstone-ember/services/employee-time', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    getItemClass: function getItemClass(currentTime, time, employee, duration) {
      var itemClass = '';
      if (currentTime == time) {
        itemClass = 'active';
      }
      if (!this.isAvailable(currentTime, employee, duration)) {
        itemClass = 'disabled';
      }
      return itemClass;
    },
    getIsCanBeSelected: function getIsCanBeSelected(employee, employee_id) {
      return employee.get('id') != employee_id;
    },
    isAvailable: function isAvailable(currentTime, employee, duration) {
      var bookedTime = employee.get('booked'),
          time = moment(currentTime, 'hh:mm'),
          endWorkDay = moment(employee.get('end_time'), 'hh:mm'),
          subEndWorkDay = moment(employee.get('end_time'), 'hh:mm').subtract(duration, 'minutes');

      if (time.isBetween(subEndWorkDay, endWorkDay)) {
        return false;
      }

      for (var j = 0; j < bookedTime.length; j++) {
        var startTime = moment(bookedTime[j].start_time, 'hh:mm').subtract(duration, 'minutes'),
            endTime = moment(bookedTime[j].end_time, 'hh:mm');

        if (time.isBetween(startTime, endTime)) {
          return false;
        }
      }
      return true;
    }
  });
});