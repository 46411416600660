define('handandstone-ember/pods/components/modal-coming-soon-notify/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    store: Ember.inject.service(),
    account: Ember.inject.service(),

    showError: false,
    modelNotify: null,

    hasError: Ember.computed('showError', 'modelNotify.errors', 'modelNotify.validations.isInvalid', function () {
      return this.get('showError') && (this.get('modelNotify.validations.isInvalid') || this.get('modelNotify.errors'));
    }).readOnly(),

    location_id: function () {
      return this.get('model.options.location_id');
    }.property('model.options.location_id'),

    setAuthorizedUserData: function setAuthorizedUserData() {
      if (this.get('account.model.id')) {
        this.set('modelNotify.email', this.get('account.model.email'));
      }
    },

    observesAccountModel: function () {
      this.setAuthorizedUserData();
    }.observes('account.model.id'),

    actions: {
      notify: function notify() {
        var _this = this;

        this.set('showError', true);
        this.get('modelNotify').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            if (!_this.get('modelNotify.isSaving')) {
              _this.get('modelNotify').save().then(function () {
                _this.get('modal').close('coming-soon-notify');
                _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'You\'ll be notify when this location SPA activated' } });
              });
            }
          }
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelNotify', this.get('store').createRecord('subscriber-location-coming-soon', {
        location_id: this.get('model.options.location_id')
      }));
      this.setAuthorizedUserData();
    }
  });
});