define('handandstone-ember/pods/components/booking/terapist-preferences/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollTo: Ember.inject.service('scroll-to'),

    tagName: 'section',
    classNames: ['therapist-preferences'],

    initDropdown: function () {
      jcf.setOptions('Select', {
        checkedClass: 'test',
        wrapNativeOnMobile: false,
        wrapNative: false,
        maxVisibleItems: 5
      });
      jcf.replaceAll();
    }.on('didRender').observes('isAnyEmployees', 'sex', 'employee'),

    checkIsAnySexEqualTo: function checkIsAnySexEqualTo(sex) {
      sex = sex.charAt(0).toUpperCase() + sex.slice(1);
      return this.get('employees').filterBy('sex', sex).length > 0;
    },

    isAnyEmployees: Ember.computed('employees.isFulfilled', function () {
      return this.get('employees.length') > 0;
    }),

    isActive: function isActive(sex) {
      return this.get('sex') == sex ? ' active' : '';
    },


    anyClass: Ember.computed('isAnyEmployees', 'sex', function () {
      return this.isActive('any');
    }),

    maleClass: Ember.computed('isAnyEmployees', 'sex', function () {
      return this.isActive('male') + (this.checkIsAnySexEqualTo('Male') ? '' : 'disabled');
    }),

    femaleClass: Ember.computed('isAnyEmployees', 'sex', function () {
      return this.isActive('female');
    }),

    specificEmployeeClass: Ember.computed('employee', 'sex', function () {
      return this.get('employee') ? 'active' : '';
    }),

    isShowComponent: Ember.computed('model.location.activeServices.{isPending,isFulfilled}', 'model.service_code', function () {
      var model = this.get('model');
      return model.get('location.activeServices.isPending') || model.get('location.activeServices.isFulfilled') && model.get('service_code');
    }),

    actions: {
      sex: function sex(_sex) {
        if (_sex == 'any' || this.checkIsAnySexEqualTo(_sex)) {
          this.set('sex', _sex);
          this.set('employee', null);
          this.sendAction('selectSexAction', _sex);
        }
        var item = $('.footer').offset().top;
        this.get('scrollTo').jQueryJumpTo(item, 500);
      },
      employee: function employee(employeeId) {
        var employee = this.get('employees').filterBy('id', employeeId)[0] || null;
        this.set('employee', employee);
        if (employee) {
          this.sendAction('selectSexAction', employee.get('sex'));
          this.sendAction('selectEmployeeAction', employee);
        } else {
          this.sendAction('selectSexAction', 'any');
        }
        var item = $('.footer').offset().top;
        this.get('scrollTo').jQueryJumpTo(item, 500);
      },
      setAnyEmployees: function setAnyEmployees() {
        this.actions.sex.call(this, 'any');
      }
    }
  });
});