define('handandstone-ember/pods/components/account/appointments/list-filter/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: [''],
    tagName: 'form',

    items: function () {
      return [{
        value: moment().format('YYYY-MM-DD'),
        title: 'Show Only Upcoming Appointments'
      }, {
        value: moment().subtract(3, 'months').format('YYYY-MM-DD'),
        title: 'Show the last 3 months'
      }, {
        value: moment().subtract(6, 'months').format('YYYY-MM-DD'),
        title: 'Show the last 6 months'
      }, {
        value: moment().subtract(1, 'years').format('YYYY-MM-DD'),
        title: 'Show the last 12 months'
      }, {
        value: 'show_cancellations',
        title: 'Show Only Cancelled Appointments'
      }];
    }.property(),

    selectedValue: Ember.computed('start_date', 'show_cancellations', function () {
      if (this.get('show_cancellations')) {
        return 'show_cancellations';
      } else {
        return this.get('start_date');
      }
    }),

    didRender: function didRender() {
      page.customSelect();
    },


    actions: {
      setValue: function setValue(value) {
        if (value === 'show_cancellations') {
          this.set('show_cancellations', true);
          this.set('start_date', moment().subtract(1, 'years').format('YYYY-MM-DD'));
          this.set('page', 1);
        } else {
          this.set('start_date', value);
          this.set('show_cancellations', false);
          this.set('page', 1);
        }
      }
    }
  });
});