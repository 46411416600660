define('handandstone-ember/pods/components/hot-deals/slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),

    classNames: 'container',
    //classNameBindings: ['variableClasses'],
    current: 1,
    total: 1,
    breakpoints: [{
      breakpoint: 10000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }, {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }],

    // variableClasses: Ember.computed('deals.isPending', 'deals.isFulfilled', function () {
    //   return this.get('deals.isPending') ? 'loading' : '';
    // }),

    showDeals: Ember.computed('deals', 'deals.isPending', function () {
      return !this.get('deals.isPending') && this.get('deals.length');
    }),

    setCounter: function setCounter(opt) {
      var curB = opt.activeBreakpoint;
      this.set('current', Math.ceil((opt.currentSlide + 1) / opt.breakpointSettings[curB].slidesToShow));
      this.set('total', Math.ceil(opt.slideCount / opt.breakpointSettings[curB].slidesToShow));
    },


    isDisplayingShowMoreButton: function () {
      return this.get('meta.totalCount') > this.get('meta.perPage');
    }.property('meta.{totalCount,perPage}'),

    actions: {
      sliderInit: function sliderInit(el) {
        this.setCounter(Ember.$(el)[0].slick);
      },
      afterChange: function afterChange(opt) {
        this.setCounter(opt);
      },
      locationsPopUp: function locationsPopUp() {
        this.sendAction('modalAction', 'locations/locations-popup');
      }
    }
  });
});