define('handandstone-ember/pods/components/service/addon-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'item add-services',
    showBookButton: false,
    isParity: function isParity() {
      return this.get('index') % 2 == 0;
    },

    image: function () {
      return this.isParity() ? this.get('model.image_right') : this.get('model.image_left');
    }.property(),
    actions: {
      bookingAction: function bookingAction(template, deferFunction) {
        this.sendAction('bookingAction', template, deferFunction);
      }
    }
  });
});