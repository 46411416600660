define('handandstone-ember/pods/employee-schedule/adapter', ['exports', 'handandstone-ember/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    pathForType: function pathForType() {
      return 'employees-schedule';
    },


    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var pathParams = query.paramsForPath;
      delete query.paramsForPath;
      var result = this._super(modelName, id, snapshot, requestType, query);
      if (requestType == 'query') {
        result = result.replace(this.urlPrefix(), this.urlPrefix() + '/locations/' + pathParams.location_id + '/services/' + pathParams.service_id);
      }
      return result;
    }
  });
});