define('handandstone-ember/mixins/authenticate-popup-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    modal: Ember.inject.service(),
    session: Ember.inject.service(),

    triggerAuthentication: function triggerAuthentication() {
      this._super.apply(this, arguments);

      var authenticationRoute = this.get('authenticationRoute');
      var currentHandlerInfos = this.router.router.currentHandlerInfos;
      var previousRoute = currentHandlerInfos[currentHandlerInfos.length - 1].name;

      if (!_authenticatedRouteMixin.default.detect(previousRoute) || previousRoute === undefined) {
        this.transitionTo(authenticationRoute).abort();
      }
      this.get('modal').close();
      this.get('modal').open('login');
    }
  });
});