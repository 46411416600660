define('handandstone-ember/pods/acg-submitted-email/model', ['exports', 'ember-data', 'handandstone-ember/mixins/get-validation-props'], function (exports, _emberData, _getValidationProps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationsObj = _getValidationProps.default.returnValidationObj(['name', 'email', 'phone']);

  validationsObj['phone'].forEach(function (item) {
    if (item._type === 'presence') {
      validationsObj['phone'].removeObject(item);
    }
  });

  var Validations = _getValidationProps.default.buildValidations(validationsObj);
  exports.default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    follow_up: _emberData.default.attr('boolean', {
      defaultValue: false
    })
  });
});