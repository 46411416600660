define('handandstone-ember/pods/components/account/information/info-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),

    classNames: 'info-form',
    showError: false,

    willClearRender: function willClearRender() {
      this.get('model').rollbackAttributes();
    },


    states: function () {
      return [{
        "name": "Alabama",
        "abbreviation": "AL"
      }, {
        "name": "Alaska",
        "abbreviation": "AK"
      }, {
        "name": "American Samoa",
        "abbreviation": "AS"
      }, {
        "name": "Arizona",
        "abbreviation": "AZ"
      }, {
        "name": "Arkansas",
        "abbreviation": "AR"
      }, {
        "name": "California",
        "abbreviation": "CA"
      }, {
        "name": "Colorado",
        "abbreviation": "CO"
      }, {
        "name": "Connecticut",
        "abbreviation": "CT"
      }, {
        "name": "Delaware",
        "abbreviation": "DE"
      }, {
        "name": "District Of Columbia",
        "abbreviation": "DC"
      }, {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
      }, {
        "name": "Florida",
        "abbreviation": "FL"
      }, {
        "name": "Georgia",
        "abbreviation": "GA"
      }, {
        "name": "Guam",
        "abbreviation": "GU"
      }, {
        "name": "Hawaii",
        "abbreviation": "HI"
      }, {
        "name": "Idaho",
        "abbreviation": "ID"
      }, {
        "name": "Illinois",
        "abbreviation": "IL"
      }, {
        "name": "Indiana",
        "abbreviation": "IN"
      }, {
        "name": "Iowa",
        "abbreviation": "IA"
      }, {
        "name": "Kansas",
        "abbreviation": "KS"
      }, {
        "name": "Kentucky",
        "abbreviation": "KY"
      }, {
        "name": "Louisiana",
        "abbreviation": "LA"
      }, {
        "name": "Maine",
        "abbreviation": "ME"
      }, {
        "name": "Marshall Islands",
        "abbreviation": "MH"
      }, {
        "name": "Maryland",
        "abbreviation": "MD"
      }, {
        "name": "Massachusetts",
        "abbreviation": "MA"
      }, {
        "name": "Michigan",
        "abbreviation": "MI"
      }, {
        "name": "Minnesota",
        "abbreviation": "MN"
      }, {
        "name": "Mississippi",
        "abbreviation": "MS"
      }, {
        "name": "Missouri",
        "abbreviation": "MO"
      }, {
        "name": "Montana",
        "abbreviation": "MT"
      }, {
        "name": "Nebraska",
        "abbreviation": "NE"
      }, {
        "name": "Nevada",
        "abbreviation": "NV"
      }, {
        "name": "New Hampshire",
        "abbreviation": "NH"
      }, {
        "name": "New Jersey",
        "abbreviation": "NJ"
      }, {
        "name": "New Mexico",
        "abbreviation": "NM"
      }, {
        "name": "New York",
        "abbreviation": "NY"
      }, {
        "name": "North Carolina",
        "abbreviation": "NC"
      }, {
        "name": "North Dakota",
        "abbreviation": "ND"
      }, {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
      }, {
        "name": "Ohio",
        "abbreviation": "OH"
      }, {
        "name": "Oklahoma",
        "abbreviation": "OK"
      }, {
        "name": "Oregon",
        "abbreviation": "OR"
      }, {
        "name": "Palau",
        "abbreviation": "PW"
      }, {
        "name": "Pennsylvania",
        "abbreviation": "PA"
      }, {
        "name": "Puerto Rico",
        "abbreviation": "PR"
      }, {
        "name": "Rhode Island",
        "abbreviation": "RI"
      }, {
        "name": "South Carolina",
        "abbreviation": "SC"
      }, {
        "name": "South Dakota",
        "abbreviation": "SD"
      }, {
        "name": "Tennessee",
        "abbreviation": "TN"
      }, {
        "name": "Texas",
        "abbreviation": "TX"
      }, {
        "name": "Utah",
        "abbreviation": "UT"
      }, {
        "name": "Vermont",
        "abbreviation": "VT"
      }, {
        "name": "Virgin Islands",
        "abbreviation": "VI"
      }, {
        "name": "Virginia",
        "abbreviation": "VA"
      }, {
        "name": "Washington",
        "abbreviation": "WA"
      }, {
        "name": "West Virginia",
        "abbreviation": "WV"
      }, {
        "name": "Wisconsin",
        "abbreviation": "WI"
      }, {
        "name": "Wyoming",
        "abbreviation": "WY"
      }];
    }.property().readOnly(),

    beforeSavedEmail: null,

    checkEmailHasChanged: function checkEmailHasChanged() {
      return this.get('beforeSavedEmail') !== this.get('model.email');
    },
    didRender: function didRender() {
      page.customSelect();
    },


    actions: {
      setValue: function setValue(value) {
        this.set('model.state', value);
      },

      save: function save() {
        var _this = this;

        this.get('model').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.set('beforeSavedEmail', _this.get('model.email'));
            if (!_this.get('model.state')) {
              _this.set('model.state', 'AL');
            }
            if (!_this.get('model.isSaving')) {
              _this.get('model').save().then(function () {
                _this.set('showError', false);
                var message = 'Your profile information was updated';
                if (_this.checkEmailHasChanged()) {
                  message += '. Please check your email for confirm your new email address';
                }
                _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: message } });
              }).catch(function () {
                _this.set('showError', true);
              });
            }
          } else {
            _this.set('showError', true);
          }
        });
      }
    }
  });
});