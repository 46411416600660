define('handandstone-ember/mixins/unauthenticated-mixin', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_unauthenticatedRouteMixin.default, {
    session: Ember.inject.service('session'),

    beforeModel: function beforeModel() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('homepage');
      }

      this._super.apply(this, arguments);
    }
  });
});