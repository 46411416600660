define('handandstone-ember/pods/components/modal-leaflat-popup/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    modal: Ember.inject.service(),
    'location-leaflat': Ember.inject.service(),
    locationService: Ember.inject.service('location'),

    location: function () {
      return this.get('model.options.model.location');
    }.property('model.options.model.location'),

    search: function () {
      return this.get('model.options.model.search');
    }.property('model.options.model.search'),

    actions: {
      closePopup: function closePopup() {
        this.set('location-leaflat.isPopupOpened', false);
        this.get('modal').close();
      },
      selectLocation: function selectLocation(locationId) {
        this.get('locationService').setLocationId(locationId);
      },
      notify: function notify() {
        this.get('modal').close();
        this.get('modal').open('coming-soon-notify', { location_id: this.get('location.id') });
      }
    }
  });
});