define('handandstone-ember/helpers/or-operator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.orOperator = orOperator;
  function orOperator(params) {
    var flag = false,
        i = 0;
    for (i; i < params.length; i++) {
      if (params[i]) {
        flag = true;
        break;
      }
    }
    return flag;
  }

  exports.default = Ember.Helper.helper(orOperator);
});