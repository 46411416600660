define('handandstone-ember/pods/location/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    location: Ember.inject.service(),
    modal: Ember.inject.service(),
    scrollTo: Ember.inject.service('scroll-to'),
    fastboot: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      return this.get('location').getBySlug(params.slug).then(function (result) {
        _this.set('location.singleLocationId', result.get('id'));
        return result;
      }).catch(function (error) {
        _this.send('error', error);
      });
    },
    afterModel: function afterModel(model) {
      this.set('additionalHeadTags', [{
        type: 'meta',
        attrs: {
          name: 'description',
          content: model.get('name') + 'Massage and Facial Spa in ' + model.get('city') + ', ' + model.get('state') + ' provides professional spa experiences at affordable prices seven days a week. Book now!'
        }
      }]);
      this._super.apply(this, arguments);
    },


    setupController: function setupController(controller, model) {
      var isShowZenotiTab = this.checkIsShowZenotiTab(model),
          isZenotiTabActive = false;

      if (isShowZenotiTab) {
        var urlHash = this.get('router.url').split('#')[1];
        isZenotiTabActive = urlHash === controller.get('zenotiTabIdName');
      }

      controller.setProperties({
        model: model,
        isShowZenotiTab: isShowZenotiTab,
        isZenotiTabActive: isZenotiTabActive
      });
    },

    checkIsShowZenotiTab: function checkIsShowZenotiTab(model) {
      //is_coming_soon = true - hide
      //zenoti_tab = true - show
      return model.get('zenoti_tab') && !model.get('is_coming_soon');
    },


    actions: {
      notify: function notify() {
        this.get('modal').open('coming-soon-notify', { location_id: this.currentModel.get('id') });
      },


      didTransition: function didTransition() {
        // Element does not exist.
        Ember.run.scheduleOnce('afterRender', this, function () {
          // If the models are already cached, the element exists.
          if (!this.get('fastboot.isFastBoot')) {
            var intakeForm = Ember.$('.intake-form');

            if (intakeForm.length) {
              var position = intakeForm.offset().top - 50;
              var hash = Ember.$(location).attr('hash');
              if (position && hash === '#intake-form') {
                this.get('scrollTo').jQueryJumpTo(position);
              }
            }
          }
        });
        return true;
      }
    }
  });
});