define('handandstone-ember/services/seo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    extendedMeta: [],
    priorityExtendedMeta: [],
    isLoadedSeoModule: false,
    seoModelsPromise: null,

    getSeoModels: function getSeoModels() {
      var _this = this;

      if (this.get('isLoadedSeoModule')) {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(_this.get('store').peekAll('seo-tag'));
        });
      }

      if (!this.get('seoModelsPromise')) {
        var result = this.get('store').query('seo-tag', {}).then(function (result) {
          _this.set('isLoadedSeoModule', true);
          _this.set('seoModelsPromise', null);
          return result;
        });

        this.set('seoModelsPromise', result);
      }

      return this.get('seoModelsPromise');
    }
  });
});