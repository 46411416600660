define('handandstone-ember/pods/result-classification/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    "slug": _emberData.default.attr('string'),
    "title": _emberData.default.attr('string'),
    "result-services": _emberData.default.attr(),

    classification: null
  });
});