define('handandstone-ember/services/geo-location', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    searchByCoordinates: function searchByCoordinates(lat, lng) {
      return new Promise(function (resolve, reject) {
        if (lat && lng) {
          var latLng = new google.maps.LatLng(lat, lng);
          var geoCoder = new google.maps.Geocoder();

          geoCoder.geocode({ 'latLng': latLng }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK) {
              if (results[1]) {
                for (var i = 0; i < results[0].address_components.length; i++) {
                  for (var b = 0; b < results[0].address_components[i].types.length; b++) {
                    if (results[0].address_components[i].types[b] === "administrative_area_level_1") {
                      return resolve(results[0].address_components[i]);
                    }
                  }
                }
              }
            }
            return reject(null);
          });
        } else {
          reject(null);
        }
      });
    },
    searchByAddress: function searchByAddress(address) {
      return new Promise(function (resolve, reject) {
        if (address) {
          var geoCoder = new google.maps.Geocoder();

          geoCoder.geocode({ 'address': address }, function (results, status) {
            if (status === google.maps.GeocoderStatus.OK && results[0].geometry.location.lat() && results[0].geometry.location.lng()) {
              resolve({ lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng() });
            } else {
              reject(false);
            }
          });
        } else {
          reject(false);
        }
      });
    }
  });
});