define('handandstone-ember/pods/components/locations/location-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),

    tagName: "li",
    nameMaxLength: 35,
    addressMaxLength: 66,

    isEqualLocations: function () {
      return this.get('location.id') === this.get('locationService.locationId');
    }.property('location.id', 'locationService.locationId'),

    actions: {
      selectLocation: function selectLocation(locationId) {
        this.sendAction('selectLocation', locationId);
      },
      notify: function notify() {
        this.get('modal').open('coming-soon-notify', { location_id: this.get('location.id') });
      },
      purchaseGiftCardClick: function purchaseGiftCardClick() {
        //Amazon Advertising Tag
        amzn("trackEvent", "Lead_IHM 317 Hand and Stone Locations Purchase Gift Card");
      }
    }
  });
});