define('handandstone-ember/initializers/ember-stripe-service', ['exports', 'handandstone-ember/config/environment', 'handandstone-ember/services/stripe-mock'], function (exports, _environment, _stripeMock) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];
    application.register('config:ember-stripe-service', _environment.default, { instantiate: false });
    application.inject('service:stripe', 'config', 'config:ember-stripe-service');

    if (_environment.default.LOG_STRIPE_SERVICE) {
      Ember.Logger.info('StripeService: initialize');
    }

    if (!_environment.default.stripe.publishableKey) {
      throw new Ember.Error('StripeService: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js');
    }

    if (typeof FastBoot !== 'undefined' && typeof Stripe === 'undefined') {
      window.Stripe = _stripeMock.default;
    }

    Stripe.setPublishableKey(_environment.default.stripe.publishableKey);
  } /* global Stripe */
  exports.default = {
    name: 'ember-stripe-stripe',
    initialize: initialize
  };
});