define('handandstone-ember/pods/special-offer/model', ['exports', 'ember-data', 'handandstone-ember/mixins/get-validation-props'], function (exports, _emberData, _getValidationProps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationsObj = _getValidationProps.default.returnValidationObj(['first_name', 'last_name', 'email']);

  var Validations = _getValidationProps.default.buildValidations(validationsObj);
  exports.default = _emberData.default.Model.extend(Validations, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    send_email: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    coupon: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    price_text: _emberData.default.attr('string'),
    footer_text: _emberData.default.attr('string'),
    barcode: _emberData.default.attr('string', { defaultValue: "/H&S barcode.png" })
  });
});