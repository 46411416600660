define('handandstone-ember/pods/components/booking/error-countdown/component', ['exports', 'handandstone-ember/mixins/modal-native-mixin'], function (exports, _modalNativeMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_modalNativeMixin.default, {
        classNames: ["popup", "small-popup", "show"],
        elementId: 'info-popup',
        actions: {
            closeModal: function closeModal() {
                this.sendAction('closeModalAndRefresh');
            }
        }
    });
});