define('handandstone-ember/pods/components/booking/service-addons-change/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: 'add-select',
    addons: Ember.computed('selectedAddons', function () {
      var addons = [],
          addonsSorted = [],
          self = this;
      self.get('allAddons').map(function (item, i) {
        addons.push({
          isChecked: false,
          addon: item
        });
        self.get('selectedAddons').map(function (itemSelected) {
          if (itemSelected.get('addon_id') == item.get('addon_id')) {
            addons[i].isChecked = true;
          }
        });
      });
      addons.forEach(function (obj) {
        if (obj.isChecked) {
          addonsSorted.unshift(obj);
        } else {
          addonsSorted.push(obj);
        }
      });
      return addonsSorted;
    }),
    actions: {
      addAddOn: function addAddOn(item, index) {
        this.set('addons.' + index + '.isChecked', !item.isChecked);
        this.sendAction('toggleAddonAction', item.addon);
      }
    }
  });
});