define('handandstone-ember/pods/components/simple-menu/item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: [],
    tagName: 'li',
    menuItem: null,
    itemLinkText: true
  });
});