define('handandstone-ember/pods/components/hot-deals/pagination/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pagination'],
    classNameBindings: ['showPagination:has-items'],
    nextPage: 1,
    prevPage: 1,

    showPagination: Ember.computed('meta.pageCount', function () {
      return this.get('meta.pageCount') > 1;
    }),

    showPrevious: Ember.computed('meta.currentPage', function () {
      if (this.get('meta.currentPage') > 1) {
        this.set('prevPage', this.get('meta.currentPage') - 1);
        return true;
      }
      return false;
    }),

    showNext: Ember.computed('meta.currentPage', function () {
      if (this.get('meta.currentPage') < this.get('meta.pageCount')) {
        this.set('nextPage', this.get('meta.currentPage') + 1);
        return true;
      }
      return false;
    }),

    pageNumbers: Ember.computed('meta.{currentPage,pageCount}', function () {
      var currentPage = this.get('meta.currentPage'),
          pageCount = this.get('meta.pageCount'),
          previousLinks = currentPage - 2,
          nextLinks = currentPage + 2,
          delimiter = true,
          pageNumbers = [];
      if (previousLinks > 1) {
        pageNumbers.push({ number: 1 });
        if (previousLinks > 2 && delimiter) {
          pageNumbers.push({ number: false });
        }
      }

      for (var i = previousLinks; i <= nextLinks; i++) {
        if (currentPage === i) {
          pageNumbers.push({ number: i, class: 'active' });
        } else if (i > 0 && i < this.get('meta.pageCount') + 1) {
          pageNumbers.push({ number: i });
        }
      }

      if (nextLinks < pageCount) {
        if (nextLinks < pageCount - 1 && delimiter) {
          pageNumbers.push({ number: false });
        }
        pageNumbers.push({ number: pageCount });
      }

      return pageNumbers;
    }),

    changeCurrentPage: function () {
      $('html, body').animate({
        scrollTop: parseInt($('.spa-deals-page > .container').offset().top - $('header').outerHeight())
      }, 500);
    }.observes('page'),

    actions: {
      page: function page(number) {
        this.set('page', number);
      }
    }
  });
});