define('handandstone-ember/pods/components/booking/service-addons/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    modal: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var isChecked = this.get('selectedAddons').some(function (addon) {
        return addon == _this.get('addon');
      });
      this.set('checked', isChecked);
    },

    actions: {
      addAddOn: function addAddOn(addon) {
        this.toggleProperty('checked');
        this.sendAction('toggleAddonAction', addon);
      },
      info: function info(addon) {
        this.get('modal').open('booking-addon-info', { model: addon });
      }
    }
  });
});