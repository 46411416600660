define('handandstone-ember/mixins/seo-module-route', ['exports', 'lodash', 'handandstone-ember/config/environment'], function (exports, _lodash, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    store: Ember.inject.service(),
    headTagsService: Ember.inject.service('head-tags'),
    seo: Ember.inject.service(),

    headTagsArray: [],
    additionalHeadTags: [],
    autoGeneratedHeadTags: [],

    afterModel: function afterModel(model, transition) {
      var _this = this;

      transition.then(function () {
        _this.get('seo').getSeoModels().then(function (result) {
          var seoTagsModels = result.filter(function (role) {
            return role.get('url') === _this.get('router.url');
          });
          _this.setHeadTags(seoTagsModels);
          _this.setAutoGeneratedSeoTags();
          _this.get('headTagsService').collectHeadTags();
        });
      });
      this._super.apply(this, arguments);
    },
    setHeadTags: function setHeadTags(seoTagsModels) {
      var _this2 = this;

      seoTagsModels = seoTagsModels || [];
      var result = [];

      seoTagsModels.forEach(function (model) {
        if (model.get('type') === 'meta') {
          result.push({
            type: 'meta',
            attrs: {
              name: model.get('name'),
              content: model.get('content')
            }
          });
        } else if (model.get('type') === 'tag') {
          if (model.get('name') === 'title') {
            _this2.controller.set('title', model.get('content'));
          } else {
            result.push({
              type: model.get('name'),
              content: model.get('content')
            });
          }
        }
      });

      this.set('headTagsArray', result);
    },
    setAutoGeneratedSeoTags: function setAutoGeneratedSeoTags() {
      var result = [{
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: _environment.default.APP.CURRENT_URl + this.getCanonicalUrl(this.get('router.url'))
        }
      }];

      this.set('autoGeneratedHeadTags', result);
    },
    getCanonicalUrl: function getCanonicalUrl(routeURL) {
      if (routeURL !== "/") {
        routeURL = routeURL.replace(/\?.*/, '');
      }
      return routeURL;
    },


    headTags: function headTags() {
      var result = this.addToArrayByFirstPriority(this.get('seo.priorityExtendedMeta'), this.get('headTagsArray'));
      result = this.addToArrayByFirstPriority(result, this.get('additionalHeadTags'));
      result = this.addToArrayByFirstPriority(result, this.get('seo.extendedMeta'));
      result = this.addToArrayByFirstPriority(result, this.get('autoGeneratedHeadTags'));

      return result;
    },

    addToArrayByFirstPriority: function addToArrayByFirstPriority(firstTags, secondTags) {
      var result = [];

      if (firstTags && secondTags && !_lodash.default.isEmpty(firstTags) && !_lodash.default.isEmpty(secondTags)) {
        result = firstTags;

        secondTags.forEach(function (secondMeta) {
          var isNotIsSetSuchTag = _lodash.default.isEmpty(firstTags.filter(function (firstMeta) {
            var result = false;
            if (secondMeta.attrs) {
              if (secondMeta.attrs.name) {
                result = Boolean(_lodash.default.get(firstMeta, 'attrs.name', null) && firstMeta.attrs.name === secondMeta.attrs.name);
              } else if (secondMeta.attrs.rel) {
                result = Boolean(_lodash.default.get(firstMeta, 'attrs.rel', null) && firstMeta.attrs.rel === secondMeta.attrs.rel);
              }
            } else {
              result = Boolean(_lodash.default.get(firstMeta, 'type', null) && firstMeta.type === secondMeta.type);
            }
            return result;
          }));

          if (isNotIsSetSuchTag) {
            result.push(secondMeta);
          }
        });
      } else {
        result = _lodash.default.isEmpty(firstTags) ? secondTags : firstTags;
      }
      return result;
    },


    actions: {
      willTransition: function willTransition() {
        this.setProperties({
          'seo.extendedMeta': [],
          'seo.priorityExtendedMeta': []
        });
        return true;
      }
    }
  });
});