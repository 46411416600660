define('handandstone-ember/services/yodle', ['exports', 'handandstone-ember/config/environment', 'ember-fetch/ajax'], function (exports, _environment, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    phoneSwap: true,
    api: {},
    setYodle: null,
    providerTriggerValue: 'google',
    fastboot: Ember.inject.service(),

    initAuthorization: function initAuthorization(id) {
      var _this = this;

      if (_environment.default.APP.YODLE_ENABLE && this.getYodleId(id)) {
        if (!this.get('fastboot.isFastBoot')) {
          Ember.$.ajax({
            url: "https://yotrack.cdn.ybn.io/yotrack.min.js",
            dataType: "script",
            cache: true
          }).done(function () {
            if (typeof _this.get('api.' + _this.getYodleId(id)) === "undefined") {
              new YoTrack(_this.getYodleId(id), function (err, api) {
                if (!err && api) {
                  _this.set('api.' + _this.getYodleId(id), api);
                  _this.notifyPropertyChange('setYodle');
                }
              });
            } else {
              _this.notifyPropertyChange('setYodle');
            }
          });
        } else {
          (0, _ajax.default)("https://yotrack.cdn.ybn.io/yotrack.min.js", {
            method: 'GET',
            dataType: "script",
            cache: true
          }).then(function () {
            if (typeof _this.get('api.' + _this.getYodleId(id)) === "undefined") {
              new YoTrack(_this.getYodleId(id), function (err, api) {
                if (!err && api) {
                  _this.set('api.' + _this.getYodleId(id), api);
                  _this.notifyPropertyChange('setYodle');
                }
              });
            } else {
              _this.notifyPropertyChange('setYodle');
            }
          });
        }
      }
    },

    trackData: function trackData(id, data, callBack) {
      if (typeof this.get('api.' + this.getYodleId(id)) !== "undefined") {
        this.get('api.' + this.getYodleId(id)).trackData(data, callBack);
      }
    },

    swapPhones: function swapPhones(id, data) {
      if (typeof this.get('api.' + this.getYodleId(id)) !== "undefined" && this.get('phoneSwap') && this.checkEnableSwapPhone()) {
        this.get('api.' + this.getYodleId(id)).swapPhones(data, function (err, line) {
          if (!err && line) {
            setSessionStorage(data, line);
            console.log('swapped_phone ' + line);
          }
        });
      }
    },

    getYodleId: function getYodleId(id) {
      var result = null;
      if (id) {
        if (typeof _environment.default.APP.YODLE_ID !== "undefined") {
          result = _environment.default.APP.YODLE_ID + "-" + id;
        } else {
          if (_environment.default.APP.TEST_YODLE_ID) {
            result = _environment.default.APP.TEST_YODLE_ID;
          }
        }
      }
      return result;
    },
    checkEnableSwapPhone: function checkEnableSwapPhone() {
      return Boolean(getSessionStorage('yodle_swap_phone'));
    },


    setSessionParameters: function setSessionParameters(transition) {
      var yodle_session_attribute = 'yodle_swap_phone';

      if (transition.queryParams[_environment.default.APP.YODLE_ON_SWAP_PARAMETER] && !getSessionStorage(yodle_session_attribute)) {
        setSessionStorage(yodle_session_attribute, true);
      }
    },

    yodleReferer: '',
    site_tracking_id: '',
    referralid: '',
    provider: false,
    redirectUrl: '',
    completeCapturedBookingData: false,
    completeTrackedYodleBookingData: false,

    trackYodleBooking: function trackYodleBooking(model) {
      var result = true;
      if (this.get('trackData')) {
        try {
          var addons = [];
          model.get('addons').map(function (addon) {
            addons.push({
              tile: Ember.copy(addon.get('title')),
              price: Ember.copy(addon.get('price'))
            });
          });

          this.trackData(model.get('location_id'), {
            location: Ember.copy(model.get('location.name')),
            service: Ember.copy(model.get('service.title')),
            duration: Ember.copy(model.get('duration.duration')),
            service_price: Ember.copy(model.get('duration.price')),
            add_ons: addons,
            employee: Ember.copy(model.get('employee.name')),
            date: Ember.copy(model.get('date')),
            time: Ember.copy(model.get('time')),
            first_name: Ember.copy(model.get('first_name')),
            last_name: Ember.copy(model.get('last_name')),
            email: Ember.copy(model.get('email')),
            phone: Ember.copy(model.get('phone')),
            total_price: Ember.copy(model.get('total'))
          }, function (err, line) {
            console.log(line);
          });
        } catch (err) {
          result = false;
        }
      } else {
        result = false;
      }
      this.set('completeTrackedYodleBookingData', true);
      return result;
    },

    processingBookingGetParams: function processingBookingGetParams(params) {
      this.set('cupturedBookingData', null);
      this.set('completeCapturedBookingData', false);
      this.set('completeTrackedYodleBookingData', false);

      if (params.provider === this.get('providerTriggerValue')) {
        this.set('provider', true);
      }

      this.set('site_tracking_id', params.site_tracking_id);
      this.set('referralid', params.referralid);

      if (this.get('provider') || params.site_tracking_id || params.referralid) {
        if (document.referrer.indexOf(location.protocol + "//" + location.host) !== 0 && document.referrer) {

          var referrerParameters = this.deserialize(document.referrer);

          if (referrerParameters.provider === this.get('providerTriggerValue')) {
            this.set('provider', true);
          }

          var referrer = document.referrer.split('?')[0];
          this.set('yodleReferer', referrer);
          setSessionStorage('yodleReferer', referrer);
        } else {
          this.set('yodleReferer', getSessionStorage('yodleReferer'));
        }
      } else {
        this.set('yodleReferer', '');
      }
    },

    redirectToYodleCongratulation: function redirectToYodleCongratulation(model) {
      var params = {
        date: Ember.copy(model.get('date')),
        time: Ember.copy(model.get('time')),
        duration: Ember.copy(model.get('duration_time')),
        price: Ember.copy(model.get('total'))
      };

      if (this.get('referralid') || this.get('site_tracking_id') || this.get('provider')) {
        params['first_name'] = Ember.copy(model.get('first_name'));
        params['last_name'] = Ember.copy(model.get('last_name'));
        params['email'] = Ember.copy(model.get('email'));
        params['phone'] = Ember.copy(model.get('phone'));
        params['service'] = Ember.copy(model.get('service.title'));
        params['employee'] = Ember.copy(model.get('employee.name'));
        params['confirmationId'] = Ember.copy(model.get('bookingKey'));

        if (this.get('site_tracking_id')) {
          params['site_tracking_id'] = this.get('site_tracking_id');
        }

        if (this.get('referralid')) {
          params['referralid'] = this.get('referralid');
        }

        if (this.get('provider')) {
          params['provider'] = this.get('providerTriggerValue');
        }
      }

      //this.captureData(model);
      var hostUrl = this.getHostUrl(this.get('yodleReferer')) + '/';

      this.set('redirectUrl', hostUrl + 'thank_you.html?' + this.serialize(params));
      window.location.href = this.get('redirectUrl');
    },

    // redirectBookingCongratulation: function () {
    //   if(this.get('completeTrackedYodleBookingData') && this.get('redirectUrl')){
    //     window.location.replace(this.get('redirectUrl'));
    //   }
    // }.observes('redirectUrl,completeTrackedYodleBookingData'),

    captureData: function captureData(model) {
      var _this2 = this;

      try {
        return (0, _ajax.default)({
          url: "https://labs.natpal.com/capture.weblead",
          method: "POST",
          data: {
            firstName: Ember.copy(model.get('first_name')),
            lastName: Ember.copy(model.get('last_name')),
            email: Ember.copy(model.get('email')),
            phone: Ember.copy(model.get('phone')),
            date: Ember.copy(model.get('date')),
            time: Ember.copy(model.get('time')),
            service: Ember.copy(model.get('service.title')),
            duration: Ember.copy(model.get('duration.duration')),
            employee: Ember.copy(model.get('employee.name')),
            site_tracking_id: this.get('site_tracking_id'),
            referralid: this.get('referralid')
          },
          crossDomain: true,
          xhrFields: { withCredentials: true }
        }).then(function () {
          _this2.set('completeCapturedBookingData', true);
        }).catch(function () {
          _this2.set('completeCapturedBookingData', true);
        });
      } catch (message) {
        this.set('completeCapturedBookingData', true);
      }
    },

    serialize: function serialize(obj) {
      var str = [];
      for (var p in obj) {
        if (obj.hasOwnProperty(p)) {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
      }return str.join("&");
    },

    //Deserialize url string and return object than include GET parameters and values
    deserialize: function deserialize(str) {
      var splited = str.split('?');
      var result = {};

      if (splited.length > 1) {
        try {
          result = JSON.parse('{"' + decodeURI(splited.slice(-1).pop()).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
        } catch (err) {
          result = {};
        }
      }

      return result;
    },

    getHostUrl: function getHostUrl(url) {
      var a = document.createElement('a');
      a.href = url;

      return a.protocol + '//' + a.hostname;
    }
  });
});