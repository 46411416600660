define('handandstone-ember/pods/service/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    slug: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    additional_description: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    image_left: _emberData.default.attr('string'),
    image_right: _emberData.default.attr('string'),
    additional_image: _emberData.default.attr('string'),
    can_book: _emberData.default.attr(),
    isPopular: _emberData.default.attr(),
    menu: _emberData.default.attr(),
    addons: _emberData.default.attr(),
    nested: _emberData.default.attr(),
    options: _emberData.default.attr(),
    results: _emberData.default.attr(),
    work_results_title: _emberData.default.attr('string'),
    work_results: _emberData.default.attr(),
    images: _emberData.default.attr(),
    service_id: null,
    sub_description: _emberData.default.attr('string'),
    sub_image: _emberData.default.attr('string'),
    external_booking: _emberData.default.attr('boolean'),
    external_services: _emberData.default.attr(),

    class: function () {
      return this.get('store').findRecord('class', this.get('type'));
    }.property(),

    employees: _emberData.default.hasMany('employee', {
      async: true,
      reload: true,
      conditions: {
        service_id: 'service_id'
      }
    }),

    employeesSchedule: _emberData.default.hasMany('employee_schedule', {
      async: true,
      params: {
        service_id: 'service_id'
      },
      conditions: {
        date: 'date'
      }
    }),

    isMassage: Ember.computed('type', function () {
      return this.get('type') == 'MA';
    }).property(),

    isFacial: Ember.computed('type', function () {
      return this.get('type') == 'FI';
    }).property(),

    isNeveskin: Ember.computed('type', function () {
      return this.get('type') == 'NS';
    }).property(),

    isHasSubText: function () {
      return Boolean(this.get('sub_description') || this.get('sub_image'));
    }.property('sub_description', 'sub_image')
  });
});