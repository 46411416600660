define('handandstone-ember/pods/account/payment/model', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40
    })],
    number: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('cc-validation', {
      cards: ['visa', 'master-card', 'american-express', 'discover']
    })],
    cvv: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      integer: true,
      allowString: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 3,
      max: 4
    })],
    exp_month: [(0, _emberCpValidations.validator)('presence', true)],
    exp_year: [(0, _emberCpValidations.validator)('presence', true)],
    billing_zip: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
      min: 4,
      max: 10
    })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    is_default: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    card_id: _emberData.default.attr('string'),
    token: _emberData.default.attr('string'),
    last4: _emberData.default.attr('string'),
    brand: _emberData.default.attr('string'),

    brandImagePath: Ember.computed('brand', function () {
      var brands = {
        'Visa': '/slice/dist/images/svg/visa.svg',
        'American Express': '/slice/dist/images/svg/american-express.svg',
        'MasterCard': '/slice/dist/images/svg/master-card.svg',
        'Discover': '/slice/dist/images/svg/discover-network.svg',
        'JCB': '/slice/dist/images/svg/jcb.svg'
      };

      return brands[this.get('brand')];
    })
  });
});