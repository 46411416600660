define('handandstone-ember/pods/components/blog/filter-taxonomy/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'aside',
    store: Ember.inject.service(),
    categories: undefined,
    tags: undefined,

    allCategories: function () {
      return this.get('categories') || [];
    }.property('categories'),

    allTags: function () {
      return this.get('tags') || [];
    }.property('tags')
  });
});