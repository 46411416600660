define('handandstone-ember/pods/components/map-single-location/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['map'],
    zoom: 10,
    lat: 0,
    lng: 0,
    isFilled: Ember.computed('lan', 'lng', 'zoom', function () {
      return Boolean(this.get('lat')) && Boolean(this.get('lng')) && Boolean(this.get('zoom'));
    }),

    actions: {
      addMarker: function addMarker(e) {
        e.target._icon.setAttribute('alt', '');
        e.target._icon.removeAttribute('tabindex');
      },
      mapOnLoad: function mapOnLoad(e) {
        e.target._container.removeAttribute("tabindex");
      }
    }
  });
});