define('handandstone-ember/mixins/application-auth-route', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_applicationRouteMixin.default, {
    location: Ember.inject.service(),
    store: Ember.inject.service(),
    'booking-store': Ember.inject.service('booking-store'),

    sessionInvalidated: function sessionInvalidated() {
      //Clear stored booking if logout
      this.get('booking-store').clear();
      this.store.unloadAll('booking');
      this.deleteAllAuthModels();

      if (!this.get('location').isBookingRoute()) {
        return this._super.apply(this, arguments);
      }
    },
    sessionAuthenticated: function sessionAuthenticated() {
      if (!this.get('location').isBookingRoute()) {
        //Clear stored booking if sign in
        this.get('booking-store').clear();
        return this._super.apply(this, arguments);
      }
    },


    //Delete all authenticate models from ember store
    deleteAllAuthModels: function deleteAllAuthModels() {
      this.store.unloadAll('account/appointment');
      this.store.unloadAll('account/payment');
      this.store.unloadAll('account/information');
      this.store.unloadAll('account/information');
    }
  });
});