define('handandstone-ember/pods/auth/sign-up/route', ['exports', 'handandstone-ember/mixins/unauthenticated-mixin'], function (exports, _unauthenticatedMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedMixin.default, {
    model: function model() {
      return this.get('store').createRecord('auth/sign-up');
    }
  });
});