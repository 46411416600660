define('handandstone-ember/pods/components/page-loader/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    parentContext: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var alertElement = Ember.$('#' + this.elementId).siblings('.sr-loading7-alert');

      if (alertElement.length) {
        alertElement.html('<p>Alert. Content is loading</p>');
        setTimeout(function () {
          //Check if this function duplicates the same logic in willDestroyElement method
          if (!_this.isDestroyed) {
            alertElement.empty();
          }
        }, 500);
      }
    },

    willDestroyElement: function willDestroyElement() {
      var alertElement = Ember.$('#' + this.elementId).siblings('.sr-loading-alert');

      if (alertElement.length) {
        alertElement.html('<p>Alert. Content has finished loading</p>');
        setTimeout(function () {
          alertElement.empty();
        }, 500);
      }
    }
  });
});