define('handandstone-ember/pods/about-us/our-story/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    model: function model() {
      return this.get('store').findRecord('static-page', 'our-story');
    }
  });
});