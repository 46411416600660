define('handandstone-ember/pods/components/service/tel-link/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service(),

    tagName: 'a',
    classNames: ['btn-blue', 'link'],
    attributeBindings: ['href', 'aria-describedby'],

    title: 'Call spa to book your service',
    click: function click() {
      if (this.get('location.currentRoute') === 'class.facial') {
        amzn("trackEvent", "Contact_IHM 317 Hand and Stone Facial Call spa to book your service");
      }
    },

    href: function () {
      if (this.get('location.selected.phoneNumber')) {
        return 'tel:' + this.get('location.selected.phoneNumber');
      } else {
        return '#';
      }
    }.property('location.selected.phoneNumber')
  });
});