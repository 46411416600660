define('handandstone-ember/pods/components/booking/appointment-date/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollTo: Ember.inject.service('scroll-to'),

    tagName: 'section',
    classNames: ['appointment-date'],
    classNameBindings: ['overlay'],
    currentDay: moment(new Date()),
    selectedDay: null,
    maxDays: 60,
    format: 'YYYYMMDD',
    isOpenCalendar: false,
    breakpoints: [{
      breakpoint: 10000,
      settings: {
        slidesToScroll: 4
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToScroll: 3
      }
    }],

    minDate: function () {
      return this.get('currentDay').format(this.get('format'));
    }.property('currentDay'),

    maxDate: function () {
      return this.get('currentDay').clone().add(this.get('maxDays') - 1, 'days').format(this.get('format'));
    }.property('currentDay', 'maxDays'),

    displayedDate: function () {
      return this.get('date') != null ? this.get('date') : this.get('currentDay').format(this.get('format'));
    }.property('date', 'currentDay'),

    changeIsOpenCalendar: function () {
      var _this = this;

      var isOpen = this.get('isOpenCalendar'),
          body = Ember.$('body');

      body.unbind('click.openCalendar');
      //Ember.$(window).trigger('resize'); // bugfix (close jcf-select in open calendar), seems to be no longer relevant

      if (isOpen) {
        if (/iP/i.test(navigator.userAgent)) {
          body.addClass('device-cursor');
        }
        body.on('click.openCalendar', function (ev) {
          if (!Ember.$(ev.target).closest(Ember.$('.calendar')).length) {
            _this.set('isOpenCalendar', false);
          }
        });
      } else {
        body.removeClass('device-cursor');
      }
    }.observes('isOpenCalendar'),

    goToSelectedSlide: function goToSelectedSlide(selectedDate) {
      var dontAnimate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      var format = this.get('format');

      for (var i = 0; i < this.get('days').length; i++) {
        if (this.get('days.' + i + '.value').format(format) == selectedDate) {
          var op = this.get('sliderO'),
              diff = op.slideCount - op.options.slidesToShow;
          this.get('slider').slick('slickGoTo', i < diff ? i : diff, dontAnimate);
          break;
        }
      }
    },


    changeSelectedDay: function () {
      var format = this.get('format'),
          days = this.get('days'),
          selectedDay = this.get('selectedDay') || null,
          selectedDayString = selectedDay ? selectedDay.format(format) : null,
          slider = this.get('slider');

      for (var i = 0; i < days.length; i++) {
        var isSelected = Boolean(days[i].value.format(format) == selectedDayString);

        this.set('days.' + i + '.activeClass', isSelected ? 'active' : '');
        this.set('days.' + i + '.isSelected', isSelected);
      }

      if (selectedDay && this.get('date') != selectedDay.format('YYYY-MM-DD')) {
        this.sendAction('selectAction', selectedDay.format('YYYY-MM-DD'));
      }

      if (selectedDay && slider) {
        var leftInterval = this.get('days.' + slider.find('.slick-current').attr('data-slick-index') + '.value'),
            rightInterval = this.get('days.' + slider.find('.slick-active:last').attr('data-slick-index') + '.value');
        if (leftInterval.diff(selectedDay, 'days', true) > 0 || rightInterval.diff(selectedDay, 'days', true) < 0) {
          this.goToSelectedSlide(selectedDayString, false);
        }
      }

      var dateBlock = Ember.$('.appointment-date'),
          position = parseInt(dateBlock.offset().top + dateBlock.outerHeight());

      this.get('scrollTo').jQueryJumpTo(position, 500);
    }.observes('selectedDay'),

    controlDate: function () {
      var result = this.get('date') != null ? moment(this.get('date')) : null;

      this.set('selectedDay', result);
    }.on('didInsertElement').observes('date'),

    didInsertElement: function didInsertElement() {
      var $slider = Ember.$(this.$('.date-list'));

      this.setProperties({
        'slider': $slider,
        'sliderO': $slider[0].slick
      });
    },

    days: Ember.computed(function () {
      var days = [],
          format = this.get('format'),
          disabledDates = this.get('disabledDates');

      for (var i = 0; i < this.get('maxDays'); i++) {
        var startDate = this.get('currentDay').clone().add(i, 'days');
        var title = startDate.calendar(null, {
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          nextWeek: 'dddd',
          lastDay: 'dddd',
          lastWeek: 'dddd',
          sameElse: 'dddd'
        });
        var startDateFormated = startDate.format(format),
            disabledClass = false;
        for (var j = 0; j < disabledDates.length; j++) {
          if (startDateFormated == disabledDates[j]) {
            disabledClass = true;
            break;
          }
        }

        var isSelected = Boolean(this.get('selectedDay') && this.get('selectedDay').format(format) == startDateFormated);

        days.push({
          title: title,
          date: startDate.format('MMM DD'),
          value: startDate,
          isSelected: isSelected,
          activeClass: isSelected ? 'active' : '',
          disabledClass: disabledClass ? 'disabled' : ''
        });
      }
      return days;
    }),

    disabledDates: function () {
      var availabilityDays = [],
          format = this.get('format'),
          days = [],
          data = this.get('dateAvailability'),
          minDate = this.get('minDate'),
          maxDate = this.get('maxDate');

      data.forEach(function (item) {
        availabilityDays.push(moment(item.get('date')).format(format));
      });

      for (var day = moment(minDate, format); day.format(format) <= moment(maxDate, format).format(format); day.add(1, 'days')) {
        if (availabilityDays.indexOf(day.format(format)) == -1) {
          days.push(day.format(format));
        }
      }
      return days;
    }.property('minDate', 'maxDate'),

    changeDisabledDates: function () {
      var days = this.get('days'),
          disabledDates = this.get('disabledDates'),
          format = this.get('format');

      for (var i = 0; i < days.length; i++) {
        var itemDate = this.get('days.' + i + '.value').format(format),
            disabledClass = false;
        for (var j = 0; j < disabledDates.length; j++) {
          if (itemDate == disabledDates[j]) {
            disabledClass = true;
            break;
          }
        }
        this.set('days.' + i + '.disabledClass', disabledClass ? 'disabled' : '');
      }
    }.observes('disabledDates'),

    actions: {
      selectDay: function selectDay(day, disabledClass) {
        if (!disabledClass) {
          this.set('selectedDay', day);
        }
      },
      slickInit: function slickInit() {
        if (this.get('date') != null) {
          this.goToSelectedSlide(this.get('selectedDay').format(this.get('format')));
        }
      },
      openCalendar: function openCalendar() {
        this.toggleProperty('isOpenCalendar');
      }
    }
  });
});