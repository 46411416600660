define('handandstone-ember/pods/components/location/introductory-prices/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service('location'),
    session: Ember.inject.service('session'),
    helper: Ember.inject.service('helper'),
    modal: Ember.inject.service(),
    forceView: false,
    showYield: false,
    customServices: null,
    description: 'Enjoy a relaxing massage or facial for our initial trial price.',

    currentLocation: Ember.computed('location.currentLocation', function () {
      return this.get('location.currentLocation');
    }),

    services: Ember.computed('currentLocation.introductory', 'customServices', function () {
      var _this = this;

      var service_code = [];
      if (this.get('customServices')) {
        this.get('customServices').forEach(function (service) {
          service_code.push({
            title: service.title,
            duration: service.duration,
            price: _this.get('helper').priceFormatting(service.price),
            external_service: service.external_service,
            is_coming_soon: true
          });
        });
      } else {
        if (this.get('currentLocation.introductory')) {
          this.get('currentLocation.introductory').forEach(function (service) {
            service_code.push({
              title: service.get('title'),
              duration: service.get('duration'),
              price: _this.get('helper').priceFormatting(service.get('price')),
              class_type: service.get('type'),
              service_code: service.get('slug'),
              service_id: service.get('service_id'),
              external_service: service.get('external_service')
            });
          });
        }
      }

      return service_code;
    }),

    isShowComponent: Ember.computed.or('isIntroSection', 'showYield').readOnly(),

    isIntroSection: function () {
      return Boolean(!this.get('session.isAuthenticated') && (this.get('services').length || !this.get('currentLocation.id')) || this.get('forceView'));
    }.property('session.isAuthenticated', 'currentLocation.id', 'services', 'forceView'),

    actions: {
      locationsPopUp: function locationsPopUp() {
        this.sendAction('modalAction', 'locations/locations-popup');

        if (this.get('location.currentRoute') === 'class.neveskin') {
          amzn("trackEvent", "Search_IHM 317 Hand and Stone Cryo Find Stores");
        } else if (this.get('location.currentRoute') === 'class.facial') {
          amzn("trackEvent", "Search_IHM 317 Hand and Stone Facial Find Stores");
        }
      },
      notify: function notify() {
        this.get('modal').open('coming-soon-notify', { location_id: this.get('currentLocation.id') });
      }
    }
  });
});