define('handandstone-ember/pods/components/header-main/add-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['add-info'],
    tagName: 'ul'
  });
});