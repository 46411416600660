define('handandstone-ember/pods/account/appointment/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'handandstone-ember/mixins/authenticate-popup-mixin'], function (exports, _authenticatedRouteMixin, _authenticatePopupMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authenticatePopupMixin.default, {
    model: function model(params) {
      if (!this.get('store').hasRecordForId('account/appointment', params.id)) {
        return this.get('store').findRecord('account/appointment', params.id);
      } else {
        return this.get('store').peekRecord('account/appointment', params.id);
      }
    }
  });
});