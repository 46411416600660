define('handandstone-ember/pods/components/account/payment/create-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    stripe: Ember.inject.service(),
    modal: Ember.inject.service(),
    account: Ember.inject.service('account'),
    classNameBindings: ['loadingClass'],
    classNames: ['frame-form'],
    showError: false,
    saveFormStatus: null,
    error: {},
    paymentFormId: 'payment-form',

    model: function () {
      return this.get('store').createRecord('account/payment');
    }.property(),

    validation: function () {
      return this.get('model.validations.attrs');
    }.property('model'),

    resetModel: function resetModel() {
      this.set('model', this.get('store').createRecord('account/password'));
    },


    months: function () {
      return moment.months();
    }.property(),

    years: function () {
      var result = {},
          currentYear = parseInt(moment().format('YYYY'));
      for (var i = 0; i < 20; i++) {
        result[currentYear + i] = currentYear + i;
      }
      return result;
    }.property(),

    didRender: function didRender() {
      page.customSelect();
    },


    isCardNameInvalid: Ember.computed('showError', 'validation.name.isInvalid', 'error.name', function () {
      return this.get('showError') && (this.get('validation.name.isInvalid') || this.get('error.name'));
    }).readOnly(),
    isCardNumberInvalid: Ember.computed('showError', 'validation.number.isInvalid', 'error.number', function () {
      return this.get('showError') && (this.get('validation.number.isInvalid') || this.get('error.number'));
    }).readOnly(),
    isCardCvvInvalid: Ember.computed('showError', 'validation.cvv.isInvalid', 'error.cvv', function () {
      return this.get('showError') && (this.get('validation.cvv.isInvalid') || this.get('error.cvv'));
    }).readOnly(),
    isBillingZipInvalid: Ember.computed('showError', 'validation.billing_zip.isInvalid', 'error.billing_zip', function () {
      return this.get('showError') && (this.get('validation.billing_zip.isInvalid') || this.get('error.billing_zip'));
    }).readOnly(),
    isCardExpYearInvalid: Ember.computed('showError', 'validation.card_exp_year.isInvalid', 'error.card_exp_year', function () {
      return this.get('showError') && (this.get('validation.card_exp_year.isInvalid') || this.get('error.card_exp_year'));
    }).readOnly(),

    isCardExpMonthInvalid: Ember.computed('showError', 'error.exp_month', 'model.exp_month', 'model.exp_year', 'validation.exp_month.isInvalid', 'validation.exp_year.isInvalid', function () {
      var result = false;
      if (this.get('showError')) {
        if (!this.get('validation.exp_month.isInvalid')) {
          if (!this.get('validation.exp_year.isInvalid')) {
            var creditCardDate = moment(this.get('model.exp_year') + this.get('model.exp_month'), "YYYYM");
            result = !(creditCardDate.isValid() && moment() < creditCardDate.add(1, 'months'));

            if (result) {
              this.set('error.exp_month', 'The month is invalid');
            }
          }
        } else {
          result = true;
        }
      }

      return result;
    }).readOnly(),

    actions: {
      setMonth: function setMonth(value) {
        this.set('model.exp_month', value);
        this.set('error.exp_month', false);
      },
      setYear: function setYear(value) {
        this.set('model.exp_year', value);
        this.set('error.exp_month', false);
      },
      setCardName: function setCardName(value) {
        this.set('model.name', value);
        this.set('error.name', false);
      },
      setCardNumber: function setCardNumber(value) {
        this.set('model.number', value);
        this.set('error.number', false);
      },
      setCardCvv: function setCardCvv(value) {
        this.set('model.cvv', value);
        this.set('error.cvv', false);
      },
      setBillingZip: function setBillingZip(value) {
        this.set('model.billing_zip', value);
        this.set('error.billing_zip', false);
      },
      save: function save() {
        var _this = this;

        this.set('showError', true);
        this.get('model').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.payment();
          }
        });
      }
    },

    payment: function payment() {
      var _this2 = this;

      var stripe = this.get('stripe'),
          form = Ember.$("#" + this.get('paymentFormId'));

      return stripe.card.createToken(form).then(function (response) {
        _this2.set('model.token', response.id);
        _this2.saveModel();
      }).catch(function (response) {
        var error = response.error;
        if (error) {
          switch (error.param) {
            case 'number':
              _this2.set('error.number', error.message);
              break;
            case 'name':
              _this2.set('error.name', error.message);
              break;
            case 'cvc':
              _this2.set('error.cvc', error.message);
              break;
            case 'exp_month':
              _this2.set('error.exp_month', error.message);
              break;
            case 'exp_year':
              _this2.set('error.exp_year', error.message);
              break;
            default:
              _this2.set('error.number', error.message);
              break;
          }
        }
      });
    },

    saveModel: function saveModel() {
      var _this3 = this;

      if (!this.get('model.isSaving')) {
        this.get('model').save().then(function () {
          _this3.get('account').notifyPropertyChange('paymentMethods');
          _this3.set('showError', false);
          _this3.set('model', _this3.get('store').createRecord('account/payment'));
          _this3.sendAction('resetModel');
          _this3.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'Payment method was added' } });
        }, function () {
          _this3.get('modal').open('small-popup', { model: { success: false, title: 'Error!', message: 'Your card was declined' } });
        });
      }
    }
  });
});