define('handandstone-ember/pods/components/booking/auth-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['authorization-block'],
    store: Ember.inject.service(),
    'booking-store': Ember.inject.service(),
    location: Ember.inject.service(),
    helper: Ember.inject.service(),

    model: null,
    isSignIn: true,

    setBaseLocalStorageBooking: function setBaseLocalStorageBooking(extendedParams) {
      this.set('booking-store.booking', {
        model: Object.assign({
          date: null,
          time: null,
          hot_deal: 0,
          location_id: this.get('location.bookingLocationId')
        }, extendedParams)
      });

      this.set('booking-store.booking.isDisplayAuthBlock', false);
      this.get('booking-store').write();
    },
    createNewBooking: function createNewBooking(params) {
      if (this.get('booking.model')) {
        this.get('booking').clear();
      }

      this.setBaseLocalStorageBooking(params);
      this.get('model').unloadRecord();
      this.sendAction('refreshBooking');
    },
    currentBookingContinue: function currentBookingContinue() {
      if (this.get('booking-store.booking.model')) {
        this.set('booking-store.booking.isDisplayAuthBlock', false);
        this.get('booking-store').write();
      } else {
        this.setBaseLocalStorageBooking({});
      }
      this.set('model.isDisplayAuthBlock', false);
      this.sendAction('refreshBooking');
    },
    newRegularBookingContinue: function newRegularBookingContinue() {
      this.createNewBooking({
        user_type: this.get('account.model.membership_status') ? this.get('helper.user_types.non_member') : this.get('helper.user_types.non_member')
      });
    },
    newFTVContinue: function newFTVContinue() {
      this.createNewBooking({
        user_type: this.get('helper.user_types.introductory')
      });
    },
    setContinueAsFTV: function setContinueAsFTV() {
      if (this.get('model.isIntroductory')) {
        this.currentBookingContinue();
      }
      if (this.get('model.isRegular') || this.get('model.isHotDeal')) {
        this.newFTVContinue();
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.get('isFirstTimeVisitorMode')) {
        this.setContinueAsFTV();
      }
    },


    actions: {
      continueAsGuest: function continueAsGuest() {
        if (this.get('model.isRegular') || this.get('model.isHotDeal')) {
          this.currentBookingContinue();
        }
        if (this.get('model.isIntroductory')) {
          this.newRegularBookingContinue();
        }
      },
      continueAsFTV: function continueAsFTV() {
        this.setContinueAsFTV();
      },
      formSwitch: function formSwitch() {
        this.set('isSignIn', !this.get('isSignIn'));
      },
      signIn: function signIn() {
        if (this.get('model.isHotDeal')) {
          this.currentBookingContinue();
        }
        if (this.get('model.isRegular')) {
          this.get('model').unloadRecord();
          this.currentBookingContinue();
        }
        if (this.get('model.isIntroductory')) {
          this.newRegularBookingContinue();
        }
      }
    }
  });
});