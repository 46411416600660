define("handandstone-ember/validators/cc-validation", ["exports", "ember-cp-validations/validators/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var CcValidation = _base.default.extend({
    validate: function validate(value, options) {

      //Accept only digits, dashes, spaces or parentheses
      if (/[^0-9-\s\(\)]+/.test(value)) {
        return "Invalid credit card number";
      }

      //Accept cards number with length between 12 and 19 characters
      value = value.replace(/\D/g, "");
      if (value.length < 12) {
        return "Credit card number is too short (minimum is 12 characters)";
      }
      if (value.length > 19) {
        return "Credit card number is too long (maximum is 19 characters)";
      }

      // The Luhn Algorithm.
      var nCheck = 0,
          bEven = false;

      for (var n = value.length - 1; n >= 0; n--) {
        var cDigit = value.charAt(n),
            nDigit = parseInt(cDigit, 10);

        if (bEven) {
          if ((nDigit *= 2) > 9) {
            nDigit -= 9;
          }
        }

        nCheck += nDigit;
        bEven = !bEven;
      }

      if (nCheck % 10 != 0) {
        return "Invalid credit card number";
      }

      //Check by options cards
      var validateRegExps = {
        'visa': /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
        'master-card': /^(?:5[1-5][0-9]{14})$/,
        'american-express': /^(?:3[47][0-9]{13})$/,
        'discover': /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
        'diners_club': /^(?:3(?:0[0-5]|[68][0-9])[0-9]{11})$/
      };

      var checkCreditCard = 'Card not supported!';
      if (options['cards']) {
        options['cards'].forEach(function (currentValue) {
          if (validateRegExps[currentValue] && value.match(validateRegExps[currentValue])) {
            checkCreditCard = true;
          }
        });
      }

      return checkCreditCard;
    }
  });

  CcValidation.reopenClass({
    getDependentsFor: function getDependentsFor() {
      return [];
    }
  });

  exports.default = CcValidation;
});