define('handandstone-ember/helpers/overlay-class', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    onModelLoad: Ember.observer('model.isFulfilled', function () {
      this.recompute();
    }),
    compute: function compute(params) {
      this.set('model', params[0]);
      return params[0] && params[0].isFulfilled == false ? 'overlay' : '';
    }
  });
});