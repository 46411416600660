define('handandstone-ember/pods/components/booking/contact-information/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.TextField.reopen({
    attributeBindings: ['data-stripe']
  });
  /* globals ga */
  exports.default = Ember.Component.extend({
    stripe: Ember.inject.service(),
    account: Ember.inject.service(),
    scrollTo: Ember.inject.service('scroll-to'),

    validation: null,
    error: {},
    paymentFormId: 'payment-form',
    model: null,
    showError: false,
    newPaymentMethodValue: 'new',
    isVisibleCardNumber: false,

    isNotShowPlaceholderCVV: function () {
      return Boolean(this.get('model.card_cvv'));
    }.property('isInputCvvChar', 'model.card_cvv'),

    isUsingNewMethod: Ember.computed('model.payment_method_id', 'account.model.membership_status', 'model.isHotDeal', function () {
      //return !this.get('model.payment_method_id');
      return Boolean(!this.get('account.model.membership_status') || this.get('model.isHotDeal'));
    }),

    isInvalidCheckbox: Ember.computed('showError', 'isChecked', function () {
      return Boolean(!this.get('isChecked') && this.get('showError'));
    }),

    errorClass: Ember.computed('isInvalidCheckbox', function () {
      return this.get('isInvalidCheckbox') ? 'has-error' : null;
    }),

    showPhoneNumber: Ember.computed('account.model.id', 'account.session.isAuthenticated', function () {
      var result = true;

      if (this.get('account.session.isAuthenticated')) {
        if (!this.get('account.model.id')) {
          result = false;
        }
      }
      return result;
    }),

    setAuthorizedUserData: function setAuthorizedUserData() {
      if (this.get('account.model.id')) {
        this.setProperties({
          'model.first_name': this.get('account.model.first_name'),
          'model.last_name': this.get('account.model.last_name'),
          'model.phone': this.get('account.model.phoneNumber'),
          'model.email': this.get('account.model.email'),
          'model.email_confirmation': this.get('account.model.membership_status') ? this.get('account.model.email') : null
        });
      }
    },


    observesAccountModel: function () {
      this.setAuthorizedUserData();
    }.observes('account.model.id'),

    // setDefaultPaymentMethod: function(){
    //   if(this.get('account.defaultPaymentModel')){
    //     this.set('model.payment_method_id', this.get('account.defaultPaymentModel.id'));
    //   }
    // },
    //
    // observesDefaultPaymentMethod: function () {
    //   this.setDefaultPaymentMethod();
    // }.observes('account.paymentMethods.@each.{id}'),

    months: function () {
      return moment.months();
    }.property(),

    years: function () {
      var result = {},
          currentYear = parseInt(moment().format('YYYY'));
      for (var i = 0; i < 20; i++) {
        result[currentYear + i] = currentYear + i;
      }
      return result;
    }.property(),

    didRender: function didRender() {
      page.customSelect();
    },


    hasFirstFormError: Ember.computed.or('errorClass', 'validation.first_name.isInvalid', 'validation.last_name.isInvalid', 'validation.email.isInvalid', 'validation.email_confirmation.isInvalid', 'validation.phone.isInvalid').readOnly(),

    hasPaymentFormError: Ember.computed.or('validation.card_exp_month.isInvalid', 'validation.card_exp_year.isInvalid', 'validation.card_name.isInvalid', 'validation.card_number.isInvalid', 'validation.card_cvv.isInvalid', 'validation.billing_zip.isInvalid').readOnly(),

    hasSecondFormError: Ember.computed('hasPaymentFormError', 'isUsingNewMethod', function () {
      return Boolean(this.get('isUsingNewMethod') && this.get('hasPaymentFormError'));
    }),

    isCardNameInvalid: Ember.computed('showError', 'validation.card_name.isInvalid', 'error.card_name', function () {
      return this.get('showError') && (this.get('validation.card_name.isInvalid') || this.get('error.card_name'));
    }).readOnly(),

    isCardNumberInvalid: Ember.computed('showError', 'validation.card_number.isInvalid', 'error.card_number', function () {
      return this.get('showError') && (this.get('validation.card_number.isInvalid') || this.get('error.card_number'));
    }).readOnly(),

    isCardCvvInvalid: Ember.computed('showError', 'validation.card_cvv.isInvalid', 'error.card_cvv', function () {
      return this.get('showError') && (this.get('validation.card_cvv.isInvalid') || this.get('error.card_cvv'));
    }).readOnly(),

    isBillingZipInvalid: Ember.computed('showError', 'validation.billing_zip.isInvalid', 'error.billing_zip', function () {
      return this.get('showError') && (this.get('validation.billing_zip.isInvalid') || this.get('error.billing_zip'));
    }).readOnly(),

    isCardExpMonthInvalid: Ember.computed('showError', 'validation.card_exp_month.isInvalid', 'error.card_exp_month', function () {
      return this.get('showError') && (this.get('validation.card_exp_month.isInvalid') || this.get('error.card_exp_month'));
    }).readOnly(),

    isCardExpYearInvalid: Ember.computed('showError', 'validation.card_exp_year.isInvalid', 'error.card_exp_year', function () {
      return this.get('showError') && (this.get('validation.card_exp_year.isInvalid') || this.get('error.card_exp_year'));
    }).readOnly(),

    actions: {
      save: function save() {
        var _this = this;

        this.setProperties({
          showError: true,
          error: {}
        });

        try {
          ga('send', 'event', { eventCategory: 'Contact ', eventAction: 'Submit', eventLabel: 'Confirm Your Booking', eventValue: 50 });
        } catch (e) {
          console.log(e);
        }

        if (this.get('hasFirstFormError') || this.get('hasSecondFormError') && !this.get('model.payment_method_id')) {
          var position = Ember.$(this.get('hasFirstFormError') ? '.booking-information-form' : '.booking-payment-form').offset().top;
          this.get('scrollTo').jQueryJumpTo(position, 300);
        }

        this.get('model').validate().then(function () {
          if (!_this.get('hasFirstFormError') && !(_this.get('hasSecondFormError') && !_this.get('model.payment_method_id')) && _this.get('isChecked') && _this.get('model.cancellation_policy')) {
            if (_this.get('isUsingNewMethod')) {
              _this.payment();
            } else {
              _this.sendAction('submitionAction');
            }
          }
        });
      },
      setMonth: function setMonth(value) {
        this.setProperties({
          'model.card_exp_month': value,
          'error.card_exp_month': false
        });
      },
      setYear: function setYear(value) {
        this.setProperties({
          'model.card_exp_year': value,
          'error.card_exp_year': false
        });
      },
      setPaymentMethod: function setPaymentMethod(value) {
        var result = value !== this.get('newPaymentMethodValue') ? value : null;

        this.set('model.payment_method_id', result);
      }
    },

    payment: function payment() {
      var _this2 = this;

      var self = this,
          stripe = this.get('stripe'),
          form = Ember.$("#" + this.get('paymentFormId'));
      this.set('model.saveProcess', true);

      return stripe.card.createToken(form).then(function (response) {
        self.set('model.payment_token', response.id);
        self.sendAction('submitionAction');
      }).catch(function (response) {
        _this2.set('model.saveProcess', false);
        var error = response.error;
        if (error) {
          switch (error.param) {
            case 'number':
              self.set('error.card_number', error.message);
              break;
            case 'name':
              self.set('error.card_name', error.message);
              break;
            case 'cvc':
              self.set('error.card_cvc', error.message);
              break;
            case 'exp_month':
              self.set('error.card_exp_month', error.message);
              break;
            case 'exp_year':
              self.set('error.card_exp_year', error.message);
              break;
            default:
              self.set('error.card_number', error.message);
              break;
          }
          self.get('scrollTo').jQueryJumpTo(Ember.$('.booking-payment-form').offset().top, 300);
        }
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setProperties({
        'model.password': '',
        'model.password_confirmation': ''
      });
      this.setAuthorizedUserData();
      //this.setDefaultPaymentMethod();
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly('model.validations.attrs'));
      this.get('account').notifyPropertyChange('paymentMethods');
    }
  });
});