define('handandstone-ember/pods/auth/forgot-password/model', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email', allowBlank: false })]
  });

  exports.default = _emberData.default.Model.extend(Validations, {
    email: _emberData.default.attr('string')
  });
});