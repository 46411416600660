define('handandstone-ember/pods/components/booking/appointment-time/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollTo: Ember.inject.service('scroll-to'),
    employeeTimeService: Ember.inject.service('employee-time'),

    tagName: '',
    timeIntervals: [],
    initialSlide: 0,
    breakpoints: [{
      breakpoint: 10000,
      settings: { slidesToShow: 8, slidesToScroll: 8 }
    }, {
      breakpoint: 1439,
      settings: { slidesToShow: 6, slidesToScroll: 6 }
    }, {
      breakpoint: 1024,
      settings: { slidesToShow: 5, slidesToScroll: 5 }
    }, {
      breakpoint: 767,
      settings: { slidesToShow: 2, slidesToScroll: 2 }
    }],

    isShowEmployee: function () {
      return this.get('employee.work') && this.get('amountEnabledIntervals');
    }.property('employee.work', 'amountEnabledIntervals'),

    timeIntervalsEnabled: function () {
      var intervals = [];

      this.get('timeIntervals').map(function (item) {
        if (item.availability == 'enabled') {
          intervals.push(item);
        }
      });

      return intervals;
    }.property('timeIntervals'),

    setActive: function setActive(time) {
      var intervals = this.get('timeIntervals');
      for (var i = 0; i < intervals.length; i++) {
        if (intervals[i].availability == 'enabled') {
          this.set('timeIntervals.' + i + '.class', intervals[i].value == time ? 'active' : '');
        }
      }
    },


    // getHotDealByTime(time){
    //   if(time in this.get('employee.hotDeals')){
    //     return this.get('employee.hotDeals')[time];
    //   }
    //   return false;
    // },

    checkIfCanSelect: function () {
      if (this.get('selectedEmployee')) {
        this.setActive(this.get('time'));
      } else {
        var isCanBeSelected = this.get('employeeTimeService').getIsCanBeSelected(this.get('employee'), this.get('employee_id'));

        if (isCanBeSelected) {
          this.setActive();
        } else {
          this.setActive(this.get('time'));
        }
      }
    }.on('didInsertElement').observes('employee_id'),

    actions: {
      slickInit: function slickInit(sl) {
        Ember.$(sl).slick('slickGoTo', this.get('initialSlide'), true);
      },
      select: function select(time, employee /*, index, hotDeal*/) {
        //if (this.get(`timeIntervals.${index}.availability`) == 'enabled') {
        this.setActive(time);
        this.sendAction('selectTimeAction', time);
        // this.sendAction('selectHotDealAction', hotDeal);
        if (!this.selectedEmployee && this.get('selectedEmployee.id') != employee.id) {
          this.sendAction('selectEmployeeAction', employee);
        }

        var position = Ember.$('.footer').offset().top - 100;

        this.get('scrollTo').jQueryJumpTo(position, 500, function () {
          Ember.$('#continue-focus').focus();
        });
        // }
      }
    }
  });
});