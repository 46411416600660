define('handandstone-ember/pods/application/adapter', ['exports', 'ember-data', 'handandstone-ember/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin'], function (exports, _emberData, _environment, _dataAdapterMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    'check-browser': Ember.inject.service(),

    authorizer: 'authorizer:token',
    host: _environment.default.APP.API_HOST,
    namespace: _environment.default.APP.API_HOST_NAMESPACE,

    headers: function () {
      var headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      };
      if (this.get('check-browser').version().isIE) {
        var date = new Date();
        headers = Object.assign(headers, {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          'Pragma': 'no-cache',
          'Expires': date,
          'If-Modified-Since': date
        });
      }

      return headers;
    }.property(),

    handleResponse: function handleResponse(status, headers, payload) {

      if (status >= 400) {
        var errors = [];
        switch (status) {
          case 422:
            for (var i = 0; i < payload.length; i++) {
              errors.push({
                detail: payload[i].message,
                source: {
                  pointer: "data/attributes/" + payload[i].field
                }
              });
            }
            break;
          case 400:
          case 404:
          case 500:
            errors.push({
              status: payload.status,
              code: payload.code,
              detail: payload.message,
              source: {
                pointer: "data/attributes/error"
              }
            });
            break;
          default:
            errors = payload.errors;
        }
        if (errors) {
          return new _emberData.default.InvalidError(errors);
        }
      }
      return this._super.apply(this, arguments);
    },

    headersForRequest: function headersForRequest() {
      var result = this._super.apply(this, arguments);
      var authorizer = this.get('authorizer');

      this.get('session').authorize(authorizer, function (headerName, headerValue) {

        var h = {};
        h[headerName] = headerValue;
        Object.assign(result, h);
      });

      return result;
    },
    query: function query(store, type, _query) {
      var url = this.buildURL(type.modelName, null, null, 'query', _query);
      if (this.sortQueryParams) {
        _query = this.sortQueryParams(_query);
        if (type.modelName.indexOf('account') !== -1) {
          _query['t'] = new Date().getTime();
        }
      }

      return this.ajax(url, 'GET', { data: _query });
    },
    findRecord: function findRecord(store, type, id, snapshot) {
      var url = this.buildURL(type.modelName, id, snapshot, 'findRecord');
      var query = this.buildQuery(snapshot);
      if (type.modelName.indexOf('account') !== -1 || type.modelName === 'homepage') {
        query['timestamp'] = new Date().getTime();
      }
      return this.ajax(url, 'GET', { data: query });
    }
  });
});