define('handandstone-ember/pods/locations/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _emberData.default.Model.extend({
    geoLocation: Ember.inject.service('geo-location'),
    maxQuantityOfLocationsOnMap: 300,
    centralOfficeId: 1,
    filterAddress: '',
    page: undefined,
    distance: undefined,
    address: undefined,
    longitude: undefined,
    latitude: undefined,
    search: {
      page: "",
      distance: 50,
      longitude: undefined,
      latitude: undefined,
      'per-page': 6,
      sort: 'distance'
    },
    meta: {},
    locationsMeta: {},
    isEmptySearch: false,

    //Locations for list tab
    locations: Ember.computed('search.{page,distance,latitude,longitude,per-page}', function () {
      var _this = this;

      if (!this.checkDisableFirstQuery()) {
        var result = this.get('store').query('location', this.get('search'));
        result.then(function (result) {

          _this.setProperties({
            meta: result.meta,
            isEmptySearch: !result.content.length,
            locationsMeta: result.meta
          });
        });
        return result;
      }
    }),

    //Locations for map tab
    mapLocations: Ember.computed('search.{distance,latitude,longitude}', function () {
      var _this2 = this;

      var search = Ember.copy(this.get('search'));
      Ember.set(search, 'per-page', this.get('maxQuantityOfLocationsOnMap'));
      Ember.set(search, 'page', 1);
      var result = this.get('store').query('location', search);
      result.then(function (result) {
        _this2.set('meta', result.meta);
      });
      return result;
    }),

    //All Locations for list tab
    allLocations: Ember.computed('isEmptySearch', function () {
      return this.get('store').query('location', { page: this.getRandomInt(0, 15) });
    }),

    getRandomInt: function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },


    //Central Office
    centralOffice: Ember.computed('centralOfficeId', function () {
      return this.get('store').findRecord('location', this.get('centralOfficeId'), { backgroundReload: false });
    }),

    //For search only after stopping slider and after some delay
    searchChange: function () {
      this.set('search.distance', this.get('distance'));
    }.observes('distance'),

    //Add the pagination query
    pagination: function () {
      this.set('search.page', this.get('page'));
    }.observes('page'),

    //Search by address
    searchAddress: function () {
      this.searchLatLng(this.get('address'));
    }.observes('address'),

    //Search by coordinates
    searchCoordinates: function () {
      this.setProperties({
        'search.latitude': this.get('latitude'),
        'search.longitude': this.get('longitude')
      });

      if (!this.get('address')) {
        this.searchAddressByCoordinates([this.get('latitude'), this.get('longitude')]);
      }
    }.observes('longitude'),

    //Latitude and Longitude by selected address
    searchLatLng: function searchLatLng(address) {
      var _this3 = this;

      this.set('filterAddress', this.get('address'));

      if (!address) {
        this.setGeoParams(true);
        return true;
      }

      this.get('geoLocation').searchByAddress(address).then(function (data) {
        if (data.lat && data.lng) {
          _this3.setProperties({
            latitude: data.lat,
            longitude: data.lng
          });
        } else {
          _this3.setGeoParams();
        }
      }, function () {
        _this3.setGeoParams();
      });
    },
    setGeoParams: function setGeoParams(isUndefined) {
      this.setProperties({
        latitude: isUndefined ? undefined : false,
        longitude: isUndefined ? undefined : false
      });
    },


    //Search address by selected  Latitude and Longitude
    searchAddressByCoordinates: function searchAddressByCoordinates(_ref) {
      var _this4 = this;

      var _ref2 = _slicedToArray(_ref, 2),
          latitude = _ref2[0],
          longitude = _ref2[1];

      this.get('geoLocation').searchByCoordinates(latitude, longitude).then(function (data) {
        _this4.set('filterAddress', data.long_name);
      }).catch(function () {
        _this4.set('filterAddress', undefined);
        return false;
      });
    },
    checkDisableFirstQuery: function checkDisableFirstQuery() {
      return (Boolean(this.get('latitude')) || Boolean(this.get('address'))) && this.get('search.latitude') == undefined;
    },
    addComma: function addComma(string) {
      var coma = '';
      if (string.length > 0) {
        coma = ',';
      }
      return coma;
    }
  });
});