define('handandstone-ember/pods/auth/sign-in/route', ['exports', 'handandstone-ember/mixins/unauthenticated-mixin'], function (exports, _unauthenticatedMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedMixin.default, {
    account: Ember.inject.service(),

    model: function model() {
      return this.get('account.model');
    }
  });
});