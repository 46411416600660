define('handandstone-ember/pods/service-duration/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location_id: _emberData.default.attr('number'),
    service_id: _emberData.default.attr('number'),
    service_code: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    is_taxable: _emberData.default.attr('boolean'),
    is_override_tax: _emberData.default.attr('boolean'),
    override_tax: _emberData.default.attr('number')
  });
});