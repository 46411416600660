define('handandstone-ember/pods/components/service/slider-image/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    minCount: 4,
    flag: true,
    responsive: [{
      breakpoint: 10000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }, {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }],
    changeImages: function () {
      var _this = this;
      _this.set('flag', false);
      setTimeout(function () {
        _this.set('flag', true);
      }, 1);
    }.observes('images'),
    isEnoughCount: function isEnoughCount() {
      return this.get('images').length >= this.minCount;
    },
    show: function () {
      return this.get('flag') && this.isEnoughCount();
    }.property()
  });
});