define("handandstone-ember/pods/components/account/intro-booking/intro-booking-list/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    account: service(),
    location: service(),
    store: service(),
    helper: service(),

    classNames: ["services-slider", "dashboard-intro-booking"],
    introductory: [],
    isError: false,
    isLoading: false,
    isLocationSelected: function () {
      return this.get("location.locationId") != undefined;
    }.property("location.locationId"),

    isShowDisabledInfo: function () {
      var counter = 0,
          introductory = this.get("introductory"),
          introductoryLength = introductory.length;

      if (introductoryLength) {

        introductory.forEach(function (item) {
          if (item.isDisabled) {
            counter++;
          }
        });
      }

      return introductoryLength && introductoryLength == counter;
    }.property("introductory"),

    setIntroductory: function setIntroductory() {
      var _this = this;

      var result = [];

      this.get('locationModel.introductory').then(function (respIntroductory) {

        var usedIntroServices = _this.get("accountModel.used_intro_services");

        if (respIntroductory.length) {
          respIntroductory.forEach(function (item) {
            result.pushObject({
              service: item
            });
          });

          if (usedIntroServices.length) {
            result.forEach(function (item) {
              for (var i = 0; i < usedIntroServices.length; i++) {
                if (item.service.get('global_id') == usedIntroServices[i].service_id) {
                  item.isDisabled = true;
                  break;
                }
              }
            });
          }
        }

        _this.setProperties({
          introductory: result,
          isLoading: false
        });
      });
    },
    setModel: function setModel() {
      var _this2 = this;

      if (this.get("isLocationSelected")) {
        var store = this.get('store'),
            locationId = this.get('location.locationId');

        this.get('account.model').then(function (accountModel) {
          _this2.set("accountModel", accountModel);

          if (store.hasRecordForId('location', locationId)) {
            if (!_this2.isDestroyed) {
              _this2.set('locationModel', store.peekRecord('location', locationId));
              _this2.setIntroductory();
            }
          } else {

            _this2.set("isLoading", true);

            store.findRecord('location', locationId).then(function (respLocation) {
              if (!_this2.isDestroyed) {
                _this2.set('locationModel', respLocation);
                _this2.setIntroductory();
              }
            }, function () {
              if (!_this2.isDestroyed) {

                _this2.setProperties({
                  isError: true,
                  isLoading: false
                });
              }
            });
          }
        });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.setModel();
    },


    changeLocation: function () {
      this.setModel();
    }.observes('location.locationId'),

    actions: {
      selectLocation: function selectLocation() {
        this.sendAction('modalAction', 'locations/locations-popup');
      }
    }
  });
});