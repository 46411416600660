define('handandstone-ember/pods/account/payment/route', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'handandstone-ember/mixins/authenticate-popup-mixin'], function (exports, _authenticatedRouteMixin, _authenticatePopupMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _authenticatePopupMixin.default, {
    account: Ember.inject.service(),

    model: function model() {
      return this.get('account.paymentMethods');
    },


    actions: {
      resetModel: function resetModel() {
        this.refresh();
      }
    }
  });
});