define('handandstone-ember/pods/components/special-links/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var TITLE_LEARN_MORE = 'learn more';
  var TITLE_BUY_NOW = 'buy now';

  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),

    classNames: 'special-links',
    classNameBindings: ['isBlueMode:blue'],
    isBlueMode: false,

    model: function () {
      return this.get('store').findRecord('homepage', 'homepage', { backgroundReload: false });
    }.property(),

    actions: {
      openHtmlLink: function openHtmlLink(title) {
        //Amazon Advertising Tag
        if (title) {
          if (this.get('location.currentRoute') === 'class.neveskin') {
            if (title.toLowerCase() === TITLE_LEARN_MORE) {
              amzn("trackEvent", "Lead_IHM 317 Hand and Stone Cryo Learn More");
            } else if (title.toLowerCase() === TITLE_BUY_NOW) {
              amzn("trackEvent", "Lead_IHM 317 Hand and Stone Cryo Buy Now");
            }
          } else if (this.get('location.currentRoute') === 'class.facial') {
            if (title.toLowerCase() === TITLE_LEARN_MORE) {
              amzn("trackEvent", "Lead_IHM 317 Hand and Stone Facial Learn More");
            } else if (title.toLowerCase() === TITLE_BUY_NOW) {
              amzn("trackEvent", "Lead_IHM 317 Hand and Stone Facial Buy Now");
            }
          }
        }

        this._super.apply(this, arguments);
      }
    }
  });
});