define('handandstone-ember/services/account', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Service.extend({
    store: service(),
    session: service(),

    homeLocation: null,

    model: function () {
      if (this.get('session.isAuthenticated')) {
        return this.get('store').findRecord('account/information', 'my', { backgroundReload: false });
      }
      return new Ember.RSVP.Promise(function (resolve) {
        resolve(null);
      });
    }.property('session.isAuthenticated'),

    load: function load() {
      if (this.get('session.isAuthenticated')) {
        return this.get('model');
      } else {
        return new Ember.RSVP.Promise(function (resolve) {
          resolve(null);
        });
      }
    },


    paymentMethods: function () {
      if (this.get('session.isAuthenticated')) {
        return this.get('store').query('account/payment', {});
      }
    }.property('session.isAuthenticated'),

    defaultPaymentModel: function () {
      var resultModel = null;
      if (this.get('paymentMethods')) {
        this.get('paymentMethods').forEach(function (model) {
          if (model.get('is_default') === true) {
            resultModel = model;
          }
        });
      }

      return resultModel;
    }.property('paymentMethods.@each.id'),

    setHomeLocation: function () {
      var _this = this;

      if (this.get('model.home_location')) {
        return this.get('store').findRecord('location', this.get('model.home_location'), { backgroundReload: false }).then(function (data) {
          _this.set('homeLocation', data);
          return data;
        }).catch(function () {
          _this.set('homeLocation', null);
        });
      } else {
        this.set('homeLocation', null);
      }
    }.observes('model.home_location')
  });
});