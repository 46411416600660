define('handandstone-ember/pods/components/special-offer/coupon-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    type: 'USAT',
    classNames: ['wrap-coupon-form'],
    showError: false,
    isShowPromo: false,
    isLoading: false,

    init: function init() {
      this._super.apply(this, arguments);

      var model = this.get('store').createRecord('special-offer', {
        type: this.get('type')
      });

      //variable inversion due to complex text - "Don’t send me e-mail specials and other Exclusive Offers"
      this.setProperties({
        model: model,
        sendEmailReverse: !model.get("send_email")
      });

      this.addObserver('sendEmailReverse', this, 'changeSendEmail');
    },
    changeSendEmail: function changeSendEmail() {
      this.set('model.send_email', !this.get("sendEmailReverse"));
    },
    willDestroyElement: function willDestroyElement() {
      this.removeObserver("sendEmailReverse", this, "changeSendEmail");
    },


    actions: {
      sendModel: function sendModel() {
        var _this = this;

        var curModel = this.get('model');

        this.set('showError', true);

        curModel.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {

            if (!curModel.get('isSaving')) {

              _this.set("isLoading", true);

              curModel.save().then(function (resp) {
                if (!_this.isDestroyed) {
                  _this.setProperties({
                    isShowPromo: true,
                    isLoading: false,
                    model: resp
                  });
                }
              }, function () {
                if (!_this.isDestroyed) {
                  _this.set("isLoading", false);
                }
              });
            }
          }
        });
      }
    }
  });
});