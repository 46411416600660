define('handandstone-ember/pods/account/appointment/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    order_id: _emberData.default.attr('number'),
    date: _emberData.default.attr('string'),
    start_time: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    status_title: _emberData.default.attr('string'),
    duration: _emberData.default.attr('string'),
    order_date: _emberData.default.attr('string'),
    employee_full_name: _emberData.default.attr('string'),
    employee_id: _emberData.default.attr('number'),
    service_name: _emberData.default.attr('string'),
    service_price: _emberData.default.attr('string'),
    service_id: _emberData.default.attr('number'),
    service_code: _emberData.default.attr('string'),
    service_type: _emberData.default.attr('string'),
    tax: _emberData.default.attr('string'),
    add_ons: _emberData.default.attr('array'),
    location: _emberData.default.attr(),
    is_hot_deal: _emberData.default.attr('boolean'),
    is_intro: _emberData.default.attr('boolean', { defaultValue: false }),
    allow_cancellation: _emberData.default.attr('boolean')
  });
});