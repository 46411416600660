define('handandstone-ember/pods/components/header-main/sub-menu/external-link-controller/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    actions: {
      goToUrl: function goToUrl() {
        this.sendAction('goToUrl', this.get('locationType.external_spa_deals_link'), true); //params = url, isExternalLink
      }
    }
  });
});