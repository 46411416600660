define('handandstone-ember/pods/location/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    "text-fields": Ember.inject.service(),
    isZenotiTabActive: false,
    isShowZenotiTab: false,
    zenotiTabIdName: 'spa-menu',

    hasLMD: Ember.computed('model.{isShowingLMD,external_booking}', function () {
      return this.get('model.isShowingLMD') && !this.get('model.external_booking');
    }),

    actions: {
      changeTab: function changeTab(isZenotiTab) {
        this.set('isZenotiTabActive', isZenotiTab);
      }
    }
  });
});