define('handandstone-ember/pods/components/auth/sign-up/registration-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service('-routing'),
    location: Ember.inject.service(),
    modal: Ember.inject.service(),

    tagName: '',
    showError: false,
    isChecked: false,

    locationName: function () {
      return this.get('location.currentLocation.name') || null;
    }.property('location.currentLocation.name'),

    locationIdObserves: function () {
      var id = this.get('location.currentLocation.id') || null;

      this.set('model.location_id', id);
    }.observes('location.currentLocation.id'),

    isInvalidCheckbox: Ember.computed('showError', 'isChecked', function () {
      return Boolean(!this.get('isChecked') && this.get('showError'));
    }),

    errorClass: Ember.computed('isInvalidCheckbox', function () {
      return this.get('isInvalidCheckbox') ? 'has-error' : null;
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('locationIdObserves');
    },


    actions: {
      registration: function registration() {
        var _this = this;

        var curModel = this.get('model');

        this.set('showError', true);

        curModel.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid') && _this.get('isChecked')) {

            if (!curModel.get('isSaving')) {
              curModel.save().then(function () {
                _this.get('modal').open('small-popup', {
                  model: {
                    success: true,
                    title: 'Confirm email',
                    message: 'The confirmation email is sent to your email. Please confirm your email and then you will be able to enter your account.'
                  }
                });
                _this.get('router').transitionTo('auth/sign-in');
              }, function () {//not delete, removes an error in the browser console

              });
            }
          }
        });
      },
      locationsPopUp: function locationsPopUp() {
        this.sendAction('modalAction', 'locations/locations-popup');
      }
    }
  });
});