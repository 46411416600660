define('handandstone-ember/pods/zenoti-classification/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    zenoti_services: _emberData.default.attr(),
    images: _emberData.default.attr(),

    isSingleImage: Ember.computed('images', function () {
      return this.get('images').length < 2;
    })
  });
});