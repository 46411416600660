define('handandstone-ember/pods/homepage/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    book: _emberData.default.attr(),
    holidays_mode: _emberData.default.attr(),
    main_image: _emberData.default.attr(),
    main_image_link: _emberData.default.attr(),
    special_links: _emberData.default.attr(),
    first_time: _emberData.default.attr(),
    appointment: _emberData.default.attr(),
    intro_section: _emberData.default.attr(),
    sub_block: _emberData.default.attr()
  });
});