define('handandstone-ember/helpers/coordinates-distance', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.coordinatesDistance = coordinatesDistance;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    function coordinatesDistance(_ref) {
        var _ref2 = _slicedToArray(_ref, 5),
            lat1 = _ref2[0],
            lon1 = _ref2[1],
            lat2 = _ref2[2],
            lon2 = _ref2[3],
            _ref2$ = _ref2[4],
            unit = _ref2$ === undefined ? 'M' : _ref2$;

        if (lat1 && lon1 && lat2 && lon2) {
            var distance = getCoordinatesDistance(lat1, lon1, lat2, lon2, unit);
            if (distance || distance === 0) {
                return distance.toFixed(1);
            }
        }
        return false;
    } /* globals getCoordinatesDistance */
    exports.default = Ember.Helper.helper(coordinatesDistance);
});