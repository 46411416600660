define('handandstone-ember/pods/components/account/payment/list-items/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'card-holder',
    classNameBindings: ['isChangingMethods:loading'],

    isChangingMethods: false,

    defaultModel: Ember.computed('models', function () {
      var resultModel = {};

      this.get('models').forEach(function (model) {
        if (model.get('is_default') === true) {
          resultModel = model;
        }
      });

      return resultModel;
    }),

    notDefaultPaymentMethods: Ember.computed('models.@each.id', function () {
      var result = [];

      this.get('models').forEach(function (model) {
        if (model.get('is_default') !== true) {
          result.push(model);
        }
      });
      return result;
    }),

    actions: {
      resetModel: function resetModel() {
        this.sendAction('resetModel');
      }
    }
  });
});