define('handandstone-ember/pods/components/modal-login/component', ['exports', 'ember-cp-validations', 'handandstone-ember/mixins/modal-component-mixin', 'ember-modal-service/components/modal'], function (exports, _emberCpValidations, _modalComponentMixin, _modal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var Validations = (0, _emberCpValidations.buildValidations)({
    identification: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    password: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', { min: 8, max: 16 })]
  });
  exports.default = _modal.default.extend(Validations, _modalComponentMixin.default, {
    modal: Ember.inject.service(),
    session: Ember.inject.service(),

    validation: null,
    showError: false,
    serverErrors: {
      email: [],
      password: []
    },
    requestSending: false,

    isEmailInvalid: Ember.computed('showError', 'validation.identification.isInvalid', 'serverErrors.email', function () {
      return this.get('showError') && (this.get('validation.identification.isInvalid') || this.get('serverErrors.email'));
    }).readOnly(),

    isPasswordInvalid: Ember.computed('showError', 'validation.password.isInvalid', 'serverErrors.password', function () {
      return this.get('showError') && (this.get('validation.password.isInvalid') || this.get('serverErrors.password'));
    }).readOnly(),

    passwordChange: function () {
      this.set('serverErrors.password', false);
    }.observes('password'),

    identificationChange: function () {
      this.set('serverErrors.identification', false);
    }.observes('identification'),

    init: function init() {
      this._super.apply(this, arguments);
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly('validations.attrs'));
    },


    actions: {
      forgotPassPopup: function forgotPassPopup() {
        this.get('modal').close();
        this.get('modal').open('forgot-pass');
      },
      signIn: function signIn() {
        var _this = this;

        this.setProperties({
          showError: true,
          serverErrors: {}
        });

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            var credentials = _this.getProperties('identification', 'password'),
                self = _this;
            if (!_this.get('requestSending')) {
              _this.set('requestSending', true);
              _this.get('session').authenticate('authenticator:token', credentials).then(function () {
                _this.setProperties({
                  'session.store.cookieExpirationTime': 60 * 60 * 24 * 14,
                  showError: false
                });
                self.get('modal').close();
                _this.set('requestSending', false);
              }, function (reason) {
                if (Array.isArray(reason)) {
                  reason.forEach(function (item) {
                    if (item.field === 'password') {
                      _this.set('serverErrors.password', [item]);
                    } else if (item.field === 'email') {
                      _this.set('serverErrors.email', [item]);
                    } else {
                      _this.setProperties({
                        'serverErrors.password': [item],
                        'serverErrors.email': [item]
                      });
                    }
                  });
                } else {
                  _this.set('serverErrors.email', [{ field: 'email', message: 'Authorization Error!' }]);
                }
                _this.set('requestSending', false);
              });
            }
          }
        });
      }
    }
  });
});