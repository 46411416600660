define('handandstone-ember/pods/auth/forgot-password/route', ['exports', 'handandstone-ember/mixins/unauthenticated-mixin'], function (exports, _unauthenticatedMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_unauthenticatedMixin.default, {
    modal: Ember.inject.service(),

    model: function model() {
      return this.get('store').createRecord('auth/forgot-password');
    },


    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('showError', false);
    },

    actions: {
      forgot: function forgot() {
        var _this = this;

        var curController = this.controllerFor(this.get('routeName')),
            curModel = curController.get('model');

        curController.set('showError', true);

        curModel.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {

            if (!curModel.get('isSaving')) {
              curModel.save().then(function () {
                _this.get('store').unloadRecord(curModel);
                _this.transitionTo('auth/sign-in');
                _this.get('modal').open('small-popup', {
                  model: {
                    success: true,
                    title: 'Success!',
                    message: 'Email was sent. Please check your email.'
                  }
                });
              }, function () {//not delete, removes an error in the browser console

              });
            }
          }
        });
      }
    }
  });
});