define('handandstone-ember/pods/components/header-main/sub-menu-item/component', ['exports', 'handandstone-ember/utils/classification'], function (exports, _classification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ItemTypeServiceResult = 'services_result';
  var ItemTypeService = 'service';

  exports.default = Ember.Component.extend({
    navmenu: Ember.inject.service(),
    tagName: 'li',
    classNameBindings: ['isHasSubItems:has-drop', 'isActive:active', 'isActiveParent:active', 'isSubItemsOpen:open', 'isSubItemsOpenOnHover:open-hover'],
    isActive: false,
    isActiveParent: false,

    isSubItemsOpen: function () {
      return this.elementId === this.get('expandedItemId');
    }.property('expandedItemId'),

    getAriaTitle: function () {
      var title = this.get('title');
      if (this.get('ariaTitle') == false) {
        return null;
      }
      if (title === "undefined" || title === undefined) {
        title = Math.random().toString(36).substring(7);
      }
      return title + '-subitems';
    }.property('title'),

    isSubItemsOpenOnHover: function () {
      var isEqualID = this.elementId === this.get('expandedItemIdOnHover'),
          isDeviceHasTouch = this.get('navmenu').getIsDeviceHasTouch();

      return isEqualID && !isDeviceHasTouch;
    }.property('expandedItemIdOnHover'),

    isHasSubItems: function () {
      var subItems = this.get('subItems');

      return Boolean(subItems && subItems.length);
    }.property('subItems'),

    subItemsChanged: function () {
      var _this = this;

      var subItems = this.get('subItems'),
          result = [];

      subItems.forEach(function (item, index) {
        var route = '';
        var slug = item.slug;

        if (item.item_type === ItemTypeServiceResult) {
          route = 'result-classification';
          slug = _classification.default.getClassNameByAbbr(item.slug);
        } else {
          route = _this.get('route') + ".item";
        }

        var res = {
          index: index,
          isActive: false,
          route: route,
          slug: slug,
          title: item.title,
          icon: item.icon
        };

        result.push(res);
      });

      return result;
    }.property('subItems'),

    observerActiveParent: Ember.observer('subItemsChanged.@each.isActive', function () {
      var result = false,
          subItems = this.get('subItemsChanged');

      for (var i = 0; i < subItems.length; i++) {
        var isActive = subItems[i].isActive;

        if (isActive) {
          result = isActive;
          break;
        }
      }

      this.set('isActiveParent', result);
    }),

    mouseEnter: function mouseEnter() {
      if (this.get('isHasSubItems')) {
        this.set('expandedItemIdOnHover', this.elementId);
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.get('isHasSubItems')) {
        this.set('expandedItemIdOnHover', null);
      }
    },
    keyUp: function keyUp(e) {
      if (this.get('isHasSubItems') && e.keyCode == 27) {
        this.set('expandedItemId', null);
        this.$('.open-subitems').focus();
      }
    },


    actions: {
      changeActive: function changeActive(isActive) {
        this.set('isActive', isActive);

        var item = this.get('item');

        if (item) {
          this.set('item.isActive', isActive);
        }
      },
      showSubItems: function showSubItems() {
        var expandedItemId = this.get('isSubItemsOpen') ? null : this.elementId;

        this.set('expandedItemId', expandedItemId);
      }
    }
  });
});