define('handandstone-ember/pods/class/neveskin/item/controller', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isNewService: function () {
      return _environment.default.APP.NEW_SERVICE_VIEW;
    }.property()
  });
});