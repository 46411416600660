define('handandstone-ember/pods/components/account/appointments/list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    helper: Ember.inject.service(),

    tagName: "tr",
    date_time: Ember.computed('model.date', 'model.start_time', function () {
      return moment(this.get('model.date') + ' ' + this.get('model.start_time'), 'YYYY-MM-DD HH:mm').format("MMMM D, YYYY h:mm A");
    })
  });
});