define('handandstone-ember/pods/components/footer-main/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service('location'),
    'dynamic-menu': Ember.inject.service('dynamic-menu'),
    store: Ember.inject.service(),
    tagName: 'footer',
    classNames: ['footer'],
    classNameBindings: ['footerClass'],
    year: new Date().getFullYear(),

    footerClass: Ember.computed('location.currentRoute', function () {
      if (this.get('location').isBookingRoute()) {
        this.set('isMain', false);
        return '';
      } else {
        this.set('isMain', true);
        return 'footer-main';
      }
    }),

    firstMenu: function () {
      return this.get('dynamic-menu.footerFirstMenu.menu');
    }.property('dynamic-menu.footerFirstMenu.menu'),

    secondMenu: function () {
      return this.get('dynamic-menu.footerSecondMenu.menu');
    }.property('dynamic-menu.footerSecondMenu.menu'),

    thirdMenu: function () {
      return this.get('dynamic-menu.footerThirdMenu.menu');
    }.property('dynamic-menu.footerThirdMenu.menu')
  });
});