define('handandstone-ember/pods/components/booking/terapist-preferences/reorder-popup-controller/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),

    tagName: '',

    didInsertElement: function didInsertElement() {
      if (!this.get('model.isReorderIntro') && this.get('model.isReorder') && this.get('model.employee_id') == null) {

        this.get('model').set('isReorder', false);
        this.sendAction('setAnyEmployees');

        this.get('modal').open('small-popup', {
          model: {
            success: true,
            title: 'Attention!',
            message: 'Your therapist is no longer available, please select from different therapists below'
          }
        });
      }
    }
  });
});