define('handandstone-ember/pods/components/locations/list-map/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultZoom = 11; /* globals L */
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),
    'location-leaflat': Ember.inject.service(),
    activatedPinContext: null,
    iconHeight: 45,
    iconWeight: 55,
    iconActiveHeight: 74,
    iconActiveWeight: 61,
    iconUrl: '/slice/dist/images/svg/marker-map.svg',
    defaultIcon: '/slice/dist/images/svg/marker-map.svg',
    activeIcon: '/slice/dist/images/svg/marker-map-active.svg',
    iconSingleHeight: 25,
    iconSingleWeight: 41,
    iconSingleShadowHeight: 41,
    iconSingleShadowWeight: 41,
    iconSingleUrl: '/slice/dist/images/marker-icon.png',
    iconSingleRetinaUrl: '/slice/dist/images/marker-icon-2x.png',
    iconSingleShadowUrl: '/slice/dist/images/marker-shadow.png',

    lat: Ember.computed('model.search.latitude', function () {
      return this.get('model.search.latitude');
    }),

    lng: Ember.computed('model.search.longitude', function () {
      return this.get('model.search.longitude');
    }),

    setDefaultCoordinates: function () {
      this.set('lat', this.get('model.search.latitude'));
      this.set('lng', this.get('model.search.longitude'));
    }.observes('model.search.distance', 'model.search.latitude', 'model.search.longitude'),

    zoom: Ember.computed('model.search.distance', function () {
      return Math.floor(defaultZoom - Math.sqrt(parseInt(this.get('model.search.distance')) / 8));
    }),
    setRealCenter: function setRealCenter(latitude, longitude) {
      if (this.get('lat') != this.get('latitude')) {
        this.set('lat', latitude);
      }
      if (this.get('lng') != this.get('longitude')) {
        this.set('lng', longitude);
      }
    },

    checkActionClosePopup: function () {
      if (!this.get('location-leaflat.isPopupOpened') && this.get('activatedPinContext')) {
        this.get('activatedPinContext').target.setIcon(L.icon({
          iconUrl: this.get('defaultIcon'),
          iconRetinaUrl: this.get('defaultIcon'),
          iconSize: [this.get('iconHeight'), this.get('iconWeight')]
        }));
        this.get('activatedPinContext').target._icon.removeAttribute('tabindex');
      }
    }.observes('location-leaflat.isPopupOpened'),

    actions: {
      openPopup: function openPopup(location, e) {
        this.get('modal').open('leaflat-popup', { model: { location: location, search: this.get('model.search') } });
        this.setProperties({ 'location-leaflat.isPopupOpened': true, activatedPinContext: e });

        e.target.setIcon(L.icon({
          iconUrl: this.get('activeIcon'),
          iconRetinaUrl: this.get('activeIcon'),
          iconSize: [this.get('iconActiveHeight'), this.get('iconActiveWeight')]
        }));
        e.target._icon.removeAttribute('tabindex');
      },
      addMarker: function addMarker(e) {
        e.target._icon.setAttribute('alt', "");
        e.target._icon.removeAttribute('tabindex');
      },
      updateCenter: function updateCenter(e) {
        this.setRealCenter(e.target.getCenter().lat, e.target.getCenter().lng);
      },
      updateZoom: function updateZoom(e) {
        this.setRealCenter(e.target.getCenter().lat, e.target.getCenter().lng);
      }
    }
  });
});