define('handandstone-ember/pods/components/hot-deals/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    location: Ember.inject.service(),
    session: Ember.inject.service(),

    spaDealsPage: false,
    startTime: '9:00 AM',
    endTime: '9:00 PM',
    minSliderTime: 0,
    maxSliderTime: 1410,
    classification: 'MA',
    'per-page': 20,
    page: 1,
    isSliderInit: true,

    classifications: [{
      title: 'Massage',
      abbr: 'MA',
      checked: true
    }, {
      title: 'Facial',
      abbr: 'FI',
      checked: true
    }],

    search: {
      start_time: '9:00',
      end_time: '21:00',
      dates: [],
      classification: ['MA', 'FI'],
      page: 1,
      'per-page': 20,
      'location_id': null,
      searchCounter: 0
    },
    meta: {},

    startTimeCropped: function () {
      return this.get('startTime').replace(/:00/g, '');
    }.property('startTime'),

    endTimeCropped: function () {
      return this.get('endTime').replace(/:00/g, '');
    }.property('endTime'),

    init: function init() {
      this._super.apply(this, arguments);

      var days = this.getDays();

      this.setProperties({
        'search.per-page': this.get('per-page'),
        'search.location_id': this.get('location.currentLocation.id'),
        days: days
      });

      this.setSearchDates(days);
    },


    currentLocation: Ember.computed('location.currentLocation', function () {
      return this.get('location.currentLocation');
    }),

    computedClass: Ember.computed('spaDealsPage,isShowIntroOnSpaDealPage', function () {
      if (this.get('spaDealsPage')) {
        return 'spa-deals-page';
      } else {
        return 'last-deals';
      }
    }),

    isLoading: Ember.computed('location.locationId,location.currentLocation.id', function () {
      if (this.get('spaDealsPage')) {
        return this.get('deals.isPending') && !this.get('location.currentLocation.id');
      } else {
        return Boolean((this.get('location.locationId') || this.get('location.singleLocationId')) && !this.get('location.currentLocation.id'));
      }
    }),

    checkboxDates: function () {
      this.setSearchDates(this.get('days'));

      this.resetPage();
    }.observes('days.@each.checked'),

    getDays: function getDays() {
      var format = 'DD-MM-YYYY',
          formatTitle = "MMM DD";

      var days = [{
        title: "Today",
        date: moment().format(format),
        checked: true

      }],
          extraDatesCount = 3;

      for (var i = 1; i <= extraDatesCount; i++) {
        days.push({
          title: moment().add(i, 'days').format(formatTitle),
          date: moment().add(i, 'days').format(format),
          checked: true
        });
      }

      return days;
    },
    setSearchDates: function setSearchDates(days) {
      var result = [];

      days.forEach(function (item) {
        if (item.checked) {
          result.push(item.date);
        }
      });

      this.set('search.dates', result);
    },


    checkboxClassifications: function () {
      var result = [];
      this.get('classifications').forEach(function (element) {
        if (element.checked === true) {
          result.push(element.abbr);
        }
      });
      this.set('search.classification', result);
      this.resetPage();
    }.observes('classifications.@each.checked'),

    pageChanged: function () {
      this.set('search.page', this.get('page'));
    }.observes('page'),

    resetPage: function resetPage() {
      this.setProperties({
        'search.page': 1,
        page: 1
      });
    },

    setStartTime: function () {
      var _this = this;

      var prevValue = this.get('startTime');
      //for search only after stopping slider and after some delay
      setTimeout(function () {
        if (prevValue === _this.get('startTime')) {
          _this.set('search.start_time', moment(_this.get('startTime'), "h:mm A").format('HH:mm'));
        }
      }, 1000);
      this.resetPage();
    }.observes('startTime'),

    setEndTime: function () {
      var _this2 = this;

      var prevValue = this.get('endTime');
      //for search only after stopping slider and after some delay
      setTimeout(function () {
        if (prevValue === _this2.get('endTime')) {
          _this2.set('search.end_time', moment(_this2.get('endTime'), "h:mm A").format('HH:mm'));
        }
      }, 1000);
      this.resetPage();
    }.observes('endTime'),

    setLocationId: function () {
      this.setProperties({
        'search.location_id': this.get('currentLocation.id'),
        isSliderInit: true
      });
    }.observes('currentLocation.id'),

    deals: function () {
      var _this3 = this;

      if (this.validateSearchParams()) {
        var search = this.get('search');
        search['t'] = new Date().getTime();

        var result = this.get('store').query('hot_deal', search);

        result.then(function (value) {
          if (!_this3.isDestroyed) {
            _this3.setMeta(value ? value.meta : {});
          }
        });

        return result;
      } else {
        return false;
      }
    }.property('search.{searchCounter,page,per-page,location_id}'),

    validateSearchParams: function validateSearchParams() {
      return this.get('search.start_time') && this.get('search.end_time') && this.get('search.dates') && this.get('search.classification') && this.get('search.location_id');
    },
    setMeta: function setMeta(meta) {
      var result = false;
      if (!this.isDestroyed) {
        this.set('meta', meta);
        result = true;
      }
      return result;
    },
    setSliderADAattrs: function setSliderADAattrs() {
      var props = this.getProperties('minSliderTime', 'maxSliderTime', 'startTime', 'endTime', 'sliderHandleStart', 'sliderHandleEnd');

      props.sliderHandleStart.attr({
        role: 'slider',
        'aria-valuemin': this.timeConversion(props.minSliderTime),
        'aria-valuemax': props.endTime,
        'aria-label': 'Appointment time from range',
        'aria-valuenow': props.startTime
      });

      props.sliderHandleEnd.attr({
        role: 'slider',
        'aria-valuemin': props.startTime,
        'aria-valuemax': this.timeConversion(props.maxSliderTime),
        'aria-label': 'Appointment time to range',
        'aria-valuenow': props.endTime
      });
    },
    timeConversion: function timeConversion(val) {
      var hours = Math.floor(val / 60),
          minutes = val - hours * 60;

      return moment(hours + ":" + minutes, "H:m").format('h:mm A');
    },
    initialDateSlide: function initialDateSlide() {
      var _this4 = this;

      var slider = Ember.$(".slider-time");

      slider.slider({
        range: true,
        min: this.get('minSliderTime'),
        max: this.get('maxSliderTime'),
        step: 30,
        values: [540, 1260], //9-21

        slide: function slide(e, ui) {
          _this4.setProperties({
            startTime: _this4.timeConversion(ui.values[0]),
            endTime: _this4.timeConversion(ui.values[1])
          });

          _this4.setSliderADAattrs();
        },

        create: function create() {
          var sliderHandle = slider.slider("widget").find('.ui-slider-handle');

          _this4.setProperties({
            isSliderInit: false,
            sliderHandleStart: sliderHandle.eq(0),
            sliderHandleEnd: sliderHandle.eq(1)
          });

          _this4.setSliderADAattrs();
        }
      });
    },


    homePageModel: function () {
      var store = this.get('store');
      if (store.hasRecordForId('homepage', 'homepage')) {
        return store.peekRecord('homepage', 'homepage');
      }
      return store.findRecord('homepage', 'homepage');
    }.property(),

    isShowIntroOnSpaDealPage: function () {
      return Boolean(this.get('spaDealsPage') && !this.get('location.currentLocation.isShowingLMD') && !this.get('session.isAuthenticated'));
    }.property('spaDealsPage', 'location.currentLocation.isShowingLMD', 'session.isAuthenticated'),

    isNoIntroAndHotDeals: function () {
      return Boolean(this.get('spaDealsPage') && !this.get('location.currentLocation.isShowingLMD') && this.get('session.isAuthenticated'));
    }.property('spaDealsPage', 'location.currentLocation.isShowingLMD', 'session.isAuthenticated'),

    didRender: function didRender() {
      if (this.get('isSliderInit')) {
        this.initialDateSlide();
      }
    },


    actions: {
      modalAction: function modalAction(value) {
        this.sendAction('modalAction', value);
      },
      locationsPopUp: function locationsPopUp() {
        this.sendAction('modalAction', 'locations/locations-popup');
      },
      searchDeals: function searchDeals() {
        this.resetPage();
        this.incrementProperty('search.searchCounter');
      }
    }
  });
});