define('handandstone-ember/pods/location/adapter', ['exports', 'handandstone-ember/pods/application/adapter', 'ember-fetch/ajax'], function (exports, _adapter, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    queryRecord: function queryRecord(modelName, query, params) {
      return (0, _ajax.default)(this.get('host') + '/' + this.get('namespace') + "/locations/slug/" + params.slug);
    }
  });
});