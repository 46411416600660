define('handandstone-ember/pods/components/modal-cookie-policy/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    cookies: Ember.inject.service(),
    focus: Ember.inject.service(),
    "text-fields": Ember.inject.service(),

    tagName: '',
    key: 'isAllowedCookies',
    labelledby: 'modal-cookie-policy-label',

    init: function init() {
      this._super.apply(this, arguments);

      var isShow = !this.getIsAllowed();

      this.setProperties({
        isShow: isShow,
        'focus.isMainAriaHidden': false,
        'focus.isModalCookiePolicyOpen': isShow
      });
    },
    getIsAllowed: function getIsAllowed() {
      return Boolean(this.get('cookies').read(this.get('key')));
    },
    setValue: function setValue() {
      this.get('cookies').write(this.get('key'), true);
    },


    actions: {
      close: function close() {
        this.setProperties({
          isShow: false,
          'focus.isMainAriaHidden': false,
          'focus.isModalCookiePolicyOpen': false
        });

        this.setValue();
      }
    }
  });
});