define('handandstone-ember/pods/components/social-links/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['social'],
    'dynamic-menu': Ember.inject.service('dynamic-menu'),
    location: Ember.inject.service(),
    isSmallTitle: false,

    title: function () {
      return this.get('dynamic-menu.socialMenu.menu.name');
    }.property('dynamic-menu.socialMenu.menu.name'),

    links: function () {
      if (this.get('location.currentLocation')) {
        return this.get('location.currentLocation.socialLinks');
      }
      return this.get('location.centralOffice.socialLinks');
    }.property('location.currentLocation')
  });
});