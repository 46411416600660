define('handandstone-ember/pods/account/appointments/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['page', 'per-page', 'start_date'],
    page: 1,
    'per-page': 6,
    start_date: moment().format('YYYY-MM-DD'),
    current_date: moment().format('YYYY-MM-DD'),
    show_cancellations: false,

    isUpcoming: function () {
      return this.get('start_date') === this.get('current_date');
    }.property('start_date'),

    isShowAppointmentTable: function () {
      return Boolean(this.get('model.isPending') || this.get('model.length'));
    }.property('model.{isPending,length}')
  });
});