define('handandstone-ember/transforms/array', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Transform.extend({
        deserialize: function deserialize(serialized) {
            if (Ember.isArray(serialized)) {
                return Ember.A(serialized);
            } else {
                return Ember.A();
            }
        },

        serialize: function serialize(deserialized) {
            if (Ember.isArray(deserialized)) {
                return Ember.A(deserialized);
            } else {
                return Ember.A();
            }
        }
    });
});