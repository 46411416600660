define('handandstone-ember/pods/components/booking/appointment-time/appointment-time-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item appointment-time'],
    classNameBindings: ['statusClass', 'timeClass', 'isHotDeal:spa-deals'],
    attributeBindings: ['aria-pressed', 'type', 'disabled'],
    tagName: 'button',
    type: 'button',

    'aria-pressed': function () {
      return (this.get('timeClass') == 'active').toString();
    }.property('timeClass'),

    statusClass: function () {
      return this.get('time.status');
    }.property('time.status'),

    timeClass: function () {
      return this.get('time.class');
    }.property('time.class'),

    isHotDeal: function () {
      return Boolean(this.get('time.hotDeal'));
    }.property('time.hotDeal'),

    disabled: function () {
      return this.get('timeClass') == 'disabled';
    }.property('timeClass'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('index') == 0 && this.get('timeSliderIndex') == 0) {
        setTimeout(function () {
          /* TODO refactore code is_franchisee*/
          if (!_this.isDestroyed) {
            _this.$().focus();
          }
        }, 1);
      }
    },
    click: function click() {
      this.chooseTime();
    },
    keyPress: function keyPress(key) {
      if (key.keyCode == 13) {
        this.chooseTime();
      }
    },
    chooseTime: function chooseTime() {
      var props = this.getProperties('time', 'employee', 'index');

      this.sendAction('select', props.time.value, props.employee, props.index, props.time.hotDeal);
    }
  });
});