define('handandstone-ember/pods/blog/post/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    slug: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    short_description: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    category_id: _emberData.default.attr('string'),
    tags: _emberData.default.attr('array'),
    published_at: _emberData.default.attr('string'),
    prev_slug: _emberData.default.attr('string'),
    next_slug: _emberData.default.attr('string'),

    publishedDate: function () {
      return moment(this.get('published_at'), 'YYYY-MM-DD HH:mm').format('MMM DD, YYYY');
    }.property('published_at'),

    category: function () {
      if (this.get('category_id')) {
        return this.get('store').findRecord('blog/category', this.get('category_id'), { backgroundReload: false });
      }
    }.property('category_id'),

    prevPost: function () {
      if (this.get('prev_slug')) {
        return this.get('store').findRecord('blog/post', this.get('prev_slug'), { backgroundReload: false });
      }
    }.property('prev_slug'),

    nextPost: function () {
      if (this.get('next_slug')) {
        return this.get('store').findRecord('blog/post', this.get('next_slug'), { backgroundReload: false });
      }
    }.property('next_slug')
  });
});