define('handandstone-ember/pods/membership/rewards-program/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    model: function model() {
      return this.get('store').findRecord('membership', 'membership-rewards-program', { backgroundReload: false });
    }
  });
});