define('handandstone-ember/pods/components/account/appointment/item-description/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollTo: Ember.inject.service('scroll-to'),

    className: 'table',

    orderDate: Ember.computed('model.order_date', function () {
      return moment(this.get('model.order_date'), 'YYYY-MM-DD HH:mm').format("MMM DD, YYYY");
    }),

    appointmentDate: Ember.computed('model.date', 'model.time', function () {
      return moment(this.get('model.date') + ' ' + this.get('model.start_time'), 'YYYY-MM-DD HH:mm').format("dddd, MMM D, YYYY h:mm A");
    }),

    subTotalPrice: Ember.computed('model.add_ons', 'model.tax', 'model.service_price', function () {

      var result = parseFloat(this.get('model.service_price'));

      this.get('model.add_ons').forEach(function (value) {
        result += parseFloat(value.price);
      });
      return result;
    }),

    totalPrice: Ember.computed('model.add_ons', 'model.tax', 'model.service_price', function () {
      var result = parseFloat(this.get('model.service_price')) + parseFloat(this.get('model.tax'));

      this.get('model.add_ons').forEach(function (value) {
        result += parseFloat(value.price);
      });
      return result;
    }),

    didRender: function didRender() {
      var position = Ember.$('.dashboard').offset().top;
      this.get('scrollTo').jQueryJumpTo(position);
    }
  });
});