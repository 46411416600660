define('handandstone-ember/pods/components/booking/procedure-items/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNameBindings: ['activeClass'],

    isActive: function isActive() {
      return this.get('activeModel.type.abbr') == this.get('type.abbr');
    },
    isNoOneActive: function isNoOneActive() {
      return !this.get('activeModel.type');
    },


    activeClass: function () {
      return this.isActive() ? 'active' : '';
    }.property('activeModel.type'),

    short_description: Ember.computed('activeModel.type', function () {
      return this.isActive() || this.isNoOneActive() ? this.get('type.short_description') : '';
    }),

    actions: {
      types: function types(typeName) {
        this.sendAction('servicesAction', typeName);
      }
    }
  });
});