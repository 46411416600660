define('handandstone-ember/helpers/is-loading-process', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    onModelLoad: Ember.observer('models.@each.isFulfilled', function () {
      this.recompute();
    }),

    compute: function compute(params) {
      var result = false;
      var models = [];
      if (params) {
        params.forEach(function (item) {
          if (params[0] && params[0].isFulfilled === false) {
            result = true;
          }
          models.push(item);
        });
      }
      this.set('models', models);
      return result;
    }
  });
});