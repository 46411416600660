define('handandstone-ember/pods/components/featured/list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item'],

    correctedDate: function () {
      var result = false;

      if (this.get('model.date')) {
        result = moment(this.get('model.date'), 'DD-MM-YYYY').format('MMMM Do');
      }

      return result;
    }.property()
  });
});