define('handandstone-ember/pods/components/account/information/password-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    classNames: 'info-form',
    showError: false,
    saveFormStatus: null,

    model: function () {
      return this.get('store').createRecord('account/password');
    }.property(),

    resetModel: function resetModel() {
      this.set('model', this.get('store').createRecord('account/password'));
    },


    actions: {
      setValue: function setValue(value) {
        this.setProperties({
          state: value,
          'model.state': value
        });
      },

      save: function save() {
        var _this = this;

        this.set('showError', true);

        this.get('model').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (!_this.get('model.isSaving')) {
            if (validations.get('isValid')) {
              _this.get('model').save().then(function () {
                _this.resetModel();
                _this.set('showError', false);
                _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'Your password updated' } });
              }).catch(function () {
                _this.get('modal').open('small-popup', { model: { success: false, title: 'Error!', message: 'Error while updating password' } });
              });
            }
          }
        });
      }
    }
  });
});