define('handandstone-ember/pods/components/text-field/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    'text-fields': Ember.inject.service('text-fields'),

    tagName: 'span',
    slug: '',

    textFieldModel: function () {
      var field = this.get('slug');

      if (field) {
        return this.get("text-fields." + field);
      }
      return null;
    }.property('slug', 'text-fields.allModels.@each.slug')
  });
});