define('handandstone-ember/pods/components/account/payment/list-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'card',
    account: Ember.inject.service(),
    modal: Ember.inject.service(),
    isChangingMethods: false,

    actions: {
      delete: function _delete() {
        var _this = this;

        this.get('model').deleteRecord();
        this.set('isChangingMethods', true);
        if (!this.get('model.isSaving') && this.get('model.isDeleted')) {
          this.get('model').save().then(function () {
            _this.set('isChangingMethods', false);
            _this.sendAction('resetModel');
            _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'Payment method was deleted' } });
          }).catch(function () {
            _this.set('isChangingMethods', false);
            _this.get('modal').open('small-popup', { model: { success: false, title: 'Error!', message: 'Error while deleting payment method' } });
          });
        }
      },

      setAsDefault: function setAsDefault() {
        var _this2 = this;

        this.set('model.is_default', true);

        if (!this.get('model.isSaving')) {
          this.set('isChangingMethods', true);
          this.get('model').save().then(function () {
            _this2.set('isChangingMethods', false);
            _this2.sendAction('resetModel');
            _this2.get('account').notifyPropertyChange('paymentMethods');
            _this2.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'Payment method was saved as default' } });
          }).catch(function () {
            _this2.set('isChangingMethods', false);
            _this2.get('modal').open('small-popup', { model: { success: false, title: 'Error!', message: 'Error while saving payment method as default' } });
          });
        }
      }
    }
  });
});