define('handandstone-ember/pods/hot-deal/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    location_id: _emberData.default.attr('number'),
    class_abbr: _emberData.default.attr('string'),
    class_title: _emberData.default.attr('string'),
    service_id: _emberData.default.attr('number'),
    location_name: _emberData.default.attr('string'),
    service_code: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    old_price: _emberData.default.attr('number'),
    duration: _emberData.default.attr('number'),
    time: _emberData.default.attr('string'),
    date: _emberData.default.attr('string'),
    employee_id: _emberData.default.attr('number'),
    employee_full_name: _emberData.default.attr('string'),

    stringifyDateTame: function () {
      return this.get('time').replace(':', '') + this.get('date').replace('-', '');
    }.property('time', 'date'),

    employeeFormatName: function () {
      var name = this.get('employee_full_name');
      return name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }.property('employee_full_name'),

    formatPrice: function () {
      return this.priceFormatting(this.get('price'));
    }.property('price'),

    formatOldPrice: function () {
      return this.priceFormatting(this.get('old_price'));
    }.property('old_price'),

    priceFormatting: function priceFormatting(price) {
      var result = false;
      if (price) {
        var cents = Math.round(price * 100 % 100);
        if (cents.toString().length < 2) {
          cents = '0' + parseInt(cents).toString();
        } else {
          cents = parseInt(cents);
        }
        result = {
          dollars: parseInt(price),
          cents: cents
        };
      }

      return result;
    },


    formatDate: function () {
      return moment(this.get('date'), "YYYY-MM-DD").format('dddd, MMM DD, YYYY');
    }.property('date'),

    formatTime: function () {
      return moment(this.get('time'), "HH:mm").format('h:mm A');
    }.property('time')
  });
});