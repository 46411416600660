
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('handandstone-ember/app')['default'].create({"API_HOST":"https://staging.hs-di.bigdropinc.net/api","API_HOST_NAMESPACE":"v1","CURRENT_URl":"https://staging.hs.bigdropinc.net","NEW_SERVICE_VIEW":true,"name":"handandstone-ember","version":"2.0.0"});
  }
}

define('~fastboot/app-factory', ['handandstone-ember/app', 'handandstone-ember/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

