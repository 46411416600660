define('handandstone-ember/pods/components/booking/detail-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service(),
    account: Ember.inject.service(),

    classNames: 'booking-details',
    classNameBindings: ['dynamicClasses', 'stepIsHasTimerClass'],

    dynamicClasses: Ember.computed('model.introductory', 'model.step', 'model.location.isFulfilled', function () {
      var result = this.get('model.step') + '-step';

      if (!this.get('model.location.isFulfilled')) {
        result += ' loading';
      }

      return result;
    }),

    chooseAnotherLocationHiddenVal: function () {
      return (!(this.get('model.step') == 'first')).toString();
    }.property('model.step'),

    stepIsHasTimerClass: function () {
      return this.get('isActiveTimer') ? 'step-has-timer' : null;
    }.property('isActiveTimer'),

    showTimer: Ember.computed('model.{step,isIntroductory,expired_at}', function () {
      var model = this.get('model');
      return model.get('expired_at') && !model.get('isFourthStep') && !(model.get('isThirdStep') && model.get('isIntroductory'));
    }),

    notShowHotDeals: Ember.computed.or('model.hot_deal', 'model.isIntroductory'),

    showDropdownBySteps: function () {
      this.initDropdown();
    }.observes('model.isPaymentStep'),

    init: function init() {
      this._super.apply(this, arguments);
      this.initDropdown();
    },
    initDropdown: function initDropdown() {
      this.set('isOpenDropdown', this.get('model.isPaymentStep') || this.get('model.isConfirmationStep'));
    },


    actions: {
      openModal: function openModal(parameter) {
        this.sendAction('openModal', parameter);
      },
      toggleAddon: function toggleAddon(parameter) {
        this.sendAction('toggleAddon', parameter);
      },
      clearStore: function clearStore(parameter) {
        this.sendAction('clearStore', parameter);
      },
      logout: function logout() {
        this.get('account.session').invalidate();
        this.sendAction('refreshBooking');
      },
      changeBookingDropdown: function changeBookingDropdown() {
        this.toggleProperty('isOpenDropdown');
      }
    }
  });
});