define('handandstone-ember/initializers/reopen-components', ['exports', 'ember-modal-service/components/modal-container'], function (exports, _modalContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  _modalContainer.default.reopen({
    attributeBindings: ['aria-hidden']
  });

  Ember.LinkComponent.reopen({
    attributeBindings: ['aria-describedby', 'aria-current', 'aria-hidden']
  });

  Ember.TextField.reopen({
    attributeBindings: ['aria-required', 'aria-invalid', 'aria-describedby', 'aria-expanded', 'role', 'aria-label']
  });

  Ember.Checkbox.reopen({
    attributeBindings: ['aria-labelledby', 'aria-describedby', 'aria-required', 'aria-invalid']
  });

  function initialize() /* application */{
    // application.inject('route', 'foo', 'service:foo');
  }

  exports.default = {
    name: 'reopen-components',
    initialize: initialize
  };
});