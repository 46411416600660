define('handandstone-ember/pods/components/subscription-form/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    modal: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.setNewModel();
    },
    setNewModel: function setNewModel() {
      this.set('showError', false);
      this.set('model', this.get('store').createRecord('subscription'));
    },


    model: null,
    showError: false,
    errorClass: Ember.computed('showError', function () {
      return this.get('showError') ? 'has-error' : '';
    }),
    actions: {
      subscribe: function subscribe() {
        var _this = this;

        this.set('showError', true);
        if (!this.get('model.isSaving')) {
          this.get('model').validate().then(function (_ref) {
            var validations = _ref.validations;

            if (validations.get('isValid')) {
              _this.get('model').save().then(function () {
                _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'You are subscribed' } });
                _this.setNewModel();
              }).catch(function (response) {
                if (response.errors) {
                  response.errors.forEach(function (item) {
                    switch (item.source.pointer.split('/').pop()) {
                      case 'email':
                        _this.set('model.error.email', item.detail);
                        break;
                      case 'name':
                        _this.set('model.error.name', item.detail);
                        break;
                      default:
                        _this.set('model.error.email', item.detail);
                        break;
                    }
                  });
                }
              });
            }
          });
        }
      }
    }
  });
});