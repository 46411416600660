define('handandstone-ember/pods/locations/controller', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    headTagsService: Ember.inject.service('head-tags'),
    seo: Ember.inject.service('seo'),

    pageTextForTitle: function () {
      var text = this.get('page') && this.get('page') > 1 ? ' Page ' + this.get('page') : '';

      this.updateDescriptionTag(text);
      return text;
    }.property('page', 'model.locations.meta.{currentPage,pageCount}'),

    updateDescriptionTag: function updateDescriptionTag(pageText) {
      var result = [{
        type: 'meta',
        attrs: {
          name: 'description',
          content: 'We offer other massage protocols and can customize a massage for you. Call your closest Hand & Stone Spa for more massage services. ' + pageText
        }
      }, {
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: _environment.default.APP.CURRENT_URl + '/locations' + (pageText ? '?page=' + this.get('page') : '')
        }
      }];

      if (this.get('model.locations.meta.pageCount') > 1) {
        if (this.get('model.locations.meta.currentPage') > 1) {
          result.push({
            type: 'link',
            attrs: {
              rel: 'prev',
              href: _environment.default.APP.CURRENT_URl + '/locations?page=' + (this.get('model.locations.meta.currentPage') - 1)
            }
          });
        }

        if (this.get('model.locations.meta.pageCount') > this.get('model.locations.meta.currentPage')) {
          result.push({
            type: 'link',
            attrs: {
              rel: 'next',
              href: _environment.default.APP.CURRENT_URl + '/locations?page=' + (this.get('model.locations.meta.currentPage') + 1)
            }
          });
        }
      }

      this.set('seo.extendedMeta', result);

      //TODO: double call
      // this.get('headTagsService').collectHeadTags();
    },


    loadedADAMessage: function () {
      var result = 'All Hand and Stone locations';
      if (this.get('model.locations.filterAddress') && this.get('model.locations.search.distance')) {
        result = 'Hand and Stone locations within ' + this.get('model.locations.search.distance') + ' miles';
      }

      return result;
    }.property('model.locations.search.distance', 'model.locations.filterAddress')
  });
});