define('handandstone-ember/pods/components/booking/appointment-date/appointment-date-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item-inner appointment-date'],
    classNameBindings: ['activeClass', 'disabledClass'],
    attributeBindings: ['aria-pressed', 'type', 'disabled'],
    tagName: 'button',
    type: 'button',

    'aria-pressed': function () {
      return this.get('day.isSelected').toString();
    }.property('day.isSelected'),

    activeClass: function () {
      return this.get('day.activeClass');
    }.property('day.activeClass'),

    disabledClass: function () {
      return this.get('day.disabledClass');
    }.property('day.disabledClass'),

    disabled: function () {
      return this.get('day.disabledClass');
    }.property('day.disabledClass'),

    click: function click() {
      this.chooseDay();
    },
    keyPress: function keyPress(key) {
      if (key.keyCode == 13) {
        this.chooseDay();
      }
    },
    chooseDay: function chooseDay() {
      var disabledClass = this.get('day.disabledClass');

      if (!disabledClass) {
        this.sendAction('selectDay', this.get('day.value'), disabledClass);
      }
    }
  });
});