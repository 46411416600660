define('handandstone-ember/pods/service/intro/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    helper: Ember.inject.service('helper'),

    slug: _emberData.default.attr('string'),
    location_id: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    service_id: _emberData.default.attr('number'),
    global_id: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    external_service: _emberData.default.attr('string'),
    duration: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    code: _emberData.default.attr('string'),

    formattedPrice: function () {
      return this.get('helper').priceFormatting(this.get('price'));
    }.property('price'),

    isMassage: function () {
      return this.get('type') === 'MA';
    }.property('type'),

    isFacial: function () {
      return this.get('type') === 'FI';
    }.property('type'),

    isNeveskin: function () {
      return this.get('type') === 'NS';
    }.property('type'),

    isOneHourHSM: function () {
      return this.get('code').toLowerCase() === 'introhs50';
    }.property('code')
  });
});