define('handandstone-ember/pods/components/modal-small-popup/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    attributeBindings: ['aria-describedby'],
    'aria-describedby': 'small-popup-describedby',

    customData: function () {
      return this.get('model.options.model');
    }.property('model.options.model')
  });
});