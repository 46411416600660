define('handandstone-ember/pods/subscription/model', ['exports', 'ember-data', 'ember-cp-validations'], function (exports, _emberData, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ValidationsSubscribe = (0, _emberCpValidations.buildValidations)({
    email: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', { type: 'email' })],
    name: [(0, _emberCpValidations.validator)('length', {
      min: 2,
      max: 40
    })]
  });

  exports.default = _emberData.default.Model.extend(ValidationsSubscribe, {
    email: _emberData.default.attr('string'),
    name: _emberData.default.attr('string') /*,
                                            isValidEmail: Ember.computed.match('email', /^.+@.+\..+$/),
                                            isValid: Ember.computed.and('isValidEmail'),
                                            isNotValid: Ember.computed.not('isValid')*/
  });
});