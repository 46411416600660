define('handandstone-ember/pods/service/adapter', ['exports', 'handandstone-ember/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    findHasMany: function findHasMany(store, snapshot, url, relationship) {
      for (var key in relationship.options.conditions) {
        url += url.indexOf('?') != -1 ? '&' : '?';
        url += key + '=' + snapshot.record.get(relationship.options.conditions[key]);
      }
      for (var _key in relationship.options.params) {
        url = url.replace(':' + _key, snapshot.record.get(relationship.options.params[_key]));
      }

      return this._super(store, snapshot, url, relationship);
    }
  });
});