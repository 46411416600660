define('handandstone-ember/pods/components/account/dashboard/items-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'dashboard-info',
    classNameBindings: ['isLoading'],
    isLoading: function () {
      return this.get('account.homeLocation.isPending') || this.get('account.model.isPending') || this.get('account.paymentMethods.isPending') ? 'loading' : '';
    }.property('account.homeLocation.isPending', 'account.model.isPending', 'account.paymentMethods.isPending'),

    account: Ember.inject.service('account'),
    location: Ember.inject.service('location')
  });
});