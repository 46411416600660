define('handandstone-ember/pods/class/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    primaryKey: 'abbr',
    normalize: function normalize(model, hash) {
      hash.links = {
        "result-classification": 'result-classifications'
      };
      return this._super.apply(this, arguments);
    }
  });
});