define('handandstone-ember/services/scroll-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    jQueryJumpTo: function jQueryJumpTo(topPosition, delay, afterScrollCallback) {
      Ember.$('html, body').animate({
        scrollTop: topPosition
      }, delay || 500, function () {
        if (afterScrollCallback) {
          afterScrollCallback();
        }
      });
    },
    savePageOffset: function savePageOffset() {
      // if (Ember.$('body').hasClass('iosBugFixCaret')) {
      //   let offset = Ember.$(window).scrollTop();
      //   Ember.$('body').css('top', -offset + 'px');
      // }
      //
      // Ember.$('body').addClass('popup-open');
    },
    applyPageOffset: function applyPageOffset() {
      // Ember.$('body').removeClass('popup-open');
      //
      // if (Ember.$('body').hasClass('iosBugFixCaret')) {
      //   let scrollTo = -Ember.$('body').position().top;
      //   Ember.$('body').css('top', 0);
      //   Ember.$('html, body').scrollTop(scrollTo);
      // }
    }
  });
});