define('handandstone-ember/pods/booking/adapter', ['exports', 'handandstone-ember/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    createRecord: function createRecord(store, type, record) {
      var id = Ember.get(record, 'id');

      if (id) {
        var data = {};
        var serializer = store.serializerFor(record.modelName);
        serializer.serializeIntoHash(data, type, record);
        return this.ajax(this.buildURL(record.modelName, id), "PUT", { data: data });
      } else {
        return this._super.apply(this, arguments);
      }
    }
  });
});