define('handandstone-ember/pods/blog/post/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    model: function model(params) {
      var _this = this;

      var tags = this.get('store').findAll('blog/tag', { backgroundReload: false });
      var categories = this.get('store').findAll('blog/category', { backgroundReload: false });

      return this.get('store').findRecord('blog/post', params.slug, { backgroundReload: false }).then(function (model) {
        return Ember.RSVP.hash({
          nextPost: model.get('nextPost'),
          prevPost: model.get('prevPost'),
          post: model,
          tags: tags,
          categories: categories
        });
      }).catch(function (err) {
        if (err.errors[0].status === 404) {
          _this.send('error', { status: 404 });
        }
      });
    }
  });
});