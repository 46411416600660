define('handandstone-ember/pods/components/booking/service-items/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scrollTo: Ember.inject.service('scroll-to'),
    modal: Ember.inject.service(),

    tagName: 'li',
    attributeBindings: ['style'],
    classNameBindings: ['getClass'],

    style: function () {
      var image = this.get('isEven') ? this.service.get('image_left') : this.service.get('image_right');

      return image ? Ember.String.htmlSafe('background-image: url(\'' + image + '\');') : null;
    }.property('isEven'),

    isEven: function () {
      return Boolean(this.get('index') % 2);
    }.property('index'),

    isActive: function isActive() {
      return this.get('activeModel.id') == this.get('service.id');
    },

    activeButtonClass: Ember.computed('activeModel', function () {
      return this.isActive() ? 'active-state' : '';
    }),

    getClass: function () {
      return this.isActive() ? 'check' : '';
    }.property('activeModel'),

    caption: Ember.computed('activeModel', function () {
      return this.isActive() ? 'selected' : 'book now';
    }),

    checkedService: function () {
      if (this.isActive() && this.get('service.can_book')) {
        var position = Ember.$('.footer').offset().top;
        this.get('scrollTo').jQueryJumpTo(position, 500);
      }
    }.on('didInsertElement').observes('activeModel'),

    formatPrice: function formatPrice(price) {
      var cents = Math.round(price * 100 % 100);
      if (cents.toString().length < 2) {
        cents = '0' + parseInt(cents).toString();
      } else {
        cents = parseInt(cents);
      }
      return {
        dollars: parseInt(price),
        cents: cents
      };
    },


    minimumPrice: function () {
      var _this = this;

      var self = this,
          price = -1;
      this.get('location.servicesDuration').then(function (result) {
        result.forEach(function (duration) {
          if (duration.get('service_code') === self.get('service.slug')) {
            if (price == -1 || price > duration.get('price')) {
              price = duration.get('price');
            }
          }
        });
        _this.set('minimumPrice', _this.formatPrice(price));
      });
    }.property(),

    cents: function () {
      return this.get('minimumPrice.cents');
    }.property('minimumPrice'),

    dollars: function () {
      return this.get('minimumPrice.dollars');
    }.property('minimumPrice'),

    actions: {
      info: function info(service) {
        this.get('modal').open('booking-service-info', { model: service });
      },
      book: function book(service) {
        this.sendAction('bookAction', service);
      }
    }
  });
});