define('handandstone-ember/services/external-booking', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    alreadyProcessed: false,
    helper: Ember.inject.service(),

    process: function process(locationModel, serviceModel, externalService) {
      if (!this.get('alreadyProcessed')) {
        this.set('alreadyProcessed', true);
        if (locationModel && locationModel.get('external_booking') && locationModel.get('external_link')) {
          var externalLink = locationModel.get('external_link');
          if (externalService) {
            externalLink = this.addServiceIdToLink(externalLink, externalService);
          } else {
            if (serviceModel != null) {
              var services = serviceModel.get('external_services');
              if (services) {
                for (var id in services) {
                  if (id == this.get('helper.user_types.non_member')) {
                    externalLink = this.addServiceIdToLink(externalLink, services[id]);
                    break;
                  } else if (id == this.get('helper.user_types.member')) {
                    externalLink = this.addServiceIdToLink(externalLink, services[id]);
                    break;
                  }
                }
              }
            }
          }
          window.location.href = externalLink;
          return true;
        }
      }

      return false;
    },
    addServiceIdToLink: function addServiceIdToLink(baseLink, serviceId) {
      if (!serviceId.length) {
        return baseLink;
      }

      return baseLink + '?serviceid=' + serviceId;
    }
  });
});