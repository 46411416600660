define('handandstone-ember/pods/press/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    published_at: _emberData.default.attr('string'),

    publishedDate: function () {
      return moment(this.get('published_at'), 'YYYY-MM-DD HH:mm').format('MMM DD, YYYY');
    }.property('published_at')
  });
});