define('handandstone-ember/pods/components/modal-forgot-pass/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    store: Ember.inject.service(),
    successSendEmail: false,
    showError: false,
    attributeBindings: ['aria-describedby'],
    'aria-describedby': 'forgot-pass-describedby',

    hasError: Ember.computed('showError', 'modelForgot.errors', 'modelForgot.validations.isInvalid', function () {
      return this.get('showError') && (this.get('modelForgot.validations.isInvalid') || this.get('modelForgot.errors'));
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelForgot', this.get('store').createRecord('auth/forgot-password'));
    },

    actions: {
      forgot: function forgot() {
        var _this = this;

        var curModel = this.get('modelForgot');

        this.set('showError', true);
        curModel.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            if (!curModel.get('isSaving')) {
              curModel.save().then(function () {
                _this.set('successSendEmail', true);
                _this.get('focus').restoreSavedModalFocus();
                _this.get('modal').close('forgot-pass');
                _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'Email was sent. Please check your email.' } });
                _this.get('store').unloadRecord(curModel);
              }).catch(function () {
                _this.get('focus').restoreSavedModalFocus();
              });
            }
          }
        });
      }
    }
  });
});