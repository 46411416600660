define('handandstone-ember/pods/booking/route', ['exports', 'handandstone-ember/mixins/seo-module-route', 'lodash'], function (exports, _seoModuleRoute, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    'booking-store': Ember.inject.service(),
    'external-booking': Ember.inject.service(),
    scrollTo: Ember.inject.service('scroll-to'),
    location: Ember.inject.service(),
    account: Ember.inject.service(),
    helper: Ember.inject.service(),
    modal: Ember.inject.service(),
    fastboot: Ember.inject.service(),

    queryParams: {
      referralid: "",
      site_tracking_id: "",
      service_id: "",
      class_id: "",
      provider: ""
    },

    model: function model(params, transition) {
      var _this2 = this;

      this.set('isFirstTimeVisitorMode', _lodash.default.get(transition, 'queryParams.isFirstTimeVisitorMode', false));

      return this.get('location').getBySlug(params.slug).then(function (response) {
        if (response.get('is_coming_soon')) {
          _this2.send('error', { status: 404 });
        }
        if (!_this2.get('booking-store.booking.model.hot_deal')) {
          if (!_this2.get('external-booking').process(response, _this2.get('serviceModel'), null)) {
            _this2.onlyMemberBooking(response);
          }
        }

        if (!response.get('isBookable')) {
          _this2.replaceWith('/locations/' + response.get('slug'));
          _this2.get('modal').open('location-nonbookable');
        }

        if (_this2.get('booking-store.booking.model')) {
          return _this2.loadModel(response.get('id'));
        }

        return _this2.newModel(response.get('id'), params);
      }).catch(function (error) {
        _this2.send('error', error);
      });
    },

    step: 'first',

    onlyMemberBooking: function onlyMemberBooking(location) {
      if (location.get('appointment_for_members_lmd_everyone')) {
        if (!this.get('account.session.isAuthenticated')) {
          this.get('modal').open('no-guest-booking');
        } else {
          if (!this.get('account.model.membership_status')) {
            this.get('modal').open('only-members-booking', {
              'model': {
                'location': location
              }
            });
          }
        }
      }
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        isFirstTimeVisitorMode: this.get('isFirstTimeVisitorMode')
      });
    },

    animatedStepChange: function animatedStepChange() {
      this.get('scrollTo').jQueryJumpTo(0);
    },


    newModel: function newModel(id, params) {
      var _this3 = this;

      this.clearStoreModel();

      this.get('store').unloadAll('booking');
      return this.get('account.model').then(function (result) {
        var external = false;
        var extendedData = {};
        if (result) {
          extendedData['user_type'] = result.get('membership_status') ? _this3.get('helper.user_types.member') : _this3.get('helper.user_types.non_member');
        }

        extendedData = Object.assign({
          location_id: id,
          step: _this3.get('step')
        }, extendedData);

        if (params['service_id'] && params['class_id'] && !_this3.get('account.session.isAuthenticated')) {
          external = true;
          extendedData['isExternalLink'] = true;
          extendedData['isDisplayAuthBlock'] = false;
          extendedData['service_id'] = params['service_id'];
          extendedData['type_id'] = params['class_id'];
          extendedData['user_type'] = _this3.get('helper.user_types.introductory');
          extendedData['isIntroductory'] = true;
          extendedData['hot_deal'] = 0;
        }

        var model = _this3.get('store').createRecord('booking', extendedData);
        model.notifyPropertyChange('step');

        if (external) {
          _this3.storeModel(model);
          return _this3.loadIntroductoryModel(model);
        }

        return model;
      }).catch(function () {
        _this3.refresh();
      });
    },

    reservationHotDeal: function reservationHotDeal(model) {
      var _this4 = this;

      model.set('reservationFlag', true);
      if (!model.get('isSaving')) {
        model.save().then(function () {
          model.set('reservationFlag', false);
          _this4.storeModel();
        }, function (error) {
          if (_this4.checkErrorUnauthorized(error)) {
            _this4.expiredAuthToken();
          } else {
            _this4.clearStoreModel();
            _this4.get('modal').open('booking-errors', { model: model });
          }

          model.set('reservationFlag', false);
        });
      }
    },


    loadModel: function loadModel(id) {
      var _this5 = this;

      if (this.get('booking-store.booking.model.location_id') != id) {
        return this.newModel(id);
      }

      var bookingModel = this.get('store').peekAll('booking').get('firstObject');
      if (!bookingModel) {
        bookingModel = this.get('store').createRecord('booking', this.get('booking-store.booking.model'));

        bookingModel.setProperties({
          step: this.get('booking-store.booking.step') ? this.get('booking-store.booking.step') : 'first',
          isDisplayAuthBlock: this.get('booking-store.booking.isDisplayAuthBlock'),
          id: this.get('booking-store.booking.id'),
          isExternalLink: this.get('booking-store.booking.isExternalLink')
        });

        if (this.get('booking-store.booking.model.hot_deal')) {
          return this.loadHotDealModel(bookingModel);
        }

        if (bookingModel.get('isIntroductory')) {
          return this.loadIntroductoryModel(bookingModel);
        }

        bookingModel.get('location').then(function () {
          _this5.get('account.model').then(function (result) {
            if (result && !bookingModel.get('isThirdStepOrAbove')) {
              bookingModel.set('user_type', result.get('membership_status') ? _this5.get('helper.user_types.member') : _this5.get('helper.user_types.non_member'));
            }

            bookingModel.set('location.user_type', bookingModel.get('user_type'));
            bookingModel.get('location.servicesDuration').then(function () {
              bookingModel.get('types').then(function (classes) {
                classes.forEach(function (item) {
                  if (item.get('id') === bookingModel.get('type_id')) {
                    return bookingModel.setType(item);
                  }
                });

                if (bookingModel.get('location.activeServices')) {
                  bookingModel.get('location.activeServices').then(function (services) {
                    services.forEach(function (item) {
                      var storeServiceCode = _this5.get('booking-store.booking.model.service_code'),
                          storeServiceId = _this5.get('booking-store.booking.model.service_id');

                      if (item.get('id') == storeServiceCode) {
                        bookingModel.setService(item);
                        bookingModel.get('durationsList').forEach(function (duration) {

                          if (duration.get('service_id') == storeServiceId) {
                            return bookingModel.setDuration(duration);
                          }
                        });

                        return false;
                      }
                    });

                    bookingModel.get('location.addons').forEach(function (addon) {
                      bookingModel.get('addon_services').forEach(function (selectedAddon) {
                        if (addon.get('addon_id') == selectedAddon) {
                          bookingModel.addAddon(addon);
                        }
                      });
                    });

                    if (bookingModel.get('service_id')) {
                      bookingModel.get('service.employees').then(function (employes) {
                        if (_this5.get('booking-store.booking.model.sex')) {
                          bookingModel.setSex(_this5.get('booking-store.booking.model.sex'));
                        }
                        if (employes) {
                          employes.forEach(function (employee) {
                            if (employee.id == _this5.get('booking-store.booking.model.specific_employee_id')) {
                              bookingModel.setEmployee(employee);
                            }
                            if (employee.id == _this5.get('booking-store.booking.model.employee_id')) {
                              bookingModel.setProperties({
                                employee_id: employee.id,
                                employee: employee
                              });
                            }
                          });
                        }

                        bookingModel.setDate(_this5.get('booking-store.booking.model.date'));
                        bookingModel.setTime(_this5.get('booking-store.booking.model.time'));

                        bookingModel.setProperties({
                          bookingKey: _this5.get('booking-store.booking.model.bookingKey'),
                          expired_at: _this5.get('booking-store.booking.model.expired_at')
                        });

                        _this5.get('booking-store').write();
                      });
                    }
                  });
                }
              });
            });
          }).catch(function () {
            _this5.refresh();
          });
        });
      }
      return bookingModel;
    },

    loadHotDealModel: function loadHotDealModel(bookingModel) {
      var _this6 = this;

      if (bookingModel.get('hot_deal') && !bookingModel.get('bookingKey')) {
        this.reservationHotDeal(bookingModel);
      }

      bookingModel.get('location').then(function () {
        bookingModel.setDate(_this6.get('booking-store.booking.model.date'));
        bookingModel.setTime(_this6.get('booking-store.booking.model.time'));

        bookingModel.setProperties({
          bookingKey: _this6.get('booking-store.booking.model.bookingKey'),
          expired_at: _this6.get('booking-store.booking.model.expired_at'),
          hot_deal: _this6.get('booking-store.booking.model.hot_deal'),
          service_id: _this6.get('booking-store.booking.model.service_id'),
          service_code: _this6.get('booking-store.booking.model.service_code'),
          service_price: _this6.get('booking-store.booking.model.service_price'),
          tax: _this6.get('booking-store.booking.model.tax'),
          duration_time: _this6.get('booking-store.booking.duration_time'),
          employee: _this6.get('store').findRecord('employee', _this6.get('booking-store.booking.model.employee_id'))
        });

        //TODO: need refactoring this code
        bookingModel.get('types').then(function (classes) {
          classes.forEach(function (item) {
            if (item.get('id') === bookingModel.get('type_id')) {
              bookingModel.setProperties({
                type: item,
                type_id: item.id
              });

              return bookingModel.get('location').set('type', item);
            }
          });

          if (bookingModel.get('location.activeServices')) {
            bookingModel.get('location.activeServices').then(function (services) {
              services.forEach(function (item) {
                if (item.get('id') === _this6.get('booking-store.booking.model.service_code')) {
                  return bookingModel.set('service', item);
                }
              });
            });
          }
        });
      });
      return bookingModel;
    },

    loadIntroductoryModel: function loadIntroductoryModel(bookingModel) {
      var _this7 = this;

      bookingModel.get('location').then(function () {
        bookingModel.set('location.user_type', bookingModel.get('user_type'));
        bookingModel.get('location.servicesDuration').then(function () {
          bookingModel.get('location.introductory').then(function (introductory) {
            bookingModel.get('types').then(function (classes) {
              classes.forEach(function (item) {
                if (item.get('id') == bookingModel.get('type_id')) {
                  return bookingModel.setType(item);
                }
              });
              if (bookingModel.get('location.activeServices')) {
                bookingModel.get('location.activeServices').then(function (services) {
                  services.forEach(function (item) {
                    if (bookingModel.get('isExternalLink')) {
                      bookingModel.get('durationsListById').forEach(function (duration) {
                        if (duration.get('service_id') === _this7.get('booking-store.booking.model.service_id')) {
                          bookingModel.set('service_code', duration.get('service_code'));
                          _this7.storeModel();
                        }
                      });
                    }

                    if (item.get('id') === _this7.get('booking-store.booking.model.service_code')) {
                      bookingModel.setService(item);
                      bookingModel.get('durationsList').forEach(function (duration) {
                        if (_this7.get('booking-store.booking.model.service_id')) {
                          if (duration.get('service_id') === _this7.get('booking-store.booking.model.service_id')) {
                            bookingModel.setDuration(duration);
                          }
                        } else {
                          introductory.forEach(function (intro) {
                            if (intro.get('slug') === _this7.get('booking-store.booking.model.service_code') && duration.get('service_id') === intro.get('service_id')) {
                              bookingModel.setDuration(duration);
                            }
                          });
                        }
                      });
                      return false;
                    }
                  });

                  if (bookingModel.get('service_id')) {
                    bookingModel.get('service.employees').then(function (employes) {
                      if (_this7.get('booking-store.booking.model.sex')) {
                        bookingModel.setSex(_this7.get('booking-store.booking.model.sex'));
                      }
                      if (employes) {
                        employes.forEach(function (employee) {
                          if (employee.id == _this7.get('booking-store.booking.model.specific_employee_id')) {
                            bookingModel.setEmployee(employee);
                          }
                          if (employee.id == _this7.get('booking-store.booking.model.employee_id')) {
                            bookingModel.setProperties({
                              employee_id: employee.id,
                              employee: employee
                            });
                          }
                        });
                      }

                      bookingModel.setDate(_this7.get('booking-store.booking.model.date'));
                      bookingModel.setTime(_this7.get('booking-store.booking.model.time'));

                      bookingModel.setProperties({
                        bookingKey: _this7.get('booking-store.booking.model.bookingKey'),
                        expired_at: _this7.get('booking-store.booking.model.expired_at'),
                        hot_deal: _this7.get('booking-store.booking.model.hot_deal'),
                        hide_variation: _this7.get('booking-store.booking.model.hide_variation')
                      });
                    });
                  }
                });
              }
            });
          });
        });
      });

      return bookingModel;
    },

    storeModel: function storeModel(model) {
      var m = this.currentModel;
      if ((typeof model === 'undefined' ? 'undefined' : _typeof(model)) === 'object') {
        m = model;
      }

      this.setProperties({
        'booking-store.booking': { model: m.toJSON() },
        'booking-store.booking.step': m.get('step'),
        'booking-store.booking.isDisplayAuthBlock': m.get('isDisplayAuthBlock'),
        'booking-store.booking.isExternalLink': m.get('isExternalLink'),
        'booking-store.booking.id': m.get('id')
      });

      this.get('booking-store').write();
    },

    clearStoreModel: function clearStoreModel() {
      this.get('booking-store').clear();
    },

    expiredAuthToken: function expiredAuthToken() {
      this.refresh();
      this.get('modal').open('expired-token');
    },

    checkErrorUnauthorized: function checkErrorUnauthorized(error) {
      return typeof error !== 'undefined' && typeof error.errors !== 'undefined' && typeof error.errors[0] !== 'undefined' && typeof error.errors[0].status !== 'undefined' && parseInt(error.errors[0].status) === 401;
    },

    actions: {
      types: function types(type) {
        this.currentModel.setType(type);
        var position = Ember.$('.services-list-scroll').offset().top - 30;
        this.get('scrollTo').jQueryJumpTo(position, 500);
      },
      properties: function properties(service) {
        this.currentModel.setService(service);
      },
      reservation: function reservation() {
        var _this8 = this;

        this.currentModel.setEmployeeId();
        var model = this.currentModel;

        if (!model.get('isSaving')) {
          model.setStoreBookingReservation(model.get("employee"));

          model.set('reservationFlag', true);
          model.save().then(function () {
            _this8.send('nextStep');
            model.set('reservationFlag', false);
          }, function (error) {
            if (_this8.checkErrorUnauthorized(error)) {
              _this8.expiredAuthToken();
            } else {
              _this8.get('modal').open('booking-errors', { model: model });
              _this8.currentModel.set('employee_id', null);
            }
            model.set('reservationFlag', false);
          });
        }
      },
      confirmation: function confirmation() {
        var _this9 = this;

        var model = this.currentModel;

        model.setProperties({
          finish_booking: true,
          saveProcess: true
        });

        if (!model.get('isSaving')) {
          model.setStoreBookingConfirmation();

          model.save().then(function () {
            model.set('saveProcess', false);
            _this9.send('nextStep');
            _this9.get('location').setCurrentRoute(_this9.get('routeName') + '/finish');
            _this9.clearStoreModel();
          }, function (error) {
            if (_this9.checkErrorUnauthorized(error)) {
              _this9.expiredAuthToken();
            } else {
              model.set('finish_booking', false);
              _this9.get('modal').open('booking-errors', { model: model });
            }
            model.set('saveProcess', false);
          });
        }
      },
      nextStep: function nextStep() {
        if (this.currentModel.changeStep(1)) {
          this.animatedStepChange();
          if (this.currentModel.get('step') != 'fourth') {
            this.storeModel();
          }
        }
      },
      previousStep: function previousStep() {
        this.currentModel.changeStep(-1);
      },
      newBooking: function newBooking() {
        this.get('booking-store').clear();
        this.refresh();
      },
      refreshBooking: function refreshBooking() {
        this.refresh();
      },
      selectDuration: function selectDuration(duration) {
        this.currentModel.setDuration(duration);
      },
      toggleAddon: function toggleAddon(addon) {
        this.currentModel.toggleAddons(addon);
      },
      selectSex: function selectSex(sex) {
        this.currentModel.setSex(sex);
      },
      selectSpecificEmployee: function selectSpecificEmployee(employee) {
        this.currentModel.setEmployee(employee);
      },
      addEmployee: function addEmployee(employee) {
        this.currentModel.setProperties({
          employee: employee,
          employee_id: employee.id
        });
      },
      selectDate: function selectDate(date) {
        this.currentModel.setDate(date);
      },
      selectTime: function selectTime(time) {
        this.currentModel.setTime(time);
      },
      selectHotDeal: function selectHotDeal(hotDeal) {
        this.currentModel.setHotDeal(hotDeal);
      },


      didTransition: function didTransition() {
        var _this10 = this;

        Ember.run.next(function () {
          if (!_this10.get('fastboot.isFastBoot')) {
            var step = Ember.$('.booking-area .step-area ul li');
            if (step.length) {
              step.css('padding-left', 100 / (step.length - 1) + '%');
            }
          }
        });
        return true;
      },

      clearStore: function clearStore() {
        this.clearStoreModel();
      },

      closeModalAndRefresh: function closeModalAndRefresh() {
        if (this.currentModel.get('hot_deal')) {
          this.send('closeModal', 'hot-deal');
        } else {
          this.refresh();
          this.send('closeModal');
        }
      },

      showAuthBlock: function showAuthBlock() {
        if (!this.get('booking-store.booking.model')) {
          this.set('booking-store.booking', {
            model: {
              date: null,
              time: null,
              hot_deal: 0,
              location_id: this.get('location.bookingLocationId')
            }
          });
        }

        this.set('booking-store.booking.isDisplayAuthBlock', true);
        this.get('booking-store').write();
        this.currentModel.set('isDisplayAuthBlock', true);
      },
      resetBookingAction: function resetBookingAction() {
        var _this = this;

        this.get('modal').open('confirm', {
          model: {
            title: 'Would you like to restart the booking process?',
            actionConfirm: function actionConfirm() {
              _this.actions.newBooking.apply(_this);
            }
          }
        });
      }
    }
  });
});