define('handandstone-ember/pods/components/input-extended/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    disablePaste: false,

    paste: function paste() {
      if (this.get('disablePaste')) {
        return false;
      }
    }
  });
});