define('handandstone-ember/pods/components/booking/success-message/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.inject.service(),
    scrollTo: Ember.inject.service('scroll-to'),

    codeOptions: {
      displayValue: false,
      height: 40,
      format: "CODE39",
      fontSize: 16,
      textMargin: 0
    },

    didInsertElement: function didInsertElement() {
      this.get('scrollTo').jQueryJumpTo(Ember.$(".success-message").offset().top - 50, 750);
    },

    booking_location: function () {
      return this.get('location.booking');
    }.property(),

    actions: {
      newBooking: function newBooking() {
        this.sendAction('newBooking');
      }
    }
  });
});