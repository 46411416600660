define('handandstone-ember/pods/components/hot-deals/item-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',

    isEqualPrice: function () {
      return this.get('deal.formatPrice.dollars') * 1 === this.get('deal.formatOldPrice.dollars') * 1 && this.get('deal.formatPrice.cents') * 1 === this.get('deal.formatOldPrice.cents') * 1;
    }.property('deal.formatPrice.dollars', 'deal.formatPrice.cents', 'deal.formatOldPrice.dollars', 'deal.formatOldPrice.cents')
  });
});