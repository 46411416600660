define('handandstone-ember/pods/components/load-content/content/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isLoading:loading'],
    attributeBindings: ['aria-hidden'],
    'aria-hidden': function () {
      return this.get('isLoading');
    }.property('isLoading')
  });
});