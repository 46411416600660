define('handandstone-ember/pods/components/booking/book-now/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    'booking-store': Ember.inject.service(),
    modal: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    location: Ember.inject.service(),
    helper: Ember.inject.service(),
    'external-booking': Ember.inject.service(),
    is_hot_deal: false,
    //1 : Introductory price
    //3 : non-Member price
    //5 : Member price
    user_type: 3,
    title: 'Book now',
    serviceModel: null,
    classModel: null,
    continueTitle: null,
    tagName: 'a',
    serviceCode: '',
    serviceId: '',
    externalService: null,
    employeeId: null,
    date: '',
    time: '',
    attributeBindings: ['href', 'aria-describedby'],
    href: '',
    classType: undefined,
    duration_time: null,
    bookingObject: null,
    isDisplayAuthBlock: true,
    locationId: null,
    isSetDefault: false,
    isReorderIntro: false,
    isReorder: false,
    isRedirectToHotDeal: false,
    isAllowedClick: true,

    externalBooking: function externalBooking(location) {
      if (!this.get('is_hot_deal')) {
        return this.get('external-booking').process(location, this.get('serviceModel'), this.get('externalService'));
      }

      return false;
    },

    isContinue: function () {
      var currentLocationId = parseInt(this.get('location.currentLocation.id')),
          locationId = this.get('locationId'),
          bookingObjectLocationId = this.get('bookingObject.location_id.length') ? parseInt(this.get('bookingObject.location_id')) : null,
          localBookingLocationId = this.get('booking-store.booking.model.location_id') ? parseInt(this.get('booking-store.booking.model.location_id')) : null;

      if (locationId != null) {
        if (locationId != currentLocationId) {
          return false;
        }
      }

      return (currentLocationId === localBookingLocationId || currentLocationId === bookingObjectLocationId) && this.get('continueTitle');
    }.property('location.currentLocation.id', 'booking-store.booking.model.location_id', 'continueTitle', 'bookingObject', 'locationId'),

    resultTitle: function () {
      return this.get('isContinue') ? this.get('continueTitle') : this.get('title');
    }.property('title', 'continueTitle', 'isContinue'),

    click: function click(event) {
      var _this = this;

      event.preventDefault();

      //Amazon Advertising Tag
      if (this.get('classModel') || this.get('serviceModel')) {
        if (this.get('location.currentRoute') === 'class.neveskin') {
          amzn("trackEvent", "Lead_IHM 317 Hand and Stone Cryo Book Now");
        } else if (this.get('location.currentRoute') === 'class.facial') {
          amzn("trackEvent", "Lead_IHM 317 Hand and Stone Facial Book Now");
        }
      }

      if (this.get('locationId') && this.get('location.currentRoute') === 'locations') {
        amzn("trackEvent", "Lead_IHM 317 Hand and Stone Locations Book Now");
      }

      if (this.get('isAllowedClick')) {
        this.toggleProperty('isAllowedClick');

        var locationId = this.get('locationId');

        if (locationId != null && this.get('isSetDefault')) {
          this.get('location').setCurrentLocationById(locationId).then(function () {
            if (!_this.isDestroyed) {
              _this.bookNowBehavior();
              _this.toggleProperty('isAllowedClick');
            }
          });
        } else {
          this.bookNowBehavior();
          this.toggleProperty('isAllowedClick');
        }
      }
    },

    bookNowBehavior: function bookNowBehavior() {
      var _this2 = this;

      if (this.get('location.currentLocation')) {
        if (this.get('location.currentLocation.isBookable')) {
          this.bookNow();
        } else {
          if (!this.externalBooking(this.get('location.currentLocation'))) {
            this.get('modal').open('location-nonbookable');
          }
        }
      } else {
        var defer = Ember.RSVP.defer(),
            self = this;

        defer.promise.then(function () {
          if (!_this2.isDestroyed) {
            self.bookNow();
          }
        });
        this.sendAction('modalAction', 'locations/locations-popup', defer);
      }
    },


    clearStoreModel: function clearStoreModel() {
      this.get('booking-store').clear();
    },

    deleteModels: function deleteModels() {
      this.get('store').unloadAll('booking');
    },

    setNewModel: function setNewModel() {
      this.set('booking-store.booking', {
        model: {
          location_id: this.get('locationId') || this.get('location.currentLocation.id'),
          type_id: this.get('classType'),
          service_code: this.get('serviceCode'),
          service_id: this.get('serviceId'),
          employee_id: this.get('employeeId'),
          specific_employee_id: this.get('employeeId'),
          date: this.get('date'),
          time: this.get('time'),
          hot_deal: this.get('is_hot_deal') ? 1 : 0,
          user_type: this.get('user_type'),
          isReorderIntro: this.get('isReorderIntro'),
          isReorder: this.get('isReorder')
        }
      });

      if (this.get('servicePrice')) {
        this.set('booking-store.booking.model.service_price', this.get('servicePrice'));
      }

      this.setProperties({
        'booking-store.booking.duration_time': this.get('duration_time'),
        'booking-store.booking.step': this.getCurrentStep(),
        'booking-store.booking.isDisplayAuthBlock': this.get('isDisplayAuthBlock')
      });

      this.get('booking-store').write();
    },

    getCurrentStep: function getCurrentStep() {
      if (this.get('is_hot_deal')) {
        return 'third';
      }

      if (this.get('user_type') !== this.get('helper.user_types.introductory')) {
        if (this.get('location.currentLocation.id') && this.get('classType') && this.get('serviceCode') && this.get('serviceId')) {
          return 'second';
        }
      }

      return 'first';
    },

    bookNow: function bookNow() {
      var _this3 = this;

      if (!this.get('isContinue')) {
        this.clearStoreModel();
        this.deleteModels();
        this.setNewModel();
      }

      if (this.get('isRedirectToHotDeal')) {
        this.get('router').transitionTo('hot-deal');
      } else {

        var locationId = this.get("locationId");

        if (locationId != null) {
          this.get('location').getLocationById(locationId).then(function (location) {
            _this3.transitionToBooking(location);
          });
        } else {
          this.transitionToBooking(this.get('location.currentLocation'));
        }
      }
    },

    transitionToBooking: function transitionToBooking(location) {
      if (!this.externalBooking(location)) {
        this.get('router').transitionTo('booking', [location.get('slug')], { isFirstTimeVisitorMode: true });
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('bookingObject', this.get('store').peekAll('booking').get('firstObject'));
    }
  });
});