define('handandstone-ember/pods/components/first-visit/block-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item'],
    isLeft: function () {
      return this.get('index') % 2 === 0;
    }.property('index')
  });
});