define('handandstone-ember/pods/components/modal-booking-errors/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    modal: Ember.inject.service(),
    routerService: Ember.inject.service('-routing'),

    attributeBindings: ['aria-describedby'],
    'aria-describedby-base': 'booking-error-describedby',
    'aria-describedby': function () {
      var _this = this;

      var result = false;

      if (this.get('customData.errors')) {
        this.get('customData.errors').forEach(function (element, index) {
          if (result) {
            result = result + " ";
          }
          result = result + _this.get('aria-describedby-base') + index;
        });
      }

      return result;
    }.property('customData.errors.@each.message', 'aria-describedby-base'),

    tabIndex: function () {
      return this.get('customData.errors') && this.get('customData.errors').length > 1 ? '-1' : false;
    }.property('customData.errors.@each.message'),

    customData: function () {
      return this.get('model.options.model');
    }.property('model.options.model'),

    actions: {
      closeModal: function closeModal() {
        this.get('modal').close();
      },
      closeModalHotDeal: function closeModalHotDeal() {
        this.get('routerService').transitionTo('hot-deal');
        this.get('modal').close();
      }
    }
  });
});