define('handandstone-ember/pods/components/booking/gift-card/component', ['exports', 'ember-cp-validations'], function (exports, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    codeValidate: [(0, _emberCpValidations.validator)('length', {
      allowBlank: false,
      min: 3,
      max: 12
    }), (0, _emberCpValidations.validator)('format', {
      allowBlank: false,
      regex: /^((PR){0,1}(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])[\d]{6,8})|([\d]{6})|(YES)$/,
      message: 'Invalidate code'
    })]
  });

  exports.default = Ember.Component.extend(Validations, {
    classNames: ['promo-code'],
    attributeBindings: ['style'],

    model: null,
    code: null,
    codeValidate: null,
    showError: false,
    hintId: 'promo-code-hint',
    hintErrorId: 'promo-code-error-hint',

    style: function () {
      if (this.get("model.isHotDeal")) {
        this.eraseModelCode();
      }

      if (this.get("model.isHotDeal")) {
        return 'display:none;';
      }
    }.property('model.{isHotDeal}'),

    isCodeApplied: function () {
      return Boolean(this.get('code') && this.get('model.gift_card') === this.get('code'));
    }.property('model.gift_card,code'),

    inputCodeClass: function () {
      var result = "form-control input-uppercase";
      if (this.get("showError") && this.get('validations.attrs.codeValidate.isInvalid')) {
        result += " error";
      }
      return result;
    }.property('showError', 'validations.attrs.codeValidate.isInvalid'),

    tabIndex: function () {
      return this.get('isCodeApplied') && this.get('showError') ? '-1' : null;
    }.property('isCodeApplied', 'showError'),

    describedbyIds: function () {
      var result = [];

      if (this.get('isCodeApplied')) {
        result.push(this.get('hintId'));
      }

      if (this.get('showError')) {
        result.push(this.get('hintErrorId'));
      }

      return result.length ? result.join(' ') : null;
    }.property('isCodeApplied', 'showError'),

    checkCodeChange: function () {
      this.eraseModelCode();
      this.set('codeValidate', this.get('code').toUpperCase());
    }.observes('code'),

    eraseModelCode: function eraseModelCode() {
      this.set("model.gift_card", null);
    },


    actions: {
      apply: function apply() {
        var _this = this;

        this.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            _this.setProperties({
              'model.gift_card': _this.get('code'),
              showError: false
            });
          } else {
            _this.set('showError', true);
          }
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      var giftCard = this.get('model.gift_card');

      this.setProperties({
        code: giftCard,
        codeValidate: giftCard
      });
    }
  });
});