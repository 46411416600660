define('handandstone-ember/pods/components/homepage/sub-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['additional-info-box'],
    classNameBindings: ['blueMode:blue'],
    tagName: 'div',

    blueMode: false
  });
});