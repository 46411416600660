define('handandstone-ember/pods/booking/controller', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),
    headTagsService: Ember.inject.service('head-tags'),
    seo: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    employeeTimeService: Ember.inject.service('employee-time'),
    location: Ember.inject.service(),
    'booking-store': Ember.inject.service(),

    isDisplayAuthBlock: function () {
      return Boolean(this.get('model.isDisplayAuthBlock') && !this.get('account.model.email') && !this.get('model.isHotDeal'));
    }.property('model.isDisplayAuthBlock', 'account.model.email', 'model.isHotDeal'),

    pageTextForTitle: function () {
      if (this.get('model.location.slug')) {
        var result = [{
          type: 'link',
          attrs: {
            rel: 'canonical',
            href: _environment.default.APP.CURRENT_URl + '/locations/' + this.get('model.location.slug')
          }
        }];

        this.set('seo.extendedMeta', result);
        this.get('headTagsService').collectHeadTags();
      }
    }.observes('model.location.slug', 'router.currentRouteName'),

    checkIsHasEnabledInterval: function checkIsHasEnabledInterval(employeesDataArray) {
      var isHasEnabledInterval = false;

      for (var i = 0; i < employeesDataArray.length; i++) {
        if (employeesDataArray[i].amountEnabledIntervals) {
          isHasEnabledInterval = true;
          break;
        }
      }

      return isHasEnabledInterval;
    },
    getFilteredEmployesForLocationId: function getFilteredEmployesForLocationId(employeesDataArray) {
      var franchiseeBuffer = this.get('booking-store').readFranchiseeBuffer(),
          result = {
        isShowCurrentLocationEmployees: false,
        isHiddenAllEmployees: !this.checkIsHasEnabledInterval(employeesDataArray),
        currentLocationId: franchiseeBuffer.isFranchisee ? franchiseeBuffer.data.locationId : this.get("model.location_id"),
        currentLocationEmployees: [],
        franchiseLocationEmployees: []
      },
          obj = {};

      employeesDataArray.forEach(function (item) {
        var location_id = item.employee.get('location_id');

        if (!obj.hasOwnProperty(location_id)) {
          obj[location_id] = [];
        }

        obj[location_id].push(item);
      });

      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          if (prop == result.currentLocationId) {
            result.currentLocationEmployees = obj[prop];
          } else {
            var employeesArray = obj[prop];

            result.franchiseLocationEmployees.push({
              locationAddress: employeesArray[0].employee.get("location_address"),
              locationDistance: employeesArray[0].employee.get("distance"),
              locationName: employeesArray[0].employee.get("location_name"),
              locationId: employeesArray[0].employee.get("location_id"),
              array: employeesArray,
              isHasEnabledInterval: this.checkIsHasEnabledInterval(employeesArray)
            });
          }
        }
      }

      if (!result.isHiddenAllEmployees) {
        result.isShowCurrentLocationEmployees = this.checkIsHasEnabledInterval(result.currentLocationEmployees);
      }

      return result;
    },


    selectedEmployeesFilteredObserves: function () {
      var _this = this;

      var franchiseeBuffer = this.get('booking-store').readFranchiseeBuffer(),
          selectedEmployees = this.get('model.selectedEmployees'),
          currentLocationId = franchiseeBuffer.isFranchisee ? franchiseeBuffer.data.locationId : this.get("model.location_id");

      if (selectedEmployees) {
        selectedEmployees.then(function (res) {
          var employees = res.toArray(),
              result = [];

          employees.forEach(function (item) {
            var timeIntervalsParams = _this.getTimeIntervalsParams(item);

            item.set("isFranchise", !(item.get("location_id") == currentLocationId));
            result.pushObject({
              employee: item,
              timeIntervals: timeIntervalsParams.intervals,
              amountEnabledIntervals: timeIntervalsParams.amountEnabledIntervals,
              initialSlide: timeIntervalsParams.initialSlide
            });
          });

          result.sort(function (a, b) {
            return b.amountEnabledIntervals - a.amountEnabledIntervals;
          });

          var filteredEmployesForLocationId = _this.getFilteredEmployesForLocationId(result);

          _this.set('selectedEmployeesFiltered', _this.setTimeSliderIndexToEmployeesFiltered(filteredEmployesForLocationId));
        });
      }
    }.observes('model.selectedEmployees', 'model.time', 'model.duration_time', 'model.employee.id', 'model.selectedEmployee'),

    setTimeSliderIndexToEmployeesFiltered: function setTimeSliderIndexToEmployeesFiltered(filteredEmployesForLocationId) {
      var timeSliderIndex = 0,
          curLocEmployees = filteredEmployesForLocationId.currentLocationEmployees,
          franchiseEmployees = filteredEmployesForLocationId.franchiseLocationEmployees;

      if (curLocEmployees.length) {
        curLocEmployees.forEach(function (item) {
          item.timeSliderIndex = timeSliderIndex;
          timeSliderIndex++;
        });
      }

      if (franchiseEmployees.length) {
        timeSliderIndex = 0;
        franchiseEmployees.forEach(function (franchiseLocationItem) {
          franchiseLocationItem.array.forEach(function (item) {
            item.timeSliderIndex = timeSliderIndex;
            timeSliderIndex++;
          });
        });
      }

      return filteredEmployesForLocationId;
    },
    getTimeIntervalsParams: function getTimeIntervalsParams(employee) {
      var format = 'HH:mm';

      var intervals = [],
          amountEnabledIntervals = 0,
          isBreak = false,
          timeStep = employee.get('interval') || 15,
          initialSlide = 0;

      for (var currentTime = moment(employee.get('start_time'), format); currentTime.format(format) < moment(employee.get('end_time'), format).format(format); currentTime.add(timeStep, 'minutes')) {

        //The next code is for cases currentTime + timeStep is overflow 24 hours day. In this case it will be endless loop
        if (isBreak) {
          break;
        }

        var nextStepTime = moment(currentTime.format(format), format);
        nextStepTime.add(timeStep, 'minutes');

        if (currentTime.format(format) > nextStepTime.format(format)) {
          isBreak = true;
        }

        var itemClass = this.get('employeeTimeService').getItemClass(currentTime.format(format), this.get('model.time'), employee, this.get('model.duration_time')),
            isDisabledTime = itemClass == 'disabled';

        if (!isDisabledTime) {
          amountEnabledIntervals++;
        }

        intervals.push({
          value: currentTime.format(format),
          title: currentTime.format('hh:mm'),
          class: itemClass,
          availability: isDisabledTime ? 'disabled' : 'enabled',
          dayPart: currentTime.format('A')
        });

        var len = intervals.length - 1,
            isCanBeSelected = this.get('employeeTimeService').getIsCanBeSelected(employee, this.get('model.employee.id'));

        if (intervals[len].class == 'active' && (!isCanBeSelected || Boolean(this.get('model.selectedEmployee')))) {
          initialSlide = len;
        }
      }

      return {
        intervals: intervals,
        amountEnabledIntervals: amountEnabledIntervals,
        initialSlide: initialSlide
      };
    }
  });
});