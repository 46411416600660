define('handandstone-ember/pods/booking/cancel/adapter', ['exports', 'handandstone-ember/pods/application/adapter', 'ember-inflector'], function (exports, _adapter, _emberInflector) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    pathForType: function pathForType(type) {
      var dasherized = Ember.String.dasherize(type);
      return dasherized;
    },
    createRecord: function createRecord(store, type, record) {
      var id = record._attributes.booking_id;
      var data = {};
      var serializer = store.serializerFor(record.modelName);
      serializer.serializeIntoHash(data, type, record);

      var parts = record.modelName.split('/');
      var modelName = (0, _emberInflector.pluralize)(parts[0]) + '/' + id + '/' + parts[1];

      return this.ajax(this.buildURL(modelName), "POST", { data: data });
    }
  });
});