define('handandstone-ember/pods/components/locations/popup-items-locations/popup-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['item'],
    titleLength: 35,
    addressLength: 40,

    concatTitle: Ember.computed('location.city', 'location.state', function () {
      return this.get('location.city') + ', ' + this.get('location.state');
    }),

    concatAddress: Ember.computed('location.address', 'location.city', 'location.state', 'location.zip', function () {
      return this.get('location.address') + ', ' + this.get('location.city') + ', ' + this.get('location.state') + ' ' + this.get('location.zip');
    }),

    isEqualLocations: function () {
      return this.get('location.id') === this.get('locationService.locationId');
    }.property('location.id', 'locationService.locationId'),

    actions: {
      selectLocation: function selectLocation(locationId) {
        this.sendAction('selectLocation', locationId);
      }
    }
  });
});