define('handandstone-ember/pods/result-classification/route', ['exports', 'handandstone-ember/mixins/seo-module-route', 'handandstone-ember/utils/classification'], function (exports, _seoModuleRoute, _classification) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    model: function model(params) {
      var _this = this;

      return this.get('store').findRecord('class', _classification.default.getClassAbbrByName(params.name)).then(function (classification) {
        return classification.get('result-classification').then(function (resultClassification) {
          resultClassification.set('classification', classification);
          return resultClassification;
        });
      }).catch(function (error) {
        _this.send('error', error);
      });
    }
  });
});