define('handandstone-ember/pods/components/account/dashboard/account-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'cell',
    account: Ember.inject.service('account')
  });
});