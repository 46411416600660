define('handandstone-ember/pods/components/not-found-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'container',

    services: function () {
      return [{
        name: 'Trigger Point',
        image: '/slice/dist/images/svg/helps-manage-migraines.svg',
        profit: 'Helps manage migraines',
        link: 'class.massage'
      }, {
        name: 'Hot Stone Massage',
        image: '/slice/dist/images/svg/soothes-aches-heat.svg',
        profit: 'Soothes aches with heat',
        link: 'class.massage'
      }, {
        name: 'Sports Massage',
        image: '/slice/dist/images/svg/reduced-post-workout.svg',
        profit: 'Reduced post workout recovery time',
        link: 'class.massage'
      }, {
        name: 'Deep Tissue Massage',
        image: '/slice/dist/images/svg/relieves-muscular-spasms.svg',
        profit: 'Relieves muscular spasms',
        link: 'class.massage'
      }, {
        name: 'Swedish Massage',
        image: '/slice/dist/images/svg/promotes-relaxation.svg',
        profit: 'Promotes relaxation',
        link: 'class.massage'
      }, {
        name: 'Pre-Natal',
        image: '/slice/dist/images/svg/fewer-muscle-spasms.svg',
        profit: 'Fewer muscle spasms',
        link: 'class.massage'
      }];
    }.property()
  });
});