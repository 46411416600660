define('handandstone-ember/mixins/modal-component-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    scrollTo: Ember.inject.service('scroll-to'),
    focus: Ember.inject.service('focus'),
    attributeBindings: ['role', 'aria-labelledby'],
    role: 'dialog',
    'aria-labelledby': 'modal-header-id',

    didInsertElement: function didInsertElement() {
      this.get('scrollTo').savePageOffset();
      this.get('focus').keepFocus(document.getElementById(this.elementId));
      this.set('focus.isMainAriaHidden', true);
      this._super.apply(this, arguments);
    },
    didDestroyElement: function didDestroyElement() {
      this.get('scrollTo').applyPageOffset();
      this.get('focus').restoreSaveFocusContext();
      this.set('focus.isMainAriaHidden', false);
      this.get('focus').deleteModalFocusListener();
      this._super.apply(this, arguments);
    }
  });
});