define('handandstone-ember/pods/components/header-main/sub-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['main-nav'],
    tagName: 'ul',
    expandedItemId: null,
    expandedItemIdOnHover: null,
    store: Ember.inject.service(),
    routerService: Ember.inject.service('-routing'),
    location: Ember.inject.service(),
    'dynamic-menu': Ember.inject.service(),
    locationClassification: null,

    addActiveClass: Ember.computed('routerService.currentState', function () {
      var _this = this;
      setTimeout(function () {
        _this.get('routerService');
        var header = $('.header-global'),
            mainNav = $('.main-nav');
        if (header.hasClass('menu-open')) {
          header.find('.mobile-menu').trigger('click');
        }
        mainNav.find('.current').removeClass('current');
        mainNav.find('a.active').addClass('current').parents('li').addClass('current');
      }, 1);
    }),

    didRender: function didRender() {
      this.get('addActiveClass');
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.buildMenus();
      this.get('routerService').addObserver("currentState", this, "closeSubItems");
    },
    closeSubItems: function closeSubItems() {
      this.setProperties({
        expandedItemId: null,
        expandedItemIdOnHover: null
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.get('routerService').removeObserver("currentState", this, "closeSubItems");
    },


    currentLocationObserver: Ember.observer('location.currentLocation', function () {
      var _this2 = this;

      if (this.get('location').get('currentLocation')) {
        this.get('location').get('currentLocation').get('classifications').then(function (resolve) {
          _this2.set('locationClassification', resolve);
        });
      }
    }),

    computedMenus: Ember.computed('locationClassification.@each.id', 'menu', 'location.currentLocation', function () {
      var currentMenu = Object.assign([], this.get('menu'));
      if (this.get('location').get('currentLocation') && this.get('locationClassification')) {
        var enableClasses = this.get('locationClassification').get('currentState').map(function (item) {
          return item.id;
        });
        var disabledClasses = currentMenu.map(function (item) {
          return item.abbr;
        }).filter(function (item) {
          return item;
        }).filter(function (item) {
          return !enableClasses.includes(item);
        });
        disabledClasses.forEach(function (item) {
          var index = currentMenu.findIndex(function (el) {
            return el.abbr === item;
          });
          if (index !== -1) {
            currentMenu.splice(index, 1);
          }
        });
      }
      return currentMenu;
    }),

    buildMenus: function buildMenus() {
      var self = this;
      self.get('store').findAll('menu').then(function (result) {
        var menu = { 'massage': {}, 'facial': {}, 'neveskin': {} };
        result.forEach(function (item) {
          switch (item.get('type')) {
            case 'FI':
              menu.facial = item.get('menu');
              break;
            case 'MA':
              menu.massage = item.get('menu');
              break;
            case 'NS':
              menu.neveskin = item.get('menu');
              break;
          }
        });

        if (!self.isDestroyed) {
          self.set('menu', [{
            title: 'Locations',
            route: 'locations'
          }, {
            title: 'Massage',
            route: 'class.massage',
            subItems: menu.massage,
            abbr: 'MA'
          }, {
            title: 'Facials',
            route: 'class.facial',
            subItems: menu.facial,
            abbr: 'FI'
          }, {
            title: 'Neveskin',
            route: 'class.neveskin',
            subItems: menu.neveskin,
            abbr: 'NS'
          }, {
            title: 'Hair Removal',
            route: 'class.hair-removal',
            abbr: 'HR'
          }, {
            title: 'Spa Deals',
            route: 'hot-deal',
            hasAction: true
          }, {
            title: 'First Visit',
            route: 'first-visit'
          }, {
            title: 'Membership',
            route: 'membership'
          }]);
        }
      });
    },

    transition: function transition(url) {
      this.get('routerService').transitionTo(url);
    },

    replaceUrl: function replaceUrl(url) {
      window.location.href = url;
    },


    actions: {
      goToUrl: function goToUrl(url, isExternalLink) {
        if (isExternalLink) {
          this.replaceUrl(url);
        } else {
          var defer = Ember.RSVP.defer(),
              self = this;

          defer.promise.then(function () {
            if (self.get('location.selectedLocation.isAllowedExternalSpaDealsLink')) {
              self.replaceUrl(self.get('location.selectedLocation.external_spa_deals_link'));
            } else {
              self.transition(url);
            }
          });

          this.sendAction('locationsPopUp', defer);
        }
      }
    }
  });
});