define('handandstone-ember/services/booking-store', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cookies: Ember.inject.service(),
    key: 'booking',
    keyFranchiseeBuffer: 'bookingFranchiseeBuffer',

    writeFranchiseeBuffer: function writeFranchiseeBuffer(obj) {
      this.get('cookies').write(this.get('keyFranchiseeBuffer'), JSON.stringify(obj), { 'maxAge': _environment.default.cookiesMaxAge, path: '/' });
    },
    readFranchiseeBuffer: function readFranchiseeBuffer() {
      var isHasRecord = false,
          data = this.get('cookies').read(this.get('keyFranchiseeBuffer')) || null;

      if (data) {
        data = JSON.parse(data);
        isHasRecord = true;
      }

      return {
        data: data,
        isHasRecord: isHasRecord,
        isFranchisee: isHasRecord ? data.isFranchisee : false
      };
    },
    clearFranchiseeBuffer: function clearFranchiseeBuffer() {
      this.get('cookies').clear(this.get('keyFranchiseeBuffer'), { path: '/' });
    },
    clear: function clear() {
      this.set(this.get('key'), null);
      this.get('cookies').clear(this.get('key'), { path: '/' });
      this.clearFranchiseeBuffer();
    },
    write: function write() {
      return this.get('cookies').write(this.get('key'), JSON.stringify(this.get(this.get('key'))), { 'maxAge': _environment.default.cookiesMaxAge, path: '/' });
    },
    read: function read() {
      var result = this.get('cookies').read(this.get('key'));

      if (result) {
        var parsedData = null;
        try {
          parsedData = JSON.parse(result);

          var buffer = this.readFranchiseeBuffer();

          if (buffer.isFranchisee) {
            parsedData.model.is_franchisee = true;
            parsedData.model.location_id = buffer.data.locationId;
            parsedData.model.service_id = buffer.data.serviceId;
            parsedData.model.employee_id = buffer.data.employeeId;
          }
        } catch (error) {
          parsedData = null;
        }

        this.set(this.get('key'), parsedData);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.read();
    }
  });
});