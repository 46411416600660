define('handandstone-ember/pods/about-us/press/controller', ['exports', 'handandstone-ember/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    headTagsService: Ember.inject.service('head-tags'),
    router: Ember.inject.service('-routing'),
    seo: Ember.inject.service('seo'),

    queryParams: ['page', 'per-page'],
    page: 1,
    'per-page': 6,

    pageTextForTitle: function () {
      var text = this.get('page') && this.get('page') > 1 ? ' Page ' + this.get('page') : '';

      this.updateDescriptionTag(text);
      return text;
    }.property('page', 'model.posts.meta.{currentPage,pageCount}'),

    updateDescriptionTag: function updateDescriptionTag(pageText) {
      var result = [{
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: _environment.default.APP.CURRENT_URl + '/about/press' + (pageText ? '?page=' + this.get('page') : '')
        }
      }];

      if (this.get('model.posts.meta.pageCount') > 1) {
        if (this.get('model.posts.meta.currentPage') > 1) {
          result.push({
            type: 'link',
            attrs: {
              rel: 'prev',
              href: _environment.default.APP.CURRENT_URl + '/about/press=' + (this.get('model.posts.meta.currentPage') - 1)
            }
          });
        }

        if (this.get('model.posts.meta.pageCount') > this.get('model.posts.meta.currentPage')) {
          result.push({
            type: 'link',
            attrs: {
              rel: 'next',
              href: _environment.default.APP.CURRENT_URl + '/about/press?page=' + (this.get('model.posts.meta.currentPage') + 1)
            }
          });
        }
      }

      this.set('seo.extendedMeta', result);
      //TODO: double call
      // this.get('headTagsService').collectHeadTags();
    }
  });
});