define('handandstone-ember/pods/components/simple-menu/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: [''],
    tagName: 'ul',
    menuItems: [],
    itemMenuWrapper: "li",
    itemHasLinkText: true,

    itemMenuWrapperOpen: function () {
      if (this.get('itemMenuWrapper').length === 0) return '';
      return "<" + this.get('itemMenuWrapper') + ">";
    }.property('itemMenuWrapper'),

    itemMenuWrapperClose: function () {
      if (this.get('itemMenuWrapper').length === 0) return '';
      return "</" + this.get('itemMenuWrapper') + ">";
    }.property('itemMenuWrapper')
  });
});