define('handandstone-ember/pods/hot-deal/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['location_id'],
    location_id: null,
    "text-fields": Ember.inject.service()
  });
});