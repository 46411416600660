define('handandstone-ember/pods/membership/adapter', ['exports', 'handandstone-ember/pods/application/adapter'], function (exports, _adapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _adapter.default.extend({
    pathForType: function pathForType() {
      return 'membership';
    }
  });
});