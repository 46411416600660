define('handandstone-ember/pods/featured/single/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_seoModuleRoute.default, {
        model: function model(params) {
            return this.get('store').findRecord('featured', params.slug);
        }
    });
});