define('handandstone-ember/pods/homepage/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    modal: Ember.inject.service(),

    afterModel: function afterModel(posts, transition) {
      if (transition.queryParams['reset-password']) {
        this.changePassToken(transition.queryParams['reset-password'].replace(' ', '+'));
      }

      if (transition.queryParams['change-email-token']) {
        this.changeEmailToken(transition.queryParams['change-email-token'].replace(' ', '+'));
      }
      this._super.apply(this, arguments);

      //Amazon Advertising Tag
      amzn("trackEvent", "PageView_IHM 317 Hand and Stone Homepage");
    },

    model: function model() {
      return this.get('store').findRecord('homepage', 'homepage', { reload: true });
    },
    changePassToken: function changePassToken(token) {
      this.get('modal').close();
      this.get('modal').open('forgot-new-pass', { token: token });
    },
    changeEmailToken: function changeEmailToken(token) {
      var _this = this;

      var model = this.get('store').createRecord('account/email-token', { id: token });

      model.save().then(function () {
        _this.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'You email was changed' } });
      }).catch(function () {
        _this.get('modal').open('small-popup', { model: { success: false, title: 'Error!', message: 'Invalid token' } });
      });

      return model;
    }
  });
});