define('handandstone-ember/utils/classification', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var Classification = function () {
    function Classification() {
      _classCallCheck(this, Classification);
    }

    _createClass(Classification, null, [{
      key: 'getClassAbbrByName',
      value: function getClassAbbrByName(className) {
        switch (className) {
          case 'neveskin':
            return "NS";
          case 'facial':
            return "FI";
          case 'massage':
            return "MA";
          case 'hair-removal':
            return "HR";
          default:
            return className;
        }
      }
    }, {
      key: 'getClassNameByAbbr',
      value: function getClassNameByAbbr(abbr) {
        switch (abbr) {
          case "NS":
            return "neveskin";
          case "FI":
            return "facial";
          case "MA":
            return "massage";
          case "HR":
            return "hair-removal";
          default:
            return abbr;
        }
      }
    }]);

    return Classification;
  }();

  exports.default = Classification;
});