define('handandstone-ember/pods/class/facial/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    model: function model() {
      return this.get('store').findRecord('class', 'FI');
    },
    afterModel: function afterModel() {
      //Amazon Advertising Tag
      amzn("trackEvent", "PageView_IHM 317 Hand and Stone Facial");
    }
  });
});