define('handandstone-ember/pods/featured/model', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        slug: _emberData.default.attr('string'),
        title: _emberData.default.attr('string'),
        description: _emberData.default.attr('string'),
        short_description: _emberData.default.attr('string'),
        image: _emberData.default.attr('string'),
        large_image: _emberData.default.attr('string')
    });
});