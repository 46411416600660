define('handandstone-ember/pods/blog/post-list/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    queryParams: {
      page: {
        refreshModel: true
      },
      tag: {
        refreshModel: true
      },
      category: {
        refreshModel: true
      }
    },

    model: function model(params) {
      return Ember.RSVP.hash({
        categories: this.get('store').findAll('blog/category', { backgroundReload: false }),
        tags: this.get('store').findAll('blog/tag', { backgroundReload: false }),
        posts: this.get('store').query('blog/post', params)
      });
    }
  });
});