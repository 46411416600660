define('handandstone-ember/pods/components/booking/cancel-button/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modal: Ember.inject.service(),
    store: Ember.inject.service(),

    title: 'Cancel',
    tagName: 'a',
    classNames: ['link'],
    model: null,
    attributeBindings: ['href', 'style'],
    href: '',
    isProcessing: false,

    style: function () {
      if (this.get('model.allow_cancellation') && this.get('model.status') === 32) {
        return '';
      } else {
        return 'display:none;';
      }
    }.property('model.allow_cancellation', 'model.status'),

    click: function click(event) {
      var _this = this;

      event.preventDefault();
      if (!this.get('isProcessing')) {
        this.set('isProcessing', true);

        this.get('modal').open('confirm', {
          model: {
            title: 'Would you like to cancel the appointment?',
            actionConfirm: function actionConfirm() {
              var cancellation = _this.get('store').createRecord('booking/cancel');
              cancellation.set('booking_id', _this.get('model').get('id'));
              cancellation.save().then(function () {

                _this.get('modal').open('small-popup', {
                  model: {
                    success: true,
                    title: 'Congratulations!',
                    message: 'Your\'ve canceled the appointment'
                  }
                });
                _this.get('model').unloadRecord();
              }).catch(function () {
                _this.get('modal').open('small-popup', {
                  model: {
                    success: false,
                    title: 'Error!',
                    message: 'Undefined error while cancelling the appointment'
                  }
                });
              }).finally(function () {
                _this.set('isProcessing', false);
              });
            }
          }
        });
      }
    }
  });
});