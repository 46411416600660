define('handandstone-ember/pods/hot-deal/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    location: Ember.inject.service(),
    queryParams: {
      location_id: null
    },

    model: function model(params) {
      if (params.location_id) {
        this.get('location').setLocationId(params.location_id);
      }
    },


    actions: {
      error: function error(_error, transition) {
        console.log(_error, transition); //Never displayed
      }
    }
  });
});