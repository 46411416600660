define('handandstone-ember/pods/components/booking/appointment-calendar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['calendar'],
    attributeBindings: ['aria-hidden', 'aria-label', 'role'],
    role: 'application',
    'aria-label': 'choose a day',
    numberOfWeeks: 6,
    weeks: [],
    disabledDates: [],
    format: 'YYYYMMDD',
    selectedDate: null,
    viewDate: null,
    minDate: null,
    maxDate: null,
    tabId: 'prev',
    isOpen: false,

    'aria-hidden': function () {
      return (!this.get('isOpen')).toString();
    }.property('isOpen'),

    weekDays: function () {
      var result = [],
          weekdays = moment.weekdays();

      moment.weekdaysMin().forEach(function (item, i) {
        result.push({
          short: item,
          long: weekdays[i]
        });
      });

      return result;
    }.property(),

    isPrevMonthDisabled: Ember.computed('minDate', 'weeks', function () {
      var result = false,
          minDate = this.get('minDate');

      if (minDate) {
        var firstDateOfView = this.get('weeks')[0][0].dateString;

        result = firstDateOfView <= minDate;
      }

      return result;
    }),

    isNextMonthDisabled: Ember.computed('maxDate', 'weeks', function () {
      var result = false,
          maxDate = this.get('maxDate');

      if (maxDate) {
        var weeks = this.get('weeks'),
            lastWeekOfView = weeks[weeks.length - 1],
            lastDateOfView = lastWeekOfView[lastWeekOfView.length - 1].dateString;

        result = lastDateOfView >= maxDate;
      }

      return result;
    }),

    changeParams: function () {
      this.renderView();
    }.observes('viewDate'),

    changeIsOpen: function () {
      this.fixCalendarPosition();
    }.observes('isOpen'),

    renderView: function renderView(viewDate) {
      var result = [],
          format = this.get('format'),
          today = moment().format(format),
          curViewDate = viewDate || this.get('viewDate') || today,
          numberOfWeeks = this.get('numberOfWeeks'),
          disabledDates = this.get('disabledDates'),
          selectedDate = this.get('selectedDate') ? this.get('selectedDate').format(format) : null,
          minDate = this.get('minDate'),
          maxDate = this.get('maxDate'),
          weeks = 0,
          tabId = 0,
          date = moment(curViewDate, format),
          firstDateMonth = date.startOf('month'),
          firstDayMonth = firstDateMonth.day(),
          firstDayCalendarView = firstDayMonth === 0 ? firstDateMonth.clone() : firstDateMonth.clone().subtract(firstDayMonth, 'days');

      for (weeks; weeks < numberOfWeeks; weeks++) {
        result.push([]);

        for (var weeksDay = 0; weeksDay <= 6; weeksDay++) {
          var weekDay = firstDayCalendarView.format('dddd'),
              month = firstDayCalendarView.format('MMMM'),
              day = firstDayCalendarView.format('D'),
              year = firstDayCalendarView.format('YYYY'),
              itemDate = firstDayCalendarView.format(format),
              dateObj = {
            day: day,
            dateString: itemDate,
            dateMoment: firstDayCalendarView.clone(),
            year: year,
            month: month,
            weekDay: weekDay,
            isToday: itemDate == today,
            tabId: tabId,
            weekIndex: weeks,
            dayIndex: weeksDay,
            isDisabled: itemDate < minDate || itemDate > maxDate,
            isSelected: itemDate == selectedDate,
            label: weekDay + ', ' + month + ' ' + day + ', ' + year
          };

          if (disabledDates.length && itemDate >= disabledDates[0]) {
            for (var disabledDatesCounter = 0; disabledDatesCounter < disabledDates.length; disabledDatesCounter++) {
              if (disabledDates[disabledDatesCounter] == itemDate) {
                dateObj.isDisabled = true;
                break;
              }
            }
          }

          result[weeks].push(dateObj);
          firstDayCalendarView.add(1, 'day');
          tabId++;
        }
      }

      var prevMonthDate = firstDateMonth.clone().subtract(1, 'M'),
          nextMonthDate = firstDateMonth.clone().add(1, 'M');

      this.setProperties({
        year: firstDateMonth.format('YYYY'),
        month: firstDateMonth.format('MMMM'),
        weeks: result,
        currentViewMonthDate: firstDateMonth,
        lastTabId: tabId - 1,
        prevMonthDate: prevMonthDate,
        nextMonthDate: nextMonthDate,
        prevMonthDateLabel: prevMonthDate.format('MMMM YYYY'),
        nextMonthDateLabel: nextMonthDate.format('MMMM YYYY')
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.renderView();
    },
    didRender: function didRender() {
      var tabId = this.get('tabId');

      if (tabId !== null) {
        this.goToTabId(tabId);
      }
    },
    goToTabId: function goToTabId(id) {
      Ember.$('[data-tabid=' + id + ']').focus();
    },
    prev: function prev() {
      this.renderView(this.get('prevMonthDate').format(this.get('format')));
    },
    next: function next() {
      this.renderView(this.get('nextMonthDate').format(this.get('format')));
    },
    close: function close() {
      this.set('isOpen', false);
    },
    getCoords: function getCoords(elem) {
      // crossbrowser version
      var box = elem.getBoundingClientRect(),
          body = document.body,
          docEl = document.documentElement,
          scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop,
          scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft,
          clientTop = docEl.clientTop || body.clientTop || 0,
          clientLeft = docEl.clientLeft || body.clientLeft || 0,
          top = box.top + scrollTop - clientTop,
          left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
    },
    fixCalendarPosition: function fixCalendarPosition() {
      var id = 'calendar-spacer',
          calendar = document.getElementsByClassName('calendar')[0],
          content = document.getElementsByClassName('content-page')[0],
          spacer = document.getElementById(id) || document.createElement('div');

      if (this.get('isOpen')) {
        calendar.setAttribute('style', 'display:block;');

        var contentPositionBottom = parseInt(this.getCoords(content).top + content.offsetHeight),
            calendarPositionBottom = parseInt(this.getCoords(calendar).top + calendar.offsetHeight);

        if (calendarPositionBottom > contentPositionBottom) {
          spacer.setAttribute('style', 'height:' + (calendarPositionBottom - contentPositionBottom) + 'px;');
          spacer.setAttribute('id', id);
          spacer.setAttribute('aria-hidden', 'true');
          content.appendChild(spacer);
        }
      } else {
        calendar.setAttribute('style', 'display:none;');
        var spacerParent = spacer.parentNode;

        if (spacerParent) {
          spacerParent.removeChild(spacer);
        }
      }
    },


    handleKeydown: Ember.on('keyDown', function (e) {
      if (e.keyCode == 27) {
        this.close();
        var calendarParent = document.getElementById(this.get('elementId')).parentNode;
        calendarParent.getElementsByClassName('btn-calendar')[0].focus();
      }
    }),

    actions: {
      setDate: function setDate(dateObj) {
        var selectedDate = this.get('selectedDate') ? this.get('selectedDate').format(this.get('format')) : null;

        if (!dateObj.isDisabled && selectedDate != dateObj.dateString) {
          this.set('selectedDate', dateObj.dateMoment);
          this.renderView(dateObj.dateString);
          this.close();
        }
      },
      prevMonth: function prevMonth() {
        if (!this.get('isPrevMonthDisabled')) {
          this.set('tabId', 'prev');
          this.prev();
        }
      },
      nextMonth: function nextMonth() {
        if (!this.get('isNextMonthDisabled')) {
          this.set('tabId', 'next');
          this.next();
        }
      },
      navigateArrows: function navigateArrows(dateObj, e) {
        var numberOfWeeks = this.get('numberOfWeeks') - 1,
            isPrevMonthDisabled = this.get('isPrevMonthDisabled'),
            isNextMonthDisabled = this.get('isNextMonthDisabled'),
            isFirstWeekOfView = dateObj.weekIndex == 0,
            isFirstDateOfView = dateObj.tabId == 0,
            isLastDayOfWeek = dateObj.dayIndex == 6,
            isLastWeekOfView = dateObj.weekIndex == numberOfWeeks,
            isLastDateOfView = isLastDayOfWeek && isLastWeekOfView;

        switch (e.keyCode) {
          case 37:
            //arrow left
            if (isFirstDateOfView) {
              if (!isPrevMonthDisabled) {
                this.set('tabId', this.get('lastTabId'));
                this.prev();
              }
            } else {
              this.goToTabId(dateObj.tabId - 1);
            }
            break;
          case 38:
            //arrow up
            if (isFirstWeekOfView) {
              if (!isPrevMonthDisabled) {
                this.set('tabId', dateObj.tabId + 7 * numberOfWeeks);
                this.prev();
              }
            } else {
              this.goToTabId(dateObj.tabId - 7);
            }
            break;
          case 39:
            //arrow right
            if (isLastDateOfView) {
              if (!isNextMonthDisabled) {
                this.set('tabId', 0);
                this.next();
              }
            } else {
              this.goToTabId(dateObj.tabId + 1);
            }
            break;
          case 40:
            //arrow down
            if (isLastWeekOfView) {
              if (!isNextMonthDisabled) {
                this.set('tabId', dateObj.dayIndex);
                this.next();
              }
            } else {
              this.goToTabId(dateObj.tabId + 7);
            }

            break;
        }
      }
    }
  });
});