define('handandstone-ember/pods/components/header-main/book-appointment/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    actions: {
      locationsPopUp: function locationsPopUp(params) {
        this.sendAction('locationsPopUp', params);

        //Amazon Advertising Tag
        amzn("trackEvent", "Lead_IHM 317 Hand and Stone Homepage Book an Appointment");
      }
    }
  });
});