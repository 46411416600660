define('handandstone-ember/services/helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    user_types: {
      introductory: 1,
      non_member: 3,
      member: 5
    },

    priceFormatting: function priceFormatting(price) {
      var result = false;
      if (price) {
        var cents = Math.round(price * 100 % 100);
        if (cents.toString().length < 2) {
          cents = '0' + parseInt(cents).toString();
        } else {
          cents = parseInt(cents);
        }
        result = {
          dollars: parseInt(price),
          cents: cents
        };
      }

      return result;
    }
  });
});