define('handandstone-ember/pods/class/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    abbr: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    image_left: _emberData.default.attr('string'),
    image_right: _emberData.default.attr('string'),
    short_description: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    external_booking: _emberData.default.attr('string'),
    external_id: _emberData.default.attr('string'),
    youtube_url: _emberData.default.attr('string'),

    "result-classification": _emberData.default.belongsTo('result-classification', { async: true }),

    services: function () {
      return this.get('store').query('service', { 'class': this.get('abbr') });
    }.property(),

    isMassage: Ember.computed('abbr', function () {
      return this.get('abbr') === 'MA';
    }),

    isFacial: Ember.computed('abbr', function () {
      return this.get('abbr') === 'FI';
    }),

    isNeveskin: Ember.computed('abbr', function () {
      return this.get('abbr') === 'NS';
    })
  });
});