define('handandstone-ember/pods/components/homepage/first-time-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['first-time-block'],
    tagName: 'section'
  });
});