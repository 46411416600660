define('handandstone-ember/pods/components/homepage/main-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    helper: Ember.inject.service(),
    location: Ember.inject.service(),
    classNames: ['main-section'],
    classType: 'MA',

    currentLocation: Ember.computed('location.currentLocation', function () {
      return this.get('location.currentLocation');
    }),

    introSelectedLocation: function () {
      return this.get('currentLocation.introductory');
    }.property('currentLocation.introductory'),

    introSelectedLocationFacialOrMassage: function () {
      var result = null;

      if (this.get('introSelectedLocation')) {
        this.get('introSelectedLocation').forEach(function (service) {
          if (!service.get('isOneHourHSM') && (service.get('isMassage') || service.get('isFacial') || service.get('isNeveskin'))) {
            var servicePrice = service.get('price');

            if (servicePrice !== 0 && (result === null || result.get('price') > servicePrice)) {
              result = service;
            }
          }
        });
      }

      return result;
    }.property('introSelectedLocation'),

    introSelectedLocationHotStone: function () {
      var result = null;

      if (this.get('introSelectedLocation')) {
        this.get('introSelectedLocation').forEach(function (service) {
          if (service.get('isOneHourHSM')) {
            var servicePrice = service.get('price');

            if (servicePrice !== 0 && (result === null || result.get('price') > servicePrice)) {
              result = service;
            }
          }
        });
      }

      return result;
    }.property('introSelectedLocation'),

    introGlobalPrices: function () {
      var result = {},
          intro = this.get('model.intro_section');

      for (var index in intro) {
        if (intro.hasOwnProperty(index)) {
          result[index] = {
            formattedPrice: this.get('helper').priceFormatting(intro[index].price),
            type: this.get('helper').priceFormatting(intro[index].class)
          };
        }
      }
      return result;
    }.property('model.intro_section'),

    image: function () {
      return this.get('model.main_image');
    }.property('model.main_image'),

    imageLink: function () {
      return this.get('model.main_image_link');
    }.property('model.main_image_link'),

    isHolidayMode: function () {
      return this.get('model.holidays_mode');
    }.property('model.holidays_mode'),

    leftIntro: function () {
      return this.get('introSelectedLocationFacialOrMassage') || this.get('introGlobalPrices.left');
    }.property('introGlobalPrices', 'introSelectedLocationFacialOrMassage'),

    rightIntro: function () {
      return this.get('introSelectedLocationHotStone') || this.get('introGlobalPrices.right');
    }.property('introGlobalPrices', 'introSelectedLocationHotStone'),

    actions: {
      bookingAction: function bookingAction(template, deferFunction) {
        this.sendAction('bookingAction', template, deferFunction);
      }
    }
  });
});