define('handandstone-ember/pods/homepage/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    location: service(),
    "text-fields": service(),

    hasLMD: Ember.computed('location.currentLocation.{isShowingLMD,external_booking}', function () {
      return this.get('location.currentLocation.isShowingLMD') && !this.get('location.currentLocation.external_booking');
    })
  });
});