define('handandstone-ember/pods/components/pagination-regular/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    baseRoute: null,
    classNames: ['pagination'],
    scrollTo: Ember.inject.service('scroll-to'),
    classNameBindings: ['showPagination:has-items'],
    attributeBindings: ['role'],
    role: 'navigation',
    nextPage: 1,
    prevPage: 1,
    pageMarginCount: 1,
    scrollToSelector: '.dashboard',

    showPagination: Ember.computed('model.locationsMeta.pageCount', function () {
      return this.get('model.locationsMeta.pageCount') > 1;
    }),

    showPrevious: Ember.computed('model.locationsMeta.currentPage', function () {
      if (this.get('model.meta.currentPage') > 1) {
        this.set('prevPage', this.get('model.locationsMeta.currentPage') - 1);
        return true;
      }
      return false;
    }),

    showNext: Ember.computed('model.locationsMeta.currentPage', function () {
      if (this.get('model.locationsMeta.currentPage') < this.get('model.locationsMeta.pageCount')) {
        this.set('nextPage', this.get('model.locationsMeta.currentPage') + 1);
        return true;
      }
      return false;
    }),

    pageNumbers: Ember.computed('model.locationsMeta.{currentPage,pageCount}', 'pageMarginCount', function () {
      var currentPage = this.get('model.locationsMeta.currentPage'),
          pageCount = this.get('model.locationsMeta.pageCount'),
          previousLinks = currentPage - this.get('pageMarginCount'),
          nextLinks = currentPage + this.get('pageMarginCount'),
          delimiter = true,
          pageNumbers = [];
      if (previousLinks > 1) {
        pageNumbers.push({ number: 1 });
        if (previousLinks > 2 && delimiter) {
          pageNumbers.push({ number: false });
        }
      }

      for (var i = previousLinks; i <= nextLinks; i++) {
        if (currentPage === i) {
          pageNumbers.push({ number: i, class: 'active' });
        } else if (i > 0 && i < this.get('model.locationsMeta.pageCount') + 1) {
          pageNumbers.push({ number: i });
        }
      }

      if (nextLinks < pageCount) {
        if (nextLinks < pageCount - 1 && delimiter) {
          pageNumbers.push({ number: false });
        }
        pageNumbers.push({ number: pageCount });
      }

      return pageNumbers;
    }),
    changeCurrentPage: function () {
      if (this.get('model.locationsMeta.currentPage') === undefined) {
        var item = Ember.$(this.get('scrollToSelector')).offset().top;
        this.get('scrollTo').jQueryJumpTo(item, 500);
      }
    }.observes('model.meta.currentPage')
  });
});