define('handandstone-ember/pods/about-us/press/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    queryParams: {
      page: {
        refreshModel: true
      }
    },

    model: function model(params) {
      return this.get('store').query('press', params);
    }
  });
});