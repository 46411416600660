define('handandstone-ember/pods/components/class/service-item/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'item',

    isMassage: Ember.computed('type', function () {
      return this.get('type') == 'MA';
    }),

    isFacial: Ember.computed('type', function () {
      return this.get('type') == 'FI';
    }),

    isNeveskin: Ember.computed('type', function () {
      return this.get('type') == 'NS';
    }),

    isParity: function isParity() {
      return this.get('index') % 2 == 0;
    },

    showLearnMore: function () {
      return this.get('isMassage') || this.get('isFacial') || this.get('isNeveskin');
    }.property(),

    link: function () {
      var link = void 0;
      if (this.get('isMassage')) {
        link = 'class.massage.item';
      } else if (this.get('isFacial')) {
        link = 'class.facial.item';
      } else if (this.get('isNeveskin')) {
        link = 'class.neveskin.item';
      }
      return link;
    }.property(),

    image: function () {
      return this.isParity() ? this.get('model.image_right') : this.get('model.image_left');
    }.property(),
    actions: {
      bookingAction: function bookingAction(template, deferFunction) {
        this.sendAction('bookingAction', template, deferFunction);
      }
    }
  });
});