define('handandstone-ember/helpers/minute-hour-format', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.minuteHourFormat = minuteHourFormat;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function minuteHourFormat(_ref) {
    var _ref2 = _slicedToArray(_ref, 2),
        minutes = _ref2[0],
        short = _ref2[1];

    minutes = durationModifier(minutes);
    var h = minutes / 60 | 0,
        m = minutes % 60 | 0;

    if (short) {
      var formatted = void 0;
      if (m) {
        if (h) {
          formatted = h + 'h' + ' ' + m + 'm';
        } else {
          formatted = m + ' min';
        }
      } else {
        formatted = h + ' hour';
      }
      return formatted;
    } else {
      return (h ? h + ' hour' + (h > 1 ? 's' : '') : '') + (m ? (h ? ' ' : '') + (m + ' min') : '');
    }
  } /* globals moment */


  function durationModifier(minutes) {
    var editors = {
      75: 60
    };

    return editors[minutes] || minutes;
  }

  exports.default = Ember.Helper.helper(minuteHourFormat);
});