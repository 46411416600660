define('handandstone-ember/pods/location/serializer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTSerializer.extend({
    normalize: function normalize(model, hash) {
      hash.links = {
        facials: 'services?class=FI',
        massages: 'services?class=MA',
        facials_intro: 'services?class=FI&user_type=1',
        facials_non_member: 'services?class=FI&user_type=3',
        facials_member: 'services?class=FI&user_type=5',

        massages_intro: 'services?class=MA&user_type=1',
        massages_non_member: 'services?class=MA&user_type=3',
        massages_member: 'services?class=MA&user_type=5',

        introductory: 'services/intro',
        classifications: 'classes',
        addons: 'addons',
        zenoti_classifications: 'zenoti-classifications',

        services_duration: '/v1/locations/' + hash.id + '/services-duration',
        services_duration_non_member: '/v1/locations/' + hash.id + '/services-duration?user_type=3',
        services_duration_intro: '/v1/locations/' + hash.id + '/services-duration?user_type=1',
        services_duration_member: '/v1/locations/' + hash.id + '/services-duration?user_type=5',

        hot_deal: '/v1/locations/' + hash.id + '/hot-deal',
        tax: 'tax'
      };
      return this._super.apply(this, arguments);
    }
  });
});