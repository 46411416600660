define('handandstone-ember/pods/locations/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    location: Ember.inject.service('location'),
    queryParams: {
      page: {
        refreshModel: true
      },
      latitude: undefined,
      longitude: undefined,
      distance: undefined,
      address: undefined
    },

    model: function model(params) {
      if (!params.latitude && !params.longitude && !params.address && params.address != '') {
        params.latitude = this.get('location.localLatitude');
        params.longitude = this.get('location.localLongitude');
      }

      var locations = this.get('store').createRecord('locations');
      locations.setProperties(params);

      return {
        locations: locations,
        locationService: this.get('location')
      };
    },
    afterModel: function afterModel() {
      var _this = this;

      if (!this.get('location.localLatitude') && !this.get('location.localLongitude')) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            var locationsModel = _this.currentModel.locations;
            if (!locationsModel.get('latitude') && !locationsModel.get('longitude') && !locationsModel.get('address') && !locationsModel.get('page')) {
              locationsModel.set('latitude', position.coords.latitude);
              locationsModel.set('longitude', position.coords.longitude);
              _this.get('location').setLocationLat(position.coords.latitude);
              _this.get('location').setLocationLong(position.coords.longitude);
              locationsModel.searchAddressByCoordinates([locationsModel.get('latitude'), locationsModel.get('longitude')]);
            }
          });
        }
      }
      this._super.apply(this, arguments);

      //Amazon Advertising Tag
      amzn("trackEvent", "PageView_IHM 317 Hand and Stone Locations");
    },


    actions: {
      setCurrentLocation: function setCurrentLocation() {
        var _this2 = this;

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            _this2.transitionTo('locations', {
              queryParams: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                address: undefined,
                page: undefined
              }
            });
            _this2.currentModel.locations.set('page', undefined);
            _this2.currentModel.locations.set('latitude', position.coords.latitude);
            _this2.currentModel.locations.set('longitude', position.coords.longitude);
            _this2.get('location').setLocationLat(position.coords.latitude);
            _this2.get('location').setLocationLong(position.coords.longitude);
            _this2.currentModel.locations.searchAddressByCoordinates([position.coords.latitude, position.coords.longitude]);
          });
        }
      },
      findLocation: function findLocation(address) {
        this.transitionTo('locations', {
          queryParams: {
            address: address,
            latitude: undefined,
            longitude: undefined,
            page: undefined
          }
        });
        this.currentModel.locations.set('page', undefined);
        this.currentModel.locations.set('address', address);
        this.currentModel.locations.searchLatLng(address);
      },
      setDistance: function setDistance(distance) {
        this.transitionTo('locations', {
          queryParams: {
            distance: distance,
            page: undefined
          }
        });
        this.currentModel.locations.setProperties({ 'distance': distance, 'page': undefined });
      }
    }
  });
});