define('handandstone-ember/pods/components/modal-forgot-new-pass/component', ['exports', 'ember-modal-service/components/modal', 'handandstone-ember/mixins/modal-component-mixin'], function (exports, _modal, _modalComponentMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modal.default.extend(_modalComponentMixin.default, {
    query: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service('-routing'),
    modal: Ember.inject.service(),
    showError: false,
    isLoadingModel: true,
    isLoading: Ember.computed.or('isLoadingModel', 'modelReset.isSaving').readOnly(),

    hasPasswordError: Ember.computed('showError', 'modelReset.validations.attrs.password.isInvalid', 'modelReset.password.isInvalid', function () {
      return this.get('showError') && (this.get('modelReset.validations.attrs.password.isInvalid') || this.get('modelReset.errors.password'));
    }).readOnly(),
    hasPasswordConfirmError: Ember.computed('showError', 'modelReset.errors.password_confirmation', 'modelReset.validations.attrs.password_confirmation.isInvalid', function () {
      return this.get('showError') && (this.get('modelReset.password_confirmation.isInvalid') || this.get('modelReset.validations.attrs.password_confirmation.isInvalid'));
    }).readOnly(),

    modelReset: null,

    modelResetToken: function modelResetToken() {
      var _this = this;

      return this.get('store').findRecord('auth/reset-password', this.get('model.options.token')).then(function (result) {
        _this.set('modelReset', result);
        return result;
      }).catch(function () {
        _this.get('modal').close('forgot-new-pass');
        _this.get('modal').open('small-popup', { model: { success: false, title: 'Error', message: 'Invalid token' } });
      }).finally(function () {
        _this.set('isLoadingModel', false);
      });
    },

    actions: {
      reset: function reset() {
        var _this2 = this;

        this.set('showError', true);
        var model = this.get('modelReset');
        model.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            if (!model.get('isSaving')) {
              model.save().then(function () {
                _this2.get('modal').close('forgot-new-pass');
                _this2.get('modal').open('small-popup', { model: { success: true, title: 'Success!', message: 'Your password was changed successfully.' } });
              });
            }
          }
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.modelResetToken();
    }
  });
});