define('handandstone-ember/pods/account/dashboard/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    account: Ember.inject.service(),

    isShowAppointmentTable: function () {
      return Boolean(this.get('model.isPending') || this.get('model.length'));
    }.property('model.{isPending,length}')
  });
});