define('handandstone-ember/pods/components/membership/overview/table-info/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNameBindings: ['isDisplayTable:info-table'],
    table: null,

    isDisplayTable: function () {
      return Boolean(this.get('table.body'));
    }.property('table.body'),

    isDisplayHead: function () {
      var result = false;
      this.get('table.head').forEach(function (row) {
        if (row) {
          result = true;
        }
      });
      return result;
    }.property('table.head'),

    body: function () {
      var _this = this;

      var result = [];

      if (this.get('table.body') && this.get('table.head')) {

        this.get('table.body').forEach(function (row) {

          if (row) {
            var rowResult = { title: row.title, cells: [] };

            row.cells.forEach(function (cell, index) {
              Ember.set(cell, 'data_title', '');
              if (parseInt(cell.type) >= 1) {
                Ember.set(cell, 'data_title', _this.get('table.head')[index]);
              }
              if (parseInt(cell.type) === 2) {
                Ember.set(cell, 'data_title', Ember.get(cell, 'data_title') + ':');
              }
              rowResult['cells'].push(cell);
            });

            result.push(rowResult);
          }
        });
      } else {
        result = this.get('table.body');
      }

      return result;
    }.property('table.{body,head}')
  });
});