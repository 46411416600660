define('handandstone-ember/pods/application/route', ['exports', 'handandstone-ember/mixins/application-auth-route'], function (exports, _applicationAuthRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationAuthRoute.default, {
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    seo: Ember.inject.service(),
    modal: Ember.inject.service(),
    location: Ember.inject.service(),
    scrollTo: Ember.inject.service('scroll-to'),
    focus: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var _this = this,
          _arguments = arguments;

      this.get('seo').getSeoModels().finally(function () {
        _this._super.apply(_this, _arguments);
      });
      return this._loadCurrentUser();
    },

    _loadCurrentUser: function _loadCurrentUser() {
      var _this2 = this;

      return this.get('account').load().catch(function () {
        return _this2.get('session').invalidate();
      });
    },


    actions: {
      openModal: function openModal(modalName, data) {
        var _this3 = this;

        this.set('focus.isUsedOutletModal', true);

        this.render(modalName, {
          into: 'application',
          outlet: 'modal',
          model: data
        });

        setTimeout(function () {
          // Ember.$('#info-popup').addClass('show');
          _this3.get('scrollTo').savePageOffset();

          var popup = Ember.$('.popups.popup');
          popup.click(function (ev) {
            if (Ember.$(ev.target).is(popup.find('.holder'))) {
              popup.find('.close').trigger('click');
            }
          });
        }, 1);
      },
      closeModal: function closeModal(params) {
        // Ember.$('#info-popup').removeClass('show');
        this.get('scrollTo').applyPageOffset();
        this.set('focus.isUsedOutletModal', false);
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });

        if (typeof params !== "undefined") {
          this.transitionTo(params);
        }
      },
      logout: function logout() {
        this.get('session').invalidate();
      },

      // willTransition: function () {
      //   // this.send('closeModal');
      //   return true;
      // },
      selectLocation: function selectLocation(locationId) {
        this.get('location').setLocationId(locationId);
      },
      didTransition: function didTransition() {
        this.get('location').setCurrentRoute(this.router._routerMicrolib.currentHandlerInfos[1]);
        return true;
      },
      error: function error(_error) {
        if (this.getErrorStatus(_error) === 404) {
          this.replaceWith('/not-found');
        }
      }
    },

    getErrorStatus: function getErrorStatus(error) {
      if (error) {
        if (error.status !== undefined) {
          return error.status;
        }

        if (error.errors && error.errors[0] && error.errors[0].status) {
          return error.errors[0].status;
        }
      }
    }
  });
});