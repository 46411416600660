define('handandstone-ember/pods/acg-submitted-email/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    modal: Ember.inject.service(),

    getController: function getController() {
      return this.controllerFor("acgSubmittedEmail");
    },
    setTopImage: function setTopImage(formStatus) {
      var src = void 0,
          alt = void 0;

      switch (formStatus) {
        case 'error':
          src = 'Header_ACG WOL Summit Page_Error Page';
          alt = 'error ';
          break;
        case 'success':
          src = 'Header_ACG WOL Summit Page_ThankYouPage';
          alt = 'thank you ';
          break;
        default:
          src = 'Header_ACG WOL Summit Page_WelcomePage';
          alt = '';
      }

      this.getController().set('topImage', {
        srcPart: src,
        altPart: alt
      });
    },
    createModel: function createModel() {
      return this.get('store').createRecord('acg-submitted-email');
    },


    setupController: function setupController(controller) {
      this.setTopImage();
      controller.setProperties({
        isLoadingFormData: false,
        showError: false,
        model: this.createModel()
      });
    },

    actions: {
      sendForm: function sendForm(acgEmailsModel) {
        var _this = this;

        var controller = this.getController();

        controller.set('showError', true);

        acgEmailsModel.validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {

            if (!acgEmailsModel.get('isSaving')) {

              controller.set("isLoadingFormData", true);

              acgEmailsModel.save().then(function () {
                // if (!this.isDestroyed) {
                _this.setTopImage('success');
                controller.setProperties({
                  isLoadingFormData: false,
                  showError: false,
                  model: _this.createModel()
                });
                _this.get('modal').open('small-popup', { model: { success: true, title: 'Thank you.!', message: 'We are processing your $25 gift card now so be sure to look in your email within the next 24 hours. You can apply your $25 gift toward any product, service or enhancement at any of the nearly 500 Hand & Stone Massage and Facial Spas. <br> If you indicated that you would like us to reach out for franchise information and to schedule an Informational Webinar with our team, we will reach out via email to schedule a follow-up call.' } });
                // }
              }, function (error) {
                //if (!this.isDestroyed) {
                var errorsArray = error.errors,
                    errorsArrayLength = errorsArray.length;

                if (errorsArrayLength) {
                  var i = 0,
                      errorMessages = '';

                  for (; i < errorsArrayLength; i++) {
                    errorMessages += errorsArray[i].detail + '<br>';
                  }

                  _this.get('modal').open('small-popup', { model: { success: false, title: 'Error!', message: errorMessages } });
                }

                _this.setTopImage('error');
                controller.set("isLoadingFormData", false);
                //}
              });
            }
          }
        });
      }
    }
  });
});