define('handandstone-ember/pods/components/membership/side-block/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    account: Ember.inject.service(),
    modal: Ember.inject.service(),

    tagName: 'aside',
    blockData: null,

    isAuthenticated: function () {
      return this.get('account.session.isAuthenticated');
    }.property('account.session.isAuthenticated'),

    actions: {
      signIn: function signIn() {
        this.get('modal').open('login');
      }
    }
  });
});