define('handandstone-ember/pods/about-us/contact-us/route', ['exports', 'handandstone-ember/mixins/seo-module-route'], function (exports, _seoModuleRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var centralOfficeId = 1;
  var contactUsSlug = 'contact_us';
  exports.default = Ember.Route.extend(_seoModuleRoute.default, {
    model: function model() {
      var location = null;

      if (this.get('store').hasRecordForId('location', centralOfficeId)) {
        var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
        location = ObjectPromiseProxy.create({
          promise: Ember.RSVP.cast(this.get('store').peekRecord('location', centralOfficeId))
        });
      } else {
        location = this.get('store').findRecord('location', centralOfficeId, { backgroundReload: false });
      }

      return {
        page: this.get('store').findRecord('static-page', contactUsSlug),
        location: location
      };
    }
  });
});