define('handandstone-ember/pods/components/locations/popup-items-locations/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    breakpoints: [{
      breakpoint: 10000,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }, {
      breakpoint: 1800,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    }, {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    }, {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }],
    setCounter: function setCounter(opt) {
      var curB = opt.activeBreakpoint;
      this.set('current', Math.ceil((opt.currentSlide + 1) / opt.breakpointSettings[curB].slidesToShow));
      this.set('total', Math.ceil(opt.slideCount / opt.breakpointSettings[curB].slidesToShow));
    },

    actions: {
      sliderInit: function sliderInit(el) {
        this.setCounter($(el)[0].slick);
      },
      afterChange: function afterChange(opt) {
        this.setCounter(opt);
      },
      selectLocation: function selectLocation(locationId) {
        this.sendAction('selectLocation', locationId);
      }
    }
  });
});