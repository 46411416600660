define('handandstone-ember/pods/components/booking/auth-block/forgot-password/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    successSendEmail: false,
    showError: false,
    hasError: Ember.computed('showError', 'modelForgot.errors', 'modelForgot.validations.isInvalid', function () {
      return this.get('showError') && (this.get('modelForgot.validations.isInvalid') || this.get('modelForgot.errors'));
    }).readOnly(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('modelForgot', this.get('store').createRecord('auth/forgot-password'));
    },

    actions: {
      formSwitch: function formSwitch() {
        this.sendAction('formSwitch');
      },
      forgot: function forgot() {
        var _this = this;

        this.set('showError', true);
        this.get('modelForgot').validate().then(function (_ref) {
          var validations = _ref.validations;

          if (validations.get('isValid')) {
            if (!_this.get('modelForgot.isSaving')) {
              _this.get('modelForgot').save().then(function () {
                _this.set('successSendEmail', true);
              });
            }
          }
        });
      }
    }
  });
});