define('handandstone-ember/mixins/modal-native-mixin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    focus: Ember.inject.service('focus'),
    attributeBindings: ['role', 'aria-labelledby'],
    role: 'dialog',
    'aria-labelledby': 'modal-header-id',

    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {
        _this.get('focus').keepFocus(document.getElementById(_this.elementId));
        _this.set('focus.isMainAriaHidden', true);
      }, 1);

      this._super.apply(this, arguments);
    },
    didDestroyElement: function didDestroyElement() {
      this.get('focus').restoreSaveFocusContext();
      this.set('focus.isMainAriaHidden', false);
      this.get('focus').deleteModalFocusListener();
      this._super.apply(this, arguments);
    }
  });
});