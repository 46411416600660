define('handandstone-ember/pods/components/booking/intro-slider/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend({
    account: service(),
    session: service(),
    scrollTo: service(),

    classNames: ['services-slider'],
    tagName: 'section',
    introductoryList: [],

    service_id: Ember.computed('model.service_id', function () {
      return this.get('model.service_id');
    }),

    isShowDisabledInfo: function () {
      return !this.get("introductoryList").length;
    }.property("introductoryList"),

    processingChangeService: 0,

    setIntroductory: function setIntroductory() {
      var _this = this;

      var result = [],
          introductory = this.get("model.location.introductory");

      if (introductory !== undefined) {
        introductory.forEach(function (item) {
          result.pushObject(item);
        });

        if (this.get('session.isAuthenticated')) {
          this.get('account.model').then(function (accountModel) {
            var usedIntroServices = accountModel.get("used_intro_services");

            for (var i = 0; i < usedIntroServices.length; i++) {
              for (var j = 0; j < result.length; j++) {
                if (result[j].get('global_id') === usedIntroServices[i].service_id) {
                  result.removeObject(result[j]);
                  break;
                }
              }
            }

            _this.set("introductoryList", result);
          });
        } else {

          this.set("introductoryList", result);
        }
      }
    },


    changeIntroductory: function () {
      this.setIntroductory();
    }.observes('model.location.introductory'),

    init: function init() {
      this._super.apply(this, arguments);
      this.setIntroductory();
    },
    didRender: function didRender() {
      if (this.get('model.location.introductory.length')) {
        window.page.bookingFirstVisitSlider();
      }
    },
    setCounter: function setCounter(opt) {
      var curB = opt.activeBreakpoint;

      this.setProperties({
        current: Math.ceil((opt.currentSlide + 1) / opt.breakpointSettings[curB].slidesToShow),
        total: Math.ceil(opt.slideCount / opt.breakpointSettings[curB].slidesToShow)
      });
    },
    processingIncrement: function processingIncrement() {
      this.set('processingChangeService', this.get('processingChangeService') + 1);
    },
    processingDecrement: function processingDecrement() {
      this.set('processingChangeService', this.get('processingChangeService') - 1);
    },


    checkedService: function () {
      var _this2 = this;

      if (this.get('model.location.introductory') && this.get('service_id')) {
        this.get('model.location.introductory').forEach(function (service) {
          if (_this2.get('service_id') === service.get('service_id')) {
            _this2.get('scrollTo').jQueryJumpTo(Ember.$('.footer').offset().top, 500);
          }
        });
      }
    }.on('didInsertElement').observes('model.location.introductory.@each.service_id', 'service_id'),

    actions: {
      selectService: function selectService(service) {
        var _this3 = this;

        if (this.get('service_id') !== service.get('service_id') && !this.get('processingChangeService')) {
          var bookingModel = this.get('model');
          this.set('service_id', service.get('service_id'));
          bookingModel.discardSelection('all');

          //Set another type
          if (service.get('type') !== bookingModel.get('type_id')) {
            bookingModel.get('types').forEach(function (type) {
              if (type.get('abbr') === service.get('type')) {
                bookingModel.setType(type);
              }
            });
          }

          //Set another service
          if (service.get('slug') !== bookingModel.get('service_code')) {
            this.processingIncrement();

            bookingModel.get('location.activeServices').then(function (services) {
              _this3.processingDecrement();

              services.forEach(function (item) {

                if (item.get('id') === service.get('slug')) {
                  bookingModel.setService(item);
                  bookingModel.get('durationsList').forEach(function (duration) {
                    if (duration.get('service_id') === service.get('service_id')) {
                      bookingModel.setDuration(duration);
                    }
                  });
                }
              });
            });
          } else {
            //Set another duration
            if (service.get('service_id') !== bookingModel.get('service_id')) {
              bookingModel.get('durationsList').forEach(function (duration) {
                if (duration.get('service_id') === service.get('service_id')) {
                  bookingModel.setDuration(duration);
                }
              });
            }
          }
        }
      }
    }
  });
});