define('handandstone-ember/pods/booking/model', ['exports', 'ember-data', 'handandstone-ember/mixins/get-validation-props'], function (exports, _emberData, _getValidationProps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationsObj = _getValidationProps.default.returnValidationObj(['first_name', 'last_name', 'email', 'email_confirmation', 'phone', 'card_name', 'card_number', 'card_cvv', 'card_exp_month', 'card_exp_year', 'billing_zip', 'password', 'password_confirmation', 'cancellation_policy']); /* globals moment */


  var Validations = _getValidationProps.default.buildValidations(validationsObj);

  exports.default = _emberData.default.Model.extend(Validations, {
    'booking-store': Ember.inject.service(),
    session: Ember.inject.service(),
    account: Ember.inject.service(),
    isDisplayAuthBlock: true,
    isExternalLink: false,
    isReorder: false,
    isReorderIntro: false,

    setStoreBookingReservation: function setStoreBookingReservation(employee) {
      var locationId = this.get('location_id'),
          serviceId = this.get('service_id'),
          employeeId = this.get('employee_id'),
          result = {
        locationId: locationId,
        serviceId: serviceId,
        employeeId: employeeId,
        isFranchisee: false,
        locationIdFranchise: null,
        serviceIdFranchise: null,
        employeeIdFranchise: null
      };

      if (employee.get('isFranchise')) {
        var _locationId = employee.get('location_id'),
            _serviceId = employee.get('service_id'),
            _employeeId = employee.get('employee_id');

        this.setProperties({
          is_franchisee: true,
          location_id: _locationId,
          service_id: _serviceId,
          employee_id: _employeeId
        });

        result.isFranchisee = true;
        result.locationIdFranchise = _locationId;
        result.serviceIdFranchise = _serviceId;
        result.employeeIdFranchise = _employeeId;
      } else {
        this.setProperties({
          is_franchisee: false
        });
      }

      this.get('booking-store').writeFranchiseeBuffer(result);
    },
    setStoreBookingConfirmation: function setStoreBookingConfirmation() {
      var buffer = this.get('booking-store').readFranchiseeBuffer();

      if (buffer.isFranchisee) {
        this.setProperties({
          is_franchisee: true,
          location_id: buffer.data.locationIdFranchise,
          service_id: buffer.data.serviceIdFranchise,
          employee_id: buffer.data.employeeIdFranchise
        });
      }
    },


    is_franchisee: _emberData.default.attr('boolean', { defaultValue: false }),
    order_id: _emberData.default.attr('string'),
    sex: _emberData.default.attr('string'),
    type_id: _emberData.default.attr('string'),
    location_id: _emberData.default.attr('number'),
    service_id: _emberData.default.attr('number'),
    service_code: _emberData.default.attr('string'),
    employee_id: _emberData.default.attr('number'),
    specific_employee_id: _emberData.default.attr('number'),
    date: _emberData.default.attr('string'),
    time: _emberData.default.attr('string'),
    duration_time: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    email_confirmation: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    password_confirmation: _emberData.default.attr('string'),
    send_email: _emberData.default.attr('boolean'),
    finish_booking: _emberData.default.attr('boolean'),
    payment_method_id: _emberData.default.attr('number'),
    gift_card: _emberData.default.attr('string'),
    cancellation_policy: _emberData.default.attr('boolean'),
    hide_variation: _emberData.default.attr('boolean'),
    addon_services: _emberData.default.attr('array', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    hot_deal: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),
    //1 : Introductory price
    //3 : non-Member price
    //5 : Member price
    user_type: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return 3;
      }
    }),

    expired_at: _emberData.default.attr('string'),
    bookingKey: _emberData.default.attr('string'),
    //Billing data
    payment_token: _emberData.default.attr('string'),
    card_name: _emberData.default.attr('string'),
    //service price
    service_price: _emberData.default.attr('string', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),

    //Tax for location
    tax: _emberData.default.attr('number', {
      defaultValue: function defaultValue() {
        return 0;
      }
    }),

    type: null,
    service: null,
    addons: [],
    steps: ['first', 'second', 'third', 'fourth'],
    types: function () {
      return this.get('store').query('class', { type: 'booking' });
    }.property(),

    location: Ember.computed('location_id', function () {
      if (this.get('store').hasRecordForId('location', this.get('location_id'))) {
        var ObjectPromiseProxy = Ember.ObjectProxy.extend(Ember.PromiseProxyMixin);
        return ObjectPromiseProxy.create({
          promise: Ember.RSVP.cast(this.get('store').peekRecord('location', this.get('location_id')))
        });
      } else {
        return this.get('store').findRecord('location', this.get('location_id'));
      }
    }),

    date_availability: Ember.computed('location_id', 'service_id', 'sex', 'specific_employee_id', function () {
      var query = {};
      if (this.get('location_id')) {
        query.location_id = this.get('location_id');
      }
      if (this.get('service_id')) {
        query.service_id = this.get('service_id');
      }
      if (this.get('sex')) {
        query.sex = this.get('sex');
      }
      if (this.get('specific_employee_id')) {
        query.employee_id = this.get('specific_employee_id');
      }

      return this.get('store').query('date_availability', query);
    }),

    durationsList: Ember.computed('service_code', function () {
      var durations = [],
          self = this;
      this.get('location.servicesDuration').forEach(function (duration) {
        if (duration.get('service_code') == self.get('service_code')) {
          durations.pushObject(duration);
        }
      });
      return durations;
    }),

    durationsListById: Ember.computed('service_id', function () {
      var durations = [],
          self = this;
      this.get('location.servicesDuration').forEach(function (duration) {
        if (duration.get('service_id') == self.get('service_id')) {
          durations.pushObject(duration);
        }
      });
      return durations;
    }),

    isStepCorrect: function isStepCorrect(step) {
      return step >= 0 && step < this.steps.length;
    },
    changeStep: function changeStep(value) {
      var newStep = this.steps.indexOf(this.get('step')) + value;
      if (this.isStepCorrect(newStep)) {
        this.set('step', this.steps[newStep]);
        return true;
      }
      return false;
    },
    discardSelection: function discardSelection() {
      var level = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';

      switch (level) {
        case 'all':
          this.set('type', null);
        case 'service':
          this.set('service', null);
          this.set('service_code', null);
        case 'duration':
          this.set('duration', null);
        case 'addons':
          this.set('addons', []);
        case 'employee':
          this.set('employee', null);
          this.set('specificEmployee', null);
          this.set('specific_employee_id', null);
          this.set('employee_id', null);
        case 'date':
          this.set('date', null);
        case 'time':
          this.set('time', null);
      }
    },


    onStepChange: function () {
      var reached = false;
      var steps = this.steps.slice(0);
      while (steps.length > 0) {
        var step = steps.pop(),
            status = step == this.get('step');
        if (status) {
          reached = true;
        }
        this.set(Ember.String.camelize('is_' + step + '_step_or_above'), reached);
        this.set(Ember.String.camelize('is_' + step + '_step'), status);
      }
    }.observes('step'),

    setEmployeeId: function setEmployeeId() {
      if (!this.get('employee_id')) {
        this.set('employee_id', this.get('employee.id') || this.get('specificEmployee.id'));
      }
    },


    appointmentDate: Ember.computed('date', 'time', function () {
      if (this.get('date') && this.get('time')) {
        return moment(this.get('date') + ' ' + this.get('time'), 'YYYY-MM-DD HH:mm').format('dddd, MMM DD, YYYY h:mmA');
      }
    }),

    selectedEmployees: Ember.computed('date', 'specificEmployee', 'service_id', function () {
      if (this.get('service.service_id') && this.get('date')) {
        return this.get('store').query('employee-schedule', {
          paramsForPath: {
            location_id: this.get('location_id'),
            service_id: this.get('service.service_id')
          },
          date: this.get('date'),
          sex: this.get('sex') ? this.get('sex').charAt(0).toUpperCase() + this.get('sex').slice(1) : null,
          employee_id: this.get('specificEmployee.id'),
          timestamp: new Date().getTime()
        });
      }
    }),

    employeeObserves: function () {
      var _this = this;

      if (this.get('selectedEmployees')) {
        this.set('isAnyWork', true);
        this.get('selectedEmployees').then(function (employes) {
          var isAnyWork = false;
          employes.forEach(function (employee) {

            if (parseInt(employee.get('work')) == 1) {
              isAnyWork = true;
            }
          });
          _this.set('isAnyWork', isAnyWork);
        });
      }
    }.observes('selectedEmployees.@each'),

    isUsingServerPrice: Ember.computed('isThirdStepOrAbove', 'isSecondStepOrAbove', 'isIntroductory', function () {
      return Boolean(this.get('isThirdStepOrAbove') || this.get('isSecondStepOrAbove') && this.get('isIntroductory'));
    }),

    servicePriceWithoutTax: Ember.computed('service_price', 'duration.price', 'isUsingServerPrice', function () {
      return parseFloat(this.get('service_price') > 0 && this.get('isUsingServerPrice') ? this.get('service_price') : this.get('duration.price'));
    }),

    total: Ember.computed('addons.@each', 'servicePriceWithoutTax', function () {
      var totalPrice = this.get('servicePriceWithoutTax');
      this.get('addons').map(function (addon) {
        totalPrice += parseFloat(addon.get('price'));
      });
      return totalPrice.toFixed(2);
    }),

    totalWithTax: Ember.computed('total', 'taxValue', function () {
      var totalPrice = parseFloat(this.get('total')) + parseFloat(this.get('taxValue'));
      return totalPrice.toFixed(2);
    }),

    addOnTaxes: Ember.computed('addons.@each.{slug,price,is_taxable,is_override_tax,override_tax}', 'location.tax.price', 'location.active_tax', function () {
      var _this2 = this;

      var tax = 0;
      if (this.get('location.active_tax')) {
        this.get('addons').map(function (addon) {
          if (addon.get('is_taxable')) {
            if (addon.get('is_override_tax')) {
              tax += parseFloat(addon.get('price')) * addon.get('override_tax');
            } else {
              tax += parseFloat(addon.get('price')) * _this2.get('location.tax.price');
            }
          }
        });
      }
      return tax;
    }),

    localProcessedTax: Ember.computed('duration.{price,is_taxable,is_override_tax,override_tax}', 'addOnTaxes', 'location.active_tax', 'location.tax.price', function () {
      var tax = 0;

      if (this.get('location.active_tax')) {
        if (this.get('duration.is_taxable')) {
          if (this.get('duration.is_override_tax')) {
            tax += parseFloat(this.get('duration.price')) * this.get('duration.override_tax');
          } else {
            tax += parseFloat(this.get('duration.price')) * this.get('location.tax.price');
          }
        }

        tax += this.get('addOnTaxes');
      }
      return tax;
    }),

    //Computed tax (Reservation tax price has highest priority)
    taxValue: Ember.computed('isUsingServerPrice', 'tax', 'localProcessedTax', 'addOnTaxes', function () {
      var result = 0;
      if (this.get('isUsingServerPrice')) {
        result = this.get('tax') + this.get('addOnTaxes');
      } else {
        result = this.get('localProcessedTax') || 0;
      }
      return result.toFixed(2);
    }),

    setType: function setType(type) {
      this.set('type', type);
      this.set('type_id', type.id);
      this.get('location').set('type', type);
      this.get('location').set('user_type', this.get('user_type'));
      this.discardSelection('service');
    },
    setService: function setService(service) {
      this.set('service', service);
      this.set('service_code', service.get('slug'));
      this.discardSelection('duration');
      this.set('sex', null);
    },
    setDuration: function setDuration(duration) {
      this.set('duration', duration);
      this.get('service').set('service_id', this.get('duration.service_id'));
      this.set('service_id', this.get('service.service_id'));
      this.set('duration_time', this.get('duration.duration'));
      this.discardSelection('addons');
    },
    setSex: function setSex(sex) {
      this.set('sex', sex);
      this.discardSelection('employee');
    },
    setEmployee: function setEmployee(employee) {
      this.setProperties({
        sex: null,
        specificEmployee: employee,
        specific_employee_id: employee.id,
        employee: employee,
        employee_id: employee.id
      });

      this.discardSelection('date');
    },
    setDate: function setDate(date) {
      this.set('date', date);
      this.discardSelection('time');
    },
    setTime: function setTime(time) {
      this.set('time', time);
    },
    setHotDeal: function setHotDeal(hotDeal) {
      this.setProperties({
        hotDeal: hotDeal ? 1 : 0,
        service_price: hotDeal.price || 0
      });
    },
    addAddon: function addAddon(addon) {
      var addonServices = this.get('addon_services');
      if (!addonServices.includes(addon.get('addon_id'))) {
        addonServices.pushObject(addon.get('addon_id'));
      }
      this.set('addon_services', addonServices);
      if (!this.addons.includes(addon)) {
        this.addons.pushObject(addon);
      }
    },
    toggleAddons: function toggleAddons(addon) {
      var addonServices = this.get('addon_services');
      if (addonServices.includes(addon.get('addon_id'))) {
        addonServices.removeObject(addon.get('addon_id'));
      } else {
        addonServices.pushObject(addon.get('addon_id'));
      }

      this.set('addon_services', addonServices);
      if (this.addons.includes(addon)) {
        this.addons.removeObject(addon);
      } else {
        this.addons.pushObject(addon);
      }
    },

    reservationFlag: false,

    hasPaymentError: Ember.computed('errors', function () {
      var result = false;
      if (typeof this.get('errors') !== 'undefined') {
        this.get('errors').forEach(function (error) {
          if (error.attribute === 'payment') {
            return result = true;
          }
        });
      }
      return result;
    }),

    hasEmailError: Ember.computed('errors', function () {
      var result = false;
      if (typeof this.get('errors') !== 'undefined') {
        this.get('errors').forEach(function (error) {
          if (error.attribute === 'email') {
            return result = true;
          }
        });
      }
      return result;
    }),

    isRedirectToHotDeal: Ember.computed('hasPaymentError', 'hasEmailError', 'hot_deal', function () {
      return !this.get('hasPaymentError') && !this.get('hasEmailError') && this.get('hot_deal');
    }),

    isIntroductory: Ember.computed('user_type', function () {
      return this.get('user_type') === 1;
    }),

    isRegular: Ember.computed('user_type', 'hot_deal', function () {
      return this.get('user_type') === 3 && !this.get('hot_deal');
    }),

    isMemberBooking: Ember.computed('user_type', 'hot_deal', function () {
      return this.get('user_type') === 5;
    }),

    isHotDeal: Ember.computed('hot_deal', function () {
      return Boolean(this.get('hot_deal'));
    }),

    isPaymentStep: Ember.computed('isIntroductory', 'step', function () {
      var step = this.get('step'),
          isIntroductory = this.get('isIntroductory');

      return Boolean(step == 'second' && isIntroductory || step == 'third' && !isIntroductory);
    }),

    isConfirmationStep: Ember.computed('finish_booking', 'saveProcess', function () {
      return Boolean(this.get('finish_booking') && !this.get('saveProcess'));
    })
  });
});