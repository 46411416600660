define('handandstone-ember/services/text-fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.getAllModels();
    },
    getAllModels: function getAllModels() {
      var _this = this;

      return this.get('store').findAll('text-field').then(function (result) {
        _this.set('allModels', result);
        return result;
      });
    },


    allModels: null,

    onStepChange: function () {
      var _this2 = this;

      this.get('allModels').forEach(function (model) {
        _this2.set(model.get('id'), model);
      });
    }.observes('allModels.@each.slug')
  });
});