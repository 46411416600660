define('handandstone-ember/pods/contact-request/adapter', ['exports', 'handandstone-ember/pods/application/adapter', 'ember-inflector'], function (exports, _adapter, _emberInflector) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _adapter.default.extend({
        pathForType: function pathForType(type) {
            var dasherized = Ember.String.dasherize(type);
            return (0, _emberInflector.pluralize)(dasherized);
        }
    });
});