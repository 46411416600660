define('handandstone-ember/services/focus', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var currentModalFocus = null;

  exports.default = Ember.Service.extend({
    savedFocusContext: null,
    currentModalContext: null,
    isMainAriaHidden: false,
    isModalCookiePolicyOpen: false,
    isUsedOutletModal: false,

    keyListener: null,
    tabbableElements: 'a[href], area[href], input:not([disabled]),' + 'select:not([disabled]), textarea:not([disabled]),' + 'button:not([disabled]), iframe, object, embed, *[tabindex],' + '*[contenteditable]',

    keepFocus: function keepFocus(context, notSaveFocusFlag) {
      this.set('currentModalContext', context);
      var allTabbableElements = context.querySelectorAll(this.get('tabbableElements'));
      var firstTabbableElement = allTabbableElements[0];
      var lastTabbableElement = allTabbableElements[allTabbableElements.length - 1];
      currentModalFocus = firstTabbableElement;

      this.set('keyListener', function (event) {
        var keyCode = event.which || event.keyCode; // Get the current keycode

        // Polyfill to prevent the default behavior of events
        event.preventDefault = event.preventDefault || function () {
          event.returnValue = false;
        };
        // If it is TAB
        if (keyCode === 9) {

          // Move focus to first element that can be tabbed if Shift isn't used
          if (event.target === lastTabbableElement && !event.shiftKey) {
            event.preventDefault();
            firstTabbableElement.focus();

            // Move focus to last element that can be tabbed if Shift is used
          } else if (event.target === firstTabbableElement && event.shiftKey) {
            event.preventDefault();
            lastTabbableElement.focus();
          }
        }

        if (keyCode === 27) {
          currentModalFocus = null;
          var closeButton = context.querySelector('.close');
          if (closeButton) {
            closeButton.click();
          }
        } else {
          currentModalFocus = document.activeElement;
        }
      });

      if (firstTabbableElement) {
        this.saveFocusContext(notSaveFocusFlag);
        setTimeout(function () {
          firstTabbableElement.focus();
        }, 1);
      }

      context.addEventListener('keydown', this.get('keyListener'), false);
    },

    rebuildModalFocus: function rebuildModalFocus() {
      var _this = this;

      if (this.get('currentModalContext')) {
        this.get('currentModalContext').removeEventListener('keydown', this.get('keyListener'), false);
        setTimeout(function () {
          _this.keepFocus(_this.get('currentModalContext'), true);
        }, 1);
      }
    },


    deleteModalFocusListener: function deleteModalFocusListener() {
      if (this.get('currentModalContext')) {
        this.get('currentModalContext').removeEventListener('keydown', this.get('keyListener'), false);
        this.set('currentModalContext', null);
      }
    },

    saveFocusContext: function saveFocusContext(notSaveFocusFlag) {
      if (!notSaveFocusFlag) {
        this.set('savedFocusContext', document.activeElement);
      }
    },

    restoreSavedModalFocus: function restoreSavedModalFocus() {
      if (currentModalFocus) {
        setTimeout(function () {
          currentModalFocus.focus();
        }, 1);
      }
    },

    restoreSaveFocusContext: function restoreSaveFocusContext() {
      this.get('savedFocusContext').focus();
    },

    modalContainerAriaHidden: function () {
      return (this.get('isMainAriaHidden') && (this.get('isModalCookiePolicyOpen') || this.get('isUsedOutletModal'))).toString();
    }.property('isMainAriaHidden', 'isModalCookiePolicyOpen', 'isUsedOutletModal')
  });
});