define('handandstone-ember/pods/employee-schedule/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    distance: _emberData.default.attr('number'),
    location_address: _emberData.default.attr('string'),
    location_id: _emberData.default.attr('number'),
    location_name: _emberData.default.attr('string'),
    employee_id: _emberData.default.attr('number'),
    service_id: _emberData.default.attr('string'), /*TODO change to type number*/
    start_time: _emberData.default.attr('string'),
    end_time: _emberData.default.attr('string'),
    full_name: _emberData.default.attr('string'),
    interval: _emberData.default.attr('number'),
    sex: _emberData.default.attr('string'),
    work: _emberData.default.attr('boolean'),
    booked: _emberData.default.attr(),
    isFranchise: false,
    // hotDeals: DS.attr(),
    name: Ember.computed('full_name', function () {
      var name = this.get('full_name');
      return name.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    })
  });
});