define('handandstone-ember/pods/auth/sign-up/model', ['exports', 'ember-data', 'ember-cp-validations', 'handandstone-ember/mixins/get-validation-props'], function (exports, _emberData, _emberCpValidations, _getValidationProps) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var validationsObj = _getValidationProps.default.returnValidationObj(['first_name', 'last_name', 'phone', 'email', 'email_confirmation', 'password', 'password_confirmation']);

  validationsObj['password'].pushObject((0, _emberCpValidations.validator)('presence', true));

  var Validations = _getValidationProps.default.buildValidations(validationsObj);

  exports.default = _emberData.default.Model.extend(Validations, {
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    email_confirmation: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    password_confirmation: _emberData.default.attr('string'),
    location_id: _emberData.default.attr('number') //not required
  });
});