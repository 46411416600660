define('handandstone-ember/pods/location/model', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    helper: Ember.inject.service('helper'),
    location_id: null,

    massages: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    facials: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    neveskin: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),

    facials_intro: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    facials_non_member: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    facials_member: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),

    neveskin_intro: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    neveskin_non_member: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    neveskin_member: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),

    massages_intro: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    massages_non_member: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),
    massages_member: _emberData.default.hasMany('service', {
      async: true,
      inverse: null
    }),

    services: _emberData.default.hasMany('service', {
      async: true,
      inverse: null,
      reload: true
    }),

    introductory: _emberData.default.hasMany('service.intro', {
      async: true,
      inverse: null
    }),

    services_duration: _emberData.default.hasMany('service_duration', {
      async: true,
      inverse: null
    }),

    services_duration_non_member: _emberData.default.hasMany('service_duration', {
      async: true,
      inverse: null
    }),
    services_duration_member: _emberData.default.hasMany('service_duration', {
      async: true,
      inverse: null
    }),
    services_duration_intro: _emberData.default.hasMany('service_duration', {
      async: true,
      inverse: null
    }),
    zenoti_classifications: _emberData.default.hasMany('zenoti-classification', {
      async: true
    }),
    classifications: _emberData.default.hasMany('class', {
      async: true
    }),
    addons: _emberData.default.hasMany('addon', { async: true }),
    tax: _emberData.default.belongsTo('tax', { async: true }),

    phone: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    area: _emberData.default.attr('string'),

    address: _emberData.default.attr('string'),
    active_tax: _emberData.default.attr('boolean'),
    active_lmd: _emberData.default.attr('boolean'),
    distance: _emberData.default.attr('string'),
    latitude: _emberData.default.attr('string'),
    longitude: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    short_description: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    worktime: _emberData.default.attr(),
    timezone: _emberData.default.attr(),
    timezone_name: _emberData.default.attr(),
    info_file: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    status: _emberData.default.attr('number'),
    images: _emberData.default.attr(),
    is_coming_soon: _emberData.default.attr('boolean'),
    extra_data: _emberData.default.attr(),
    socials: _emberData.default.attr(),
    slug: _emberData.default.attr('string'),
    intro_price: _emberData.default.attr('number'),
    external_booking: _emberData.default.attr('boolean'),
    external_link: _emberData.default.attr('string'),
    external_introductory_category_id: _emberData.default.attr('string'),
    external_spa_deals_link: _emberData.default.attr('string'),
    coronavirus_form: _emberData.default.attr('string'),
    appointment_for_members_lmd_everyone: _emberData.default.attr('boolean'),
    zenoti_tab: _emberData.default.attr('boolean'),
    gift_card_link: _emberData.default.attr('string'),

    //1 : Introductory price
    //3 : non-Member price
    //5 : Member price
    user_type: 3,

    socialLinks: function () {
      return this.get('socials');
    }.property('socials'),

    fullAddress: function () {
      return this.get('address') + ', ' + this.get('city') + ', ' + this.get('state') + ' ' + this.get('zip');
    }.property(),

    phoneNumber: function () {
      if (this.get('phone')) {
        var phone = this.get('phone'),
            result = this.get('area') ? '(' + this.get('area') + ') ' : '';

        return result + phone.substr(0, 3) + (phone.charAt(3) != '-' && phone.charAt(4) != '-' ? '-' : "") + phone.substr(3);
      }
      return '';
    }.property('phone', 'area'),

    distanceCorrect: function () {
      if (this.get('distance') != null && this.get('distance') != 0) {
        return parseFloat(this.get('distance')).toFixed(1);
      }
      return false;
    }.property('distance'),

    servicesDuration: function () {
      if (this.get('user_type') === this.get('helper.user_types.non_member')) {
        return this.get('services_duration_non_member');
      }

      if (this.get('user_type') === this.get('helper.user_types.member')) {
        return this.get('services_duration_member');
      }

      return this.get('services_duration_intro');
    }.property('user_type'),

    activeServices: Ember.computed('type', 'user_type', function () {

      if (this.get('type.isMassage') && this.get('user_type') === this.get('helper.user_types.introductory')) {
        return this.get('massages_intro');
      }

      if (this.get('type.isFacial') && this.get('user_type') === this.get('helper.user_types.introductory')) {
        return this.get('facials_intro');
      }

      if (this.get('type.isNeveskin') && this.get('user_type') === this.get('helper.user_types.introductory')) {
        return this.get('neveskin_intro');
      }

      if (this.get('type.isMassage') && this.get('user_type') === this.get('helper.user_types.non_member')) {
        return this.get('massages_non_member');
      }

      if (this.get('type.isFacial') && this.get('user_type') === this.get('helper.user_types.non_member')) {
        return this.get('facials_non_member');
      }

      if (this.get('type.isNeveskin') && this.get('user_type') === this.get('helper.user_types.non_member')) {
        return this.get('neveskin_non_member');
      }

      if (this.get('type.isMassage') && this.get('user_type') === this.get('helper.user_types.member')) {
        return this.get('massages_member');
      }

      if (this.get('type.isFacial') && this.get('user_type') === this.get('helper.user_types.member')) {
        return this.get('facials_member');
      }

      if (this.get('type.isNeveskin') && this.get('user_type') === this.get('helper.user_types.member')) {
        return this.get('neveskin_member');
      }
    }),

    activeClassName: Ember.computed('type', function () {
      if (this.get('type.isFacial')) {
        return 'Facials';
      } else if (this.get('type.isMassage')) {
        return 'Massage';
      } else if (this.get('type.isNeveskin')) {
        return 'Neveskin';
      }
    }),

    todayWorkingHours: function () {
      var today = moment().format('dddd');
      if (this.get('worktime')) {
        for (var i = 0; i < this.get('worktime').length; i++) {
          if (this.get('worktime')[i].day == today && this.get('worktime')[i].closed && this.get('worktime')[i].opened) {
            return this.get('worktime')[i];
          }
        }
      }
      return false;
    }.property('worktime.@each.{day}'),

    isBookable: function () {
      //32 - `good` booking status
      return this.get('status') === 32;
    }.property(),

    isShowingLMD: function () {
      return Boolean(!this.get('is_coming_soon') && this.get('active_lmd'));
    }.property('is_coming_soon', 'active_lmd'),

    isAllowedExternalSpaDealsLink: function () {
      return Boolean(this.get('external_booking') && this.get('external_spa_deals_link'));
    }.property('external_booking', 'external_spa_deals_link')
  });
});